import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled, alpha } from "@mui/material/styles";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

//
import { getEnrolledCareers } from "../../store/careers.slice";

//
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { showSnackbar } from "../../../../../../store/app/snackbar.slice";
import Loader from "@app/layout/shared/loader.component";
import { unwrapResult } from "@reduxjs/toolkit";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "center",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 0px",
  },
  careerListContainer: {
    margin: "20px 0 10px",
    border: "1px solid #cccccca6",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px",
      border: "none",
    },
  },
  emptyListContent: {
    height: "72vh",
  },
  careerList: {
    minHeight: "72vh",
    padding: "10px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
  },
  careerCard: {
    height: "300px",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      height: "220px",
    },
  },
  careerLogo: {
    width: "260px",
    height: "260px",
    border: "1px solid #cccccca6",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
    },
  },
  careerMeta: {},
}));

function CareerList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isMobileMatch = useMediaQuery("(max-width:599px)");

  // REDUX
  const user = useSelector(({ auth }) => auth.user);
  const { role: userRole, data: userData, isCampusStudent } = user;

  // STATES
  const [loading, setLoading] = useState(true);
  const [careers, updateCareers] = useState([]);
  const [anchorCareerMenuEl, setAnchorEl] = useState(null);

  //
  const openCareerMenu = Boolean(anchorCareerMenuEl);

  // EFFECTS
  useEffect(() => {
    setLoading(true);
    dispatch(getEnrolledCareers())
      .then(unwrapResult)
      .then((response) => {
        if (response?.status === 200) {
          updateCareers(response?.data);
        }

        setLoading(false);
      })
      .catch((error) => {
        const message = error?.message ? error.message : "Something went wrong";

        dispatch(
          showSnackbar({
            message: message,
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
      });
  }, []);

  // METHODS
  const handleCareerMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCareerMenuClose = () => {
    setAnchorEl(null);
  };

  // NAVIGATION
  const gotoExplore = () => {};

  const gotoDashboard = () => {
    navigate(`/user-student/main/dashboard`);
  };

  const startCareerCourse = (id, careerId) => {
    navigate(`/user-student/career/${id}/${careerId}/instructions`);
  };

  const gotoCareerSummary = (careerId, name) => {
    navigate(`./${careerId}?name=${name}`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.root
      )}
    >
      <Typography variant="h4">My Careers</Typography>

      <div
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.careerListContainer
        )}
      >
        {careers.length ? (
          <div className={clsx("flex flex-col", classes.careerList)}>
            {careers.map((career, index) => (
              <div
                key={index}
                className={clsx(
                  "my-4 flex flex-col justify-start items-stretch"
                )}
              >
                <Paper
                  elevation={3}
                  className={clsx(
                    "flex flex-col sm:flex-row justify-start items-stretch sm:space-x-6",
                    classes.careerCard
                  )}
                >
                  {isMobileMatch ? (
                    <>
                      <div
                        className={clsx(
                          "flex flex-row space-x-5",
                          classes.careerMobileCardHeader
                        )}
                      >
                        <div
                          className={clsx(
                            "flex flex-col justify-center items-center",
                            classes.careerLogo
                          )}
                        >
                          <img
                            className={clsx("w-5/6 h-5/6")}
                            src={career?.master?.logo_bg}
                            alt="parent-illustration"
                          />
                        </div>

                        <div className={clsx(classes.mobileCardHeader)}>
                          <div className={clsx("flex flex-col")}>
                            <Typography variant="body2">
                              Career | Field
                            </Typography>

                            <div className={clsx("mt-1")}>
                              <Typography
                                variant="h4"
                                className={clsx("text-bold")}
                              >
                                {career?.master?.name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={clsx("flex flex-row justify-between mt-8")}
                      >
                        {career.status !== "COMPLETED" ? (
                          <div className={clsx("", classes.careerStatus)}>
                            <Button
                              variant="contained"
                              color="accent"
                              onClick={() =>
                                startCareerCourse(
                                  career?._id?.$oid,
                                  career?.careerId?.$oid
                                )
                              }
                            >
                              Start Now
                            </Button>
                          </div>
                        ) : (
                          <div
                            className={clsx(
                              "flex flex-col",
                              classes.careerStatus
                            )}
                          >
                            <Typography variant="body2">Status</Typography>
                            <Typography variant="h6">
                              {career?.status}
                            </Typography>
                          </div>
                        )}

                        <div
                          className={clsx(
                            "flex flex-col justify-end items-end"
                          )}
                        >
                          {career.status === "COMPLETED" && (
                            <Button
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                              onClick={() =>
                                gotoCareerSummary(
                                  career?.careerId?.$oid,
                                  career?.master?.name
                                )
                              }
                            >
                              Check Report
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={clsx(
                          "flex flex-col justify-center items-center",
                          classes.careerLogo
                        )}
                      >
                        <img
                          className={clsx("w-5/6 h-5/6")}
                          src={career?.master?.logo_bg}
                          alt="parent-illustration"
                        />
                      </div>

                      <div
                        className={clsx(
                          "flex-grow flex flex-row justify-between items-stretch",
                          classes.careerMeta
                        )}
                      >
                        <div
                          className={clsx(
                            "flex flex-col justify-between items-start"
                          )}
                        >
                          <div className={clsx("flex flex-col")}>
                            <Typography variant="body2">
                              Career | Field
                            </Typography>

                            <div className={clsx("mt-1")}>
                              <Typography
                                variant="h4"
                                className={clsx("text-bold")}
                              >
                                {career?.master?.name}
                              </Typography>
                            </div>
                          </div>

                          {career.status !== "COMPLETED" ? (
                            <div className={clsx("", classes.careerStatus)}>
                              <Button
                                variant="contained"
                                color="accent"
                                onClick={() =>
                                  startCareerCourse(
                                    career?._id?.$oid,
                                    career?.careerId?.$oid
                                  )
                                }
                              >
                                Start Now
                              </Button>
                            </div>
                          ) : (
                            <div
                              className={clsx(
                                "flex flex-col",
                                classes.careerStatus
                              )}
                            >
                              <Typography variant="body2">Status</Typography>
                              <Typography variant="h6">
                                {career?.status}
                              </Typography>
                            </div>
                          )}
                        </div>

                        <div
                          className={clsx(
                            "flex flex-col justify-end items-end"
                          )}
                        >
                          {/*<IconButton*/}
                          {/*    aria-controls={openCareerMenu ? 'basic-menu' : undefined}*/}
                          {/*    aria-haspopup="true"*/}
                          {/*    aria-expanded={openCareerMenu ? 'true' : undefined}*/}
                          {/*    onClick={handleCareerMenuClick}*/}
                          {/*>*/}
                          {/*    <MoreVertIcon />*/}
                          {/*</IconButton>*/}
                          {/*<StyledMenu*/}
                          {/*    id="demo-customized-menu"*/}
                          {/*    MenuListProps={{*/}
                          {/*        'aria-labelledby': 'demo-customized-button',*/}
                          {/*    }}*/}
                          {/*    anchorEl={anchorCareerMenuEl}*/}
                          {/*    open={openCareerMenu}*/}
                          {/*    onClose={handleCareerMenuClose}*/}
                          {/*>*/}
                          {/*    <MenuItem onClick={handleCareerMenuClose} disableRipple>*/}
                          {/*        Archive*/}
                          {/*    </MenuItem>*/}
                          {/*    <MenuItem onClick={handleCareerMenuClose} disableRipple>*/}
                          {/*        Rate Career*/}
                          {/*    </MenuItem>*/}
                          {/*    <MenuItem onClick={handleCareerMenuClose} disableRipple>*/}
                          {/*        Share Career*/}
                          {/*    </MenuItem>*/}
                          {/*    <MenuItem onClick={handleCareerMenuClose} disableRipple>*/}
                          {/*        Unenroll*/}
                          {/*    </MenuItem>*/}
                          {/*</StyledMenu>*/}

                          {career.status === "COMPLETED" && (
                            <Button
                              variant="contained"
                              color="primary"
                              endIcon={<ArrowForwardIcon />}
                              onClick={() =>
                                gotoCareerSummary(
                                  career?.careerId?.$oid,
                                  career?.master?.name
                                )
                              }
                            >
                              Check Report
                            </Button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Paper>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={clsx(
              "h-full flex flex-col justify-center items-center",
              classes.emptyListContent
            )}
          >
            {isCampusStudent ? (
              <>
                <Typography variant="h6">
                  You don't have any active classroom
                </Typography>
                <div className={clsx("mt-4")}>
                  <Button
                    variant="outlined"
                    color="accent"
                    onClick={gotoDashboard}
                  >
                    Classrooms
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Typography variant="h6">
                  You haven't enrolled for any career
                </Typography>
                <div className={clsx("mt-4")}>
                  <Button
                    variant="outlined"
                    color="accent"
                    onClick={gotoExplore}
                  >
                    Explore Careers
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CareerList;
