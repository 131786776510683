import { combineReducers } from '@reduxjs/toolkit';

import careerList from '../main/modules/store/careers.slice';
import course from '../course/store/course.slice';
import mobile from '../main/components/store/mobile.slice';

const reducer = combineReducers({
    "careers": careerList,
    course,
    mobile
});

export default reducer;
