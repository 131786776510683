import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getSearch = createAsyncThunk('header/getSearch', (query, { dispatch, getState}) => {
    return axios(`/platform/careers/search?query=${query}`);
});

// ENTITY ADAPTER
const headerAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = headerAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = headerAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = headerSlice.actions;

// REDUCERS
export default headerSlice.reducer;
