import StudentApp from './student.app';
import StudentAuth from './StudentAuth';

// ROUTES
import MainRoutes from "./main/main.routes";
import CourseRoutes from "./course/course.routes";

const Routes = [
    {
        path: '/user-student',
        element:
            <StudentAuth>
                <StudentApp />
            </StudentAuth>,
        children: [
            ...MainRoutes,
            ...CourseRoutes
        ]
    }
];

export default Routes;
