import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import {useNavigate, useSearchParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
//
import {getCourseData, createOrder, updateOrder} from "../store/checkout-service.slice";

//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {showSnackbar} from "../../../../store/app/snackbar.slice";
import Loader from "@app/layout/shared/loader.component";
import appConfig from '@/@config/config';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '60vh',
        margin: '20px 0px'
    },
    checkoutContainer: {
        width: "33%",
        height: '50vh',
        padding: '10px',
        border: '1px solid #cccccca6',
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            height: '50vh',
            padding: '10px',
        },
    }
}));

function CheckoutService() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [serviceId, setServiceId] = useState();
    const [serviceData, setServiceData] = useState();
    const [serviceType, setServiceType] = useState(null);
    const [serviceAmount, setServiceAmount] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [gst, setGst] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [openConfirmation, setOpenConfirmation] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    useEffect(() => {
        setLoading(true);

        let _serviceId = searchParams.get('id');
        setServiceId(_serviceId);

        let _serviceType = searchParams.get('type')
        setServiceType(_serviceType);

        dispatch(getCourseData({id: _serviceId})).unwrap().then(payload => {
            const response = payload?.data;

            if(response.status === 1) {
                let _serviceData = response?.data[0];
                setServiceData(_serviceData);

                let amount;
                if(_serviceType == 0) {
                    amount = parseFloat(_serviceData?.early_bird_price);
                } else if(_serviceType == 1) {
                    amount = parseFloat(_serviceData?.regular_price);
                }

                setServiceAmount(amount);
                setTotalAmount(state => (amount * 1).toFixed(2));
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        })
    }, [searchParams])

    // METHODS
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const displayRazorpay = async () => {
        setConfirmLoading(true);

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Something went wrong, please try later");
            return;
        }

        let order = {
            amount: totalAmount*100,
            course: {
                id: serviceId,
                type: serviceType
            }
        };

        dispatch(createOrder(order)).then(unwrapResult).then(response => {
            const {amount, id: order_id, currency, _id} = response?.data?.data;

            setConfirmLoading(false);

            const options = {
                key: appConfig.RAZORPAY,
                amount: amount,
                currency: currency,
                order_id: order_id,
                method: {
                    netbanking: true,
                    card: true,
                    wallet: true,
                    upi: true,
                    paylater: false
                },
                handler: async function (razorpay_response) {
                    const razorpay_data = {
                        "razorpay_payment_id": razorpay_response?.razorpay_payment_id,
                        "razorpay_order_id": razorpay_response?.razorpay_order_id,
                        "razorpay_signature": razorpay_response?.razorpay_signature,
                    };

                    if(razorpay_response?.razorpay_payment_id) {
                        dispatch(updateOrder({_id, data: razorpay_data})).then(unwrapResult).then(_ => {
                            handleCheckoutSuccess()
                        }).catch(error => {
                            console.log(error);
                            dispatch(showSnackbar({
                                message: "Something went wrong, please try later",
                                autoHideDuration: 6000,//ms
                                anchorOrigin: {
                                    vertical  : 'bottom',//top bottom
                                    horizontal: 'right'//left center right
                                },
                                variant: 'error'
                            }));
                        });
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);

            paymentObject.on('payment.failed', function (response){
                alert("Something went wrong, please try later");
            });

            paymentObject.open();
        }).catch(error => {
            dispatch(showSnackbar({
                message: "Something went wrong, please try later",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'right'//left center right
                },
                variant: 'error'
            }));
        })
    }

    const handleCheckoutSuccess = () => {
        setOpenConfirmation(true);
    }

    const handleDialogClose = () => {
        navigate(`/`);
        setOpenConfirmation(false);
    }

    // RENDERS
    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col md:flex-row justify-center items-center", classes.root)}>
            <div className={clsx("flex flex-col space-y-8", classes.checkoutContainer)}>
                <Typography variant="h5">Checkout</Typography>

                <div className={clsx("flex flex-col")}>
                    <Typography variant={"body1"} className="font-bold">Service #: {serviceData?.title}</Typography>
                    <Typography variant={"body1"}>Amount: ₹{serviceAmount}</Typography>

                    <div className={clsx("mt-10")}>
                        <Typography variant={"h6"}>Total amount: ₹{totalAmount}</Typography>
                    </div>
                </div>

                <div className={clsx("basis-1 flex flex-col justify-end")}>
                    <LoadingButton variant="contained" color="primary" sx={{height: '55px'}} onClick={displayRazorpay} loading={confirmLoading} disabled={loading}>
                        CONFIRM
                    </LoadingButton>
                </div>
            </div>

            <Dialog
                open={openConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your course has been confirmed, someone from the team will contact you shortly.
                        For more details, Contact/Whatsapp @7042694615
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CheckoutService;
