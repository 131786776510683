import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import Rating from '@mui/material/Rating';

const ReviewCardWrapper = styled(Card)(({ theme }) => ({
    minWidth: "300px",
    margin: "16px 8px"
}))

export default function Review({name, text, rating}) {
    return (
        <ReviewCardWrapper raised={true}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        {name.charAt(0).toUpperCase()}
                    </Avatar>
                }
                title={name}
            />

            <CardContent sx={{paddingTop: "0px"}}>
                <Rating name="size-medium" defaultValue={rating}  precision={0.25} readOnly />

                <Typography variant="body2" color="text.secondary" className="italic">
                    {text}
                </Typography>
            </CardContent>

        </ReviewCardWrapper>
    );
}
