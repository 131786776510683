import PlatformRoutes from '../layout/platform/platform.routes';
import AuthRoutes from '../layout/auth/auth.routes';
import StudentRoutes from '../layout/student/student.routes';

const routes = [
    ...PlatformRoutes,
    ...AuthRoutes,
    ...StudentRoutes
];

export default routes;
