import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';


const useStyles = makeStyles(theme => ({
    root: {
        width: '80%'
    },
    SubList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start !important',
        alignItems: 'flex-start !important'
    }
}));

function PrivacyPolicy() {
    const classes = useStyles();

    // STATES

    // EFFECTS

    // METHODS

    return (
        <div className={clsx("flex flex-col space-y-8", classes.root)}>
            <Typography variant="h5">Privacy Policy</Typography>

            <Typography variant="body1">
                This document is an electronic record in terms of the Information Technology Act, 2000 and Rules made thereunder as applicable. This electronic record is generated by a computer system and does not require any physical or digital signatures.
            </Typography>

            <Typography variant="body1">
                This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy, and user agreement of access or usage of a website. Here website means www.careerconnectspassion.com.
            </Typography>

            <Typography variant="body1">
                1. COLLECTION & STORAGE OF INFORMATION
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Registration at CAREER CONNECTS PASSION require user/s to share their personal details including but not limited to name, gender, father’s name, address, e-mail address, school etc., which may be used by CAREER CONNECTS PASSION to arrive at a proper psychometric assessment.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION may collect by virtue of your access to the website also receive some other information, including but not limited to your browser type, operating system, IP address, date and time you visit the site, the areas or pages of the site that you visit, the amount of time you spend viewing the site, the number of times you return to the site, your location and other click-stream data to which you expressly hereunder.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            That due to our access to the website, some files may be stored on your system, to which you agree by accessing the website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION also provides access to the user/s through their respective Google/Facebook accounts; or as the case may be, during which process their (user/s) personal or any other information may be shared with CAREER CONNECTS PASSION, which may be used by CAREER CONNECTS PASSION in terms of the Privacy Policy. Further, irrespective of the mode of your login to the Website you shall remain bound by EULA/Privacy Policy and disclaimer of CAREER CONNECTS PASSION
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION stores and processes your information at the safe data centers/servers that are protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and Rules there under. You undertake not to object to your Information being transferred or used in this
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                2. USE OF INFORMATION
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION shall have the right to use your Information as provided by you and such usage of your information by us does not amount to violation of any rights you might have in your Information. You agree to grant us a non exclusive world-wide, perpetual, revocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity and database rights( but no other rights) you have in Your Information, in any media now known or not currently known, with respect to your Information.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Notwithstanding the reasonable efforts in that behalf, CAREER CONNECTS PASSION cannot control the information provided by the Users which may be made available on the Website. You may find other User’s information to be offensive, harmful, inaccurate or deceptive. Please use caution and practice safe training when using the Website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION does not sell or rent your personal information to third party for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of user’s privacy as a very important community principle. We understand clearly that your Personal Information and that of user is one of our most important assets
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION may share the information provided by you with mentors, or as the case may be; to complete your requirement from CAREER CONNECTS PASSION; to contact you with information and promotional materials and offers from our company as well as from our affiliates, other related companies, educational institutions, marketing partners and other third parties, if you have agreed to receive such communications; to government if required under law or by any other mode as permissible in law.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                3. CONFIDENTIALITY
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term “Confidential Information” shall mean all information, whether written, oral or visual, that may be disclosed or made available by the CAREER CONNECTS PASSION to the User/s, but not limited to:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Technical, financial, commercial, or other information relating to the business or financial affairs, methods of operation or proposed methods of operation, accounts, transactions or products, proposed transaction or proposed products, security procedures, internal controls or computer or data processing programs, algorithms, electronic data processing applications, routines, sub routines, techniques, systems, policies, procedures or personal information of CAREER CONNECTS PASSION or User or any other customer, client, business partner, alliance or vendor of the CAREER CONNECTS PASSION
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Information or data which is confidential or proprietary to a third party which is in the possession, custody or control of CAREER CONNECTS PASSION; and
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    All data, notes, summaries or other material derived from the information specified above.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Notwithstanding the foregoing, “Confidential Information” shall not include any information that:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is or becomes generally available to the public other than as a result of a disclosure by CAREER CONNECTS PASSION; or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is available to the user/s on a non-confidential basis prior to the disclosure of such Confidential Information by the CAREER CONNECTS PASSION;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Becomes available to the user/s on a non-confidential basis from a source other than a CAREER CONNECTS PASSION only if such source is not subject to any prohibition against transmitting the information to the user/s;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Was developed independently by the user/s without any reference to or use of the Confidential Information;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is approved for release upon the written permission of the CAREER CONNECTS PASSION;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is required to be disclosed to a government/ judicial/ quasi-judicial body in pursuance of an order.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>

                    <Typography variant="body1">
                        In such events, where possible, the user shall give adequate notice to the CAREER CONNECTS PASSION, as the case may be, before making any disclosure so that the CAREER CONNECTS PASSION may have adequate opportunity to respond prior to such disclosures; as also make disclosures only to the extent as required.
                    </Typography>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Further, you agree and undertake:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is or becomes generally available to the public other than as a result of a disclosure by CAREER CONNECTS PASSION; or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    That all the Confidential Information shall be considered and shall remain a trade secret and the sole property of CAREER CONNECTS PASSION;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    That using utmost care, you shall hold interest for CAREER CONNECTS PASSION and keep in strict confidence all Confidential Information and not disclose to any person/ third party or use for any purpose or allow the disclosure or use of any Confidential Information accept as required for the purposes of the engagement or as permitted in terms of the Agreement;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    To employ all reasonable measures to protect the Confidential Information from unauthorized or inadvertent disclosure, including measure no less protective then those measures that you employs to protect own information of a like nature;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Not to publish, disclose or allow disclosure to others (including, without limitation, the tax professional’s affiliates or agents) of any Confidential Information that has been made available to the user/s;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    To restrict internal access to the Confidential Information on a need-to-know basis who have been instructed as to the confidential nature of such information and who are either subject to a corporate nondisclosure policy established policy established by their employer or have executed a non disclosure agreement reasonably designed to maintain CAREER CONNECTS PASSION 's proprietary rights and to ensure the confidentiality and non-use of the Confidential Information as set forth in this Agreement;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    To be responsible for any breach of this Agreement caused by the tax professional;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    That notwithstanding the return of any Confidential Information in accordance with this agreement herein, the user and its employees and agents shall continue to hold in confidence all Confidential Information, which obligation shall survive any expiration or termination of this Agreement for a period of one year.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            ACCEPTANCE
                        </Typography>
                    </div>

                    <Typography variant="body1">
                        By using the Site and/ or by providing your Information you consent to the collection and use of the information you disclose on the Site by CAREER CONNECTS PASSION in accordance with CAREER CONNECTS PASSION Privacy Policy. You agree and acknowledge that you have read and fully understood the Privacy Policy and the terms and contents of such Privacy Policy as mentioned in the Website is acceptable to you.
                    </Typography>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CHANGES IN THE PRIVACY POLICY
                        </Typography>
                    </div>

                    <Typography variant="body1">
                        The Privacy policy may be changed by CAREER CONNECTS PASSION time to time and user/s is required to keep himself updated with the same at all times. By accessing the Website, you are deemed to have acquainted yourself with the up-to-date Privacy Policy.
                    </Typography>
                </ListItem>
            </List>
        </div>
    );
}

export default PrivacyPolicy;
