import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getAggregatedCareers = createAsyncThunk('student/getAggregatedCareers', (id, { dispatch, getState}) => {
    return axios.get('/platform/careers/aggregate');
});


// ENTITY ADAPTER
const studentAdapter = createEntityAdapter({});

// SELECTORS
export const { selectAll: selectCareer, selectById: selectCareerById } = studentAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = studentAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = studentSlice.actions;

// REDUCERS
export default studentSlice.reducer;
