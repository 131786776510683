import Careers from '../careers/careers';
import CareerSummary from './career-summary/career-summary';

// COMPONENTS
import CareerList from './career-list/career-list';

const Routes = [
    {
        path: 'careers',
        element: <Careers />,
        children: [
            {
                index: true,
                element: <CareerList />
            },
            {
                path: 'list',
                element: <CareerList />
            },
            {
                path: ':careerId',
                element: <CareerSummary />
            }
        ]
    }
];

export default Routes;
