export const SECTION_REPORT = {
    "Career Task": {
        HIGH: "You will be able to showcase all your strengths and abilities in the tasks for this career option. You will enjoy all the tasks and responsibilities of this career option and perform them with total dedication.",
        MODERATE: "You will be able to showcase a few strengths and abilities in the tasks for this career option.",
        LOW: "This career option does not compliment your strengths and abilities"
    },
    "Career Tasks": {
        HIGH: "You will be able to showcase all your strengths and abilities in the tasks for this career option. You will enjoy all the tasks and responsibilities of this career option and perform them with total dedication.",
        MODERATE: "You will be able to showcase a few strengths and abilities in the tasks for this career option.",
        LOW: "This career option does not compliment your strengths and abilities"
    },
    "Career Day": {
        HIGH: "You will be motivated to work in all the sectors of this career option. You will feel fulfilled working in this career option.",
        MODERATE: "You will like working in some of the sectors of this career option. ",
        LOW: "There will be no motivation to work in any of the sectors of this career option."
    },
    "Career Days": {
        HIGH: "You will be motivated to work in all the sectors of this career option. You will feel fulfilled working in this career option.",
        MODERATE: "You will like working in some of the sectors of this career option. ",
        LOW: "There will be no motivation to work in any of the sectors of this career option."
    },
    "Career Subject": {
        HIGH: "You find all the subjects related to this career interesting and engaging. You are more likely to learn all the subjects with full dedication.",
        MODERATE: " You find a few subjects related to this career interesting and engaging.",
        LOW: "You are not interested to study the subjects related to this career option."
    },
    "Career Subjects": {
        HIGH: "You find all the subjects related to this career interesting and engaging. You are more likely to learn all the subjects with full dedication.",
        MODERATE: " You find a few subjects related to this career interesting and engaging.",
        LOW: "You are not interested to study the subjects related to this career option."
    },
    "Career Personality": {
        HIGH: "All your current personality traits fulfill the personality type for this career option. ",
        MODERATE: "Some of your current personality traits fulfill the personality type for this career option.",
        LOW: "Your Personality is not compatible with this career option."
    },
    "Career Value": {
        HIGH: "All your personal values match the values for this career option.",
        MODERATE: "Some of your personal values match the values for this career option.",
        LOW: "Your Personal Values don’t match with the values for this career option"
    },
    "Career Values": {
        HIGH: "All your personal values match the values for this career option.",
        MODERATE: "Some of your personal values match the values for this career option.",
        LOW: "Your Personal Values don’t match with the values for this career option"
    }
};

export const CAREER_REMARK = {
    HIGH: "Most Suitable Career ",
    MODERATE: "Moderately Suitable Career",
    LOW: "Least Suitable Career"
}

export const COLOR_MAP = {
    HIGH: "#00E676",
    MODERATE: "#FFC860",
    LOW: "#FE7062"
}
