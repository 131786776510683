import {CAREER_REMARK, SECTION_REPORT, COLOR_MAP} from "./constant";

export const formatSectionsReport = (sections) => {
    try {
        let report = sections.map(section => {
            let name = section?.name?.trim();
            let sectionScore = 0;
            let maxSectionScore = 0;
            let scoreStatus;

            section?.subsections?.forEach(subsection => {
                if(subsection?.type == 2) {
                    maxSectionScore += 5;
                    sectionScore += subsection?.rating
                }
            });

            scoreStatus = getSectionRemark(sectionScore, maxSectionScore);

            return {
                name,
                maxSectionScore,
                sectionScore,
                scoreStatus,
                remark: SECTION_REPORT[name][scoreStatus],
                color: COLOR_MAP[scoreStatus],
            }

        });

        return report
    } catch(error) {
        console.log(error)
    }
}

export const getCareerRemark = (score) => {
    if(score >= 79 && score <= 100) {
        return {status: "HIGH", color: COLOR_MAP["HIGH"], remark: CAREER_REMARK["HIGH"]};
    } else if(score >= 51 && score <= 78) {
        return {status: "MODERATE", color: COLOR_MAP["MODERATE"], remark: CAREER_REMARK["MODERATE"]}
    } else {
        return {status: "LOW", color: COLOR_MAP["LOW"], remark: CAREER_REMARK["LOW"]};
    }
}

const getSectionRemark = (sectionScore, maxSectionScore) => {
    let remark;

    switch (maxSectionScore) {
        case 25:
            if(sectionScore >= 18 && sectionScore <= 25) {
                remark = "HIGH";
            } else if(sectionScore >= 13 && sectionScore <= 17) {
                remark = "MODERATE"
            } else {
                remark = "LOW"
            }
            break;
        case 15:
            if(sectionScore >= 12 && sectionScore <= 15) {
                remark = "HIGH";
            } else if(sectionScore >= 8 && sectionScore <= 11) {
                remark = "MODERATE"
            } else {
                remark = "LOW"
            }
            break;
        case 10:
            if(sectionScore >= 8 && sectionScore <= 10) {
                remark = "HIGH";
            } else if(sectionScore >= 5 && sectionScore <= 7) {
                remark = "MODERATE"
            } else {
                remark = "LOW"
            }
            break;
    };

    return remark;
};
