import React, {useEffect, useState, useRef} from "react";
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '60vh',
        margin: '0px 30px'
    },
    sidebar: {
        width: '22%'
    },
    container: {
        width: '75%',
        margin: '22px 0px'
    },
    sidebarContent: {
        border: '1px solid',
        margin: '22px 0px'
    },
    sidebarItem: {
        height: '40px',
        padding: '10px 15px',
        borderBottom: '1px solid',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ebebeb'
        }
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'initial',
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    }
}));

function Company() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    // REF
    const children = useRef(['about-us', 'terms', 'privacy-policy', 'cancellation-refund-policy', 'disclaimer']);

    // STATES
    const [selectedRoute, setSelectedRoute] = useState(null);

    // EFFECTS
    useEffect(() => {
        setSelectedRoute(state => {
            let { pathname } = location;

            let routes = pathname.split('/');
            let navigationEnd = routes[routes.length - 1];

            if(navigationEnd === 'about-us') {
                state = 0
            } else if(navigationEnd === 'terms') {
                state = 1
            } else if(navigationEnd === 'privacy-policy') {
                state = 2
            } else if(navigationEnd === 'cancellation-refund-policy') {
                state = 3
            } else if(navigationEnd === 'disclaimer') {
                state = 4
            }

            return state;
        })
    }, [location]);

    // METHODS
    const handleSelectRoute = (index) => {
        navigate(`./${children.current[index]}`);
    }

    return (
        <div className={clsx("flex flex-row justify-between items-start", classes.root)}>
            <div className={clsx("", classes.sidebar)}>
                <div className={clsx(classes.sidebarContent)}>
                    <div className={clsx("", classes.sidebarItem, classes.aboutUs, {[classes.active]:selectedRoute === 0} )} onClick={() => handleSelectRoute(0)}>
                        <Typography variant="body2">About Us</Typography>
                    </div>

                    <div className={clsx("", classes.sidebarItem, classes.terms, {[classes.active]:selectedRoute === 1} )} onClick={() => handleSelectRoute(1)}>
                        <Typography variant="body2">Terms & Condition</Typography>
                    </div>

                    <div className={clsx("", classes.sidebarItem, classes.privacyPolicy, {[classes.active]:selectedRoute === 2} )} onClick={() => handleSelectRoute(2)}>
                        <Typography variant="body2">Privacy Policy</Typography>
                    </div>

                    <div className={clsx("", classes.sidebarItem, classes.cancellationPolicy, {[classes.active]:selectedRoute === 3} )} onClick={() => handleSelectRoute(3)}>
                        <Typography variant="body2">Cancellation/Refund Policy</Typography>
                    </div>

                    <div className={clsx("", classes.sidebarItem, classes.disclaimer, {[classes.active]:selectedRoute === 4} )} onClick={() => handleSelectRoute(4)}>
                        <Typography variant="body2">Disclaimer</Typography>
                    </div>
                </div>
            </div>
            <div className={clsx("", classes.container)}>
                <Outlet />
            </div>
        </div>
    );
}

export default Company;
