import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import {unwrapResult} from "@reduxjs/toolkit";
import {useNavigate, useLocation, createSearchParams} from "react-router-dom";
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
//
import AuthService from '@app/services/auth/auth.service';
import {postSignupUser} from '@app/auth/store/signup.slice';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatePicker from '@mui/lab/DatePicker';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles(theme => ({
    root: {
        overflowY: 'hidden'
    },
    container: {
        height: '100%'
    },
    imageContainer: {
        width: '36%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mainImage: {
        height: '100%'
    },
    content: {
        width: '64%',
        overflowY: 'scroll',
        paddingBottom: '25px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    contentBody: {
        width: '100%',
        marginTop: '20px'
    },
    userTypeContainer: {
        width: '60%'
    },
    userType: {
        width: '120px',
        fontWeight: 'bold'
    },
    actionBody: {
        width: '100%'
    },
    signupParentBody: {
        width: '48%'
    },
    signupStudentBody: {
        width: '95%'
    },
    signupBodyLeft: {
        width: '48%'
    },
    signupBodyRight: {
        width: '48%'
    },
    signupInput: {
        width: '48%'
    },
    signupButton: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    },
    horizontalLine: {
        width: '45%',
        height: '1px'
    },
    horizontalLineText: {
        width: '10%',
        padding: '0 10px'
    },
    oAuthBody: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    },
    loginBody: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    }
}));

function Signup() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // STATES
    const [loading, setLoading] = useState(false);
    const [userType, setUserType] = useState(0);
    const [birthdate, setBirthdate] = useState(null);
    const [birthdatePicker, setBirthdatePicker] = useState(false);

    // FORM CONTROL
    const methods = useForm({
        mode: 'all'
    });
    const { watch,  control, formState, reset, setError } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    console.log(data);
                    break;
                case "signOut":
                    console.log(data);
                    break;
                case "customOAuthState":
                    console.log(data);
            }
        });

        Auth.currentAuthenticatedUser()
            .then(currentUser => console.log(currentUser))
            .catch(() => console.log("Not signed in"));

        return unsubscribe;
    }, [])

    // EFFECTS
    useEffect(() => {
        // console.log(form);
        // console.log(errors);
    }, [form, errors])

    // METHODS
    const handleChangeUserType = () => setUserType(state => state ? 0 : 1);

    const handleBirthdateChange = (date, field) => {
        if(date) {
            field.onChange(date.valueOf());
            setBirthdate(date);
        } else {
            setError('dob', {
                type: "required",
                message: "Birthdate is required.",
            });
        }
    }

    const handleSignup = () => {
        if(isValid && isDirty) {
            setLoading(true);

            let mongoUser = {
                username: form.username,
                first_name: form.first_name,
                last_name: form.last_name,
                email: form.email,
                phone_number: `+91${form.mobile}`,
                birthdate: form.birthdate,
                gender: form.gender,
                class: form.class,
                school: form.school,
                city: form.city,
                state: form.state
            };

            dispatch(postSignupUser(mongoUser)).then(unwrapResult).then(response => {
                const cognitoUser = {
                    username: form.username,
                    password: form.password,
                    attributes: {
                        email: form.email,
                        phone_number: `+91${form.mobile}`,
                        birthdate: new Date(form.birthdate).toISOString().split('T')[0],
                        gender: form.gender,
                        'custom:user_id': response.data.userId,
                        'custom:first_name': form.first_name,
                        'custom:last_name': form.last_name,
                        'custom:class': form.class,
                        'custom:school': form.school,
                        'custom:city': form.city,
                        'custom:state': form.state,
                        'custom:user_type': userType ? 'parent' : 'student'
                    }
                };

                AuthService.createUser(cognitoUser).then(response => {
                    navigate({
                        pathname: `../confirm/${form.username}`,
                        search: `?${createSearchParams({
                            state: "sent"
                        })}`
                    });
                    setLoading(false);
                }).catch(error => {
                    // HANDLE ERROR STATES
                    console.log(error);

                    dispatch(showSnackbar({
                        message: error?.message,
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'error'
                    }));
                    setLoading(false);
                })
            }).catch(error => {
                const message = error?.data?.message ? error?.data?.message : "Something went wrong";
                dispatch(showSnackbar({
                    message: message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));
                setLoading(false);
            });
        }
    };

    const handleGoogleSignup = () => {
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })
    }

    // NAVIGATION
    const gotoLogin = () => {
        navigate(`/auth/login`);
    }

    const gotoHome = () => {
        navigate(`/`);
    }

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-row", classes.container)}>
                <div className={clsx(classes.imageContainer)}>
                    <img className={clsx(classes.mainImage)} src="../../../assets/auth/auth-bg-9.2.png" />
                </div>

                <div className={clsx("flex flex-col justify-start items-center", classes.content)}>
                    <div className={clsx("flex flex-col justify-start items-center space-y-8", classes.contentBody)}>
                        <div className={clsx("flex flex-col justify-start items-center", classes.userTypeContainer)}>
                            <div className={clsx("flex flex-row space-x-4")}>
                                <Typography variant="subtitle1">I am a</Typography>
                                <Chip
                                    className={classes.userType}
                                    label={userType ? 'Parent' : 'Student'}
                                    icon={<FaceIcon />}
                                    color="primary" size="large"
                                    onClick={handleChangeUserType} />
                            </div>
                        </div>

                        <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.actionBody)}>
                            <form>
                                {
                                    userType ?
                                        <div className={clsx("flex flex-col justify-start items-center")}>
                                            <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.signupParentBody)}>
                                                <Controller
                                                    name="first_name"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "First name is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.first_name}
                                                            required
                                                            helperText={errors?.first_name?.message}
                                                            label="First name"
                                                            id="first_name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={
                                                        {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message: 'Enter valid email'
                                                            }
                                                        }
                                                    }
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.email}
                                                            required
                                                            helperText={errors?.email?.message}
                                                            label="Email"
                                                            id="email"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="username"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Username is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.username}
                                                            required
                                                            helperText={errors?.username?.message}
                                                            label="Username"
                                                            id="username"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="last_name"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Last name is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.last_name}
                                                            required
                                                            helperText={errors?.last_name?.message}
                                                            label="Last name"
                                                            id="last-name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="mobile"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Mobile is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.mobile}
                                                            required
                                                            helperText={errors?.mobile?.message}
                                                            label="Mobile"
                                                            id="mobile"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Password is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            className="mt-8 mb-16 w-12/25"
                                                            error={!!errors.password}
                                                            required
                                                            helperText={errors?.password?.message}
                                                            label="Password"
                                                            id="password"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className={clsx("flex flex-col justify-start items-stretch space-y-8", classes.signupStudentBody)}>
                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="first_name"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "First Name is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.first_name}
                                                            required
                                                            helperText={errors?.first_name?.message}
                                                            label="First Name"
                                                            id="first-name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="last_name"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Last name is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.last_name}
                                                            required
                                                            helperText={errors?.last_name?.message}
                                                            label="Last name"
                                                            id="last-name"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={
                                                        {
                                                            required: "Email is required",
                                                            pattern: {
                                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                message: 'Enter valid email'
                                                            }
                                                        }
                                                    }
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.email}
                                                            required
                                                            helperText={errors?.email?.message}
                                                            label="Email"
                                                            id="email"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="mobile"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={
                                                        {
                                                            required: "Mobile is required",
                                                            pattern: {
                                                                value: /^[0-9]+$/,
                                                                message: 'Enter valid mobile'
                                                            },
                                                            minLength: {
                                                                value: 10,
                                                                message: 'Enter 10 digit number'
                                                            },
                                                            maxLength: {
                                                                value: 10,
                                                                message: 'Enter 10 digit number'
                                                            },
                                                        }
                                                    }
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                                            }}
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.mobile}
                                                            required
                                                            helperText={errors?.mobile?.message}
                                                            label="Mobile"
                                                            id="mobile"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="username"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Username is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.username}
                                                            required
                                                            helperText={errors?.username?.message}
                                                            label="Username"
                                                            id="username"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="password"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Password is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.password}
                                                            required
                                                            helperText={errors?.password?.message}
                                                            label="Password"
                                                            id="password"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="birthdate"
                                                    control={control}
                                                    rules={{ required: "Date of birth is required" }}
                                                    render={ ({ field }) => (
                                                        <DatePicker
                                                            label="Birthdate"
                                                            value={birthdate}
                                                            onChange={(date) => handleBirthdateChange(date, field)}
                                                            renderInput={
                                                                ({
                                                                     InputProps, disabled, error,
                                                                     inputProps, inputRef, label,
                                                                     ref
                                                                }) => {
                                                                        return (
                                                                            <TextField
                                                                                InputProps={InputProps}
                                                                                label={label}
                                                                                inputRef={inputRef}
                                                                                inputProps={inputProps}
                                                                                className={clsx(classes.signupInput)}
                                                                                error={!!errors.dob}
                                                                                helperText={errors?.dob?.message}
                                                                                id="birthdate"
                                                                            />
                                                                        )
                                                                }
                                                            }
                                                        />
                                                    )}
                                                />



                                                <Controller
                                                    name="gender"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Gender is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            select
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.gender}
                                                            required
                                                            helperText={errors?.gender?.message}
                                                            label="Gender"
                                                            id="gender"
                                                            variant="outlined"
                                                        >
                                                            <MenuItem  value="male">Male</MenuItem>
                                                            <MenuItem  value="female">Female</MenuItem>
                                                            <MenuItem  value="other">Other</MenuItem>
                                                        </TextField>
                                                    )}
                                                />
                                            </div>

                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="class"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "Class is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            select
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.class}
                                                            required
                                                            helperText={errors?.class?.message}
                                                            label="Class"
                                                            id="class"
                                                            variant="outlined"
                                                        >
                                                            <MenuItem  value="9">Class 9</MenuItem>
                                                            <MenuItem  value="10">Class 10</MenuItem>
                                                            <MenuItem  value="11">Class 11</MenuItem>
                                                            <MenuItem  value="12">Class 12</MenuItem>
                                                            <MenuItem  value="other">Other</MenuItem>
                                                        </TextField>
                                                    )}
                                                />

                                                <Controller
                                                    name="school"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "School is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.school}
                                                            required
                                                            helperText={errors?.school?.message}
                                                            label="School"
                                                            id="school"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className={clsx("flex flex-row justify-between items-start")}>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "City is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.city}
                                                            required
                                                            helperText={errors?.city?.message}
                                                            label="City"
                                                            id="city"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    defaultValue=""
                                                    rules={{ required: "State is required" }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <TextField
                                                            className={clsx(classes.signupInput)}
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            error={!!errors.state}
                                                            required
                                                            helperText={errors?.state?.message}
                                                            label="State"
                                                            id="state"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                }
                            </form>

                            <div className={clsx("flex flex-col justify-center items-center")}>
                                <LoadingButton className={classes.signupButton} variant="contained" loading={loading} onClick={handleSignup}>Signup</LoadingButton>
                            </div>
                        </div>

                        <div className={clsx("w-2/5 flex flex-row justify-center items-center")}>
                            <hr className={clsx(classes.horizontalLine)}/>
                            <Typography className={clsx(classes.horizontalLineText)} variant="body1">or</Typography>
                            <hr className={clsx(classes.horizontalLine)}/>
                        </div>

                        <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.oAuthBody)}>
                            <Button variant="google" onClick={handleGoogleSignup}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-google mr-4" viewBox="0 0 16 16">
                                    <path
                                        d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                </svg>
                                Signup with Google
                            </Button>

                            {/*<Button variant="facebook">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                            {/*         className="bi bi-facebook mr-4" viewBox="0 0 16 16">*/}
                            {/*        <path*/}
                            {/*            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>*/}
                            {/*    </svg>*/}
                            {/*    Signup with Facebook*/}
                            {/*</Button>*/}
                        </div>

                        <div className={clsx("flex flex-row justify-center items-center", classes.loginBody)}>
                            <Typography variant="subtitle1">Already have a account?</Typography>
                            <Button variant="text" onClick={gotoLogin}>Login</Button>
                        </div>

                        <Button variant="text-black" onClick={gotoHome}>
                            <ArrowBackIcon className={clsx("mr-3")} />
                            Home
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
