import CourseApp from './course.app';
// Course
import CourseInstructions from './career-course/course-instructions';
import CourseSection from './career-course/course-section';
import VideoContent from './career-course/components/video-content';
import QuestionRadio from './career-course/components/question-radio';
import QuestionRate from './career-course/components/question-rate';
import QuestionInput from './career-course/components/question-input';
import CourseCompleted from './career-course/components/course-complete';

const Routes = [
    {
        path: 'career/:id/:careerId',
        element: <CourseApp />,
        children: [
            {
                path: 'instructions',
                element: <CourseInstructions />
            },
            {
                path: 'section/:sectionId/:subsectionId',
                element: <CourseSection />,
                children: [
                    {
                        path: 'cn-1',
                        element: <VideoContent />
                    },
                    {
                        path: 'cn-2',
                        element: <QuestionRate />
                    },
                    {
                        path: 'cn-3',
                        element: <QuestionRadio />
                    },
                    {
                        path: 'cn-4',
                        element: <QuestionInput />
                    },
                ]
            },
            {
                path: 'completed',
                element: <CourseCompleted />
            }
        ]
    }
];

export default Routes;
