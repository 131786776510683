import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
//
import withReducer from "../../../store/withReducer";
import reducer from "./store";
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
import { postDemoSchedule } from "./store/parent.slice";
//
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ProgressButton } from "@app/layout/shared/buttons.component";
import CircleIcon from "@mui/icons-material/Circle";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { data as review_data } from "../../../../@data/certification-course/review.data";
import Review from "../components/review";

const CareerCounsellorCertificationWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "40rem",
  padding: "20px 30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "stretch",

  "& .price-container": {
    maxWidth: "240px",
  },

  "& .early-bird-button": {
    width: "140px",
    height: "70px",
    borderRadius: "5px",
  },

  "& .early-bird-active": {
    cursor: "pointer",
    backgroundColor: theme.palette.accent.main,
  },

  "& .early-bird-inactive": {
    cursor: "not-allowed",
    backgroundColor: "grey",
  },

  "& .regular-price-button": {
    width: "140px",
    height: "70px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "5px",
  },

  "& .review-container": {
    width: "96vw",
    overflowX: "scroll",
    padding: "0px 12px",
  },
}));

function CareerCounsellorCertification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // FLAGS
  const earlyBirdFlag = false;
  const earlyBirdDate = "20th May ";
  const sessionStartDate = "2nd June";

  // STATE
  const [loading, setLoading] = useState(false);
  const [serviceId, setServiceId] = useState(
    process.env.REACT_APP_ENVIRONMENT === "prod"
      ? "635077d2e562a07f524e3248"
      : "634b29182ffa1b3027973aec"
  );
  const [serviceTitle, setServiceTitle] = useState(
    "Career Counsellor Certification"
  );
  const [serviceAmount, setServiceAmount] = useState(null);

  // EFFECTS

  //
  const handleEarlyBird = () => {
    if (earlyBirdFlag) {
      navigate(
        `/checkout-services?id=${serviceId}&type=0&title=${serviceTitle}`
      );
    }
  };

  const handleRegularService = () => {
    navigate(`/checkout-services?id=${serviceId}&type=1
        &title=${serviceTitle}`);
  };

  return (
    <CareerCounsellorCertificationWrapper>
      <Typography variant="h4" className="font-black">
        CAREER COUNSELING BUSINESS CERTIFICATION COURSE
      </Typography>

      <div className={clsx("flex md:flex-row flex-col")}>
        <div className={clsx("flex-1 py-8")}>
          <Typography variant="h4">
            Start your own career counselling business
          </Typography>

          <div className={clsx("flex flex-col mt-8 space-y-4")}>
            <div className={clsx("flex flex-row space-x-2")}>
              <Typography variant="body1">
                <CircleIcon sx={{ fontSize: "15px" }} />
              </Typography>
              <Typography variant="h5">
                Lifetime Access To Counseling Tools And Sessions
              </Typography>
            </div>

            <div className={clsx("flex flex-row space-x-2")}>
              <Typography variant="body1">
                <CircleIcon sx={{ fontSize: "15px" }} />
              </Typography>
              <Typography variant="h5">
                4+ Years of Experienced And Certified Trainers
              </Typography>
            </div>

            <div className={clsx("flex flex-row space-x-2")}>
              <Typography variant="body1">
                <CircleIcon sx={{ fontSize: "15px" }} />
              </Typography>
              <Typography variant="h5">
                Free Career Tests From Our Platform Worth Rs 10000
              </Typography>
            </div>

            <div className={clsx("flex flex-row space-x-2")}>
              <Typography variant="body1">
                <CircleIcon sx={{ fontSize: "15px" }} />
              </Typography>
              <Typography variant="h5">
                Get A Practitioner Certificate
              </Typography>
            </div>
          </div>
        </div>

        <div className={clsx("flex-1")}>
          <img className={clsx()} src="assets/career-counsellor.svg" />
        </div>
      </div>

      <div className={clsx("flex md:flex-row flex-col")}>
        <div className={clsx("flex-1 py-8")}>
          <Typography variant="h4" className="font-black">
            Course Content
          </Typography>

          <div className={clsx("flex flex-col mt-8 space-y-4")}>
            <div>
              <Typography variant="h6">MODULE 1</Typography>
              <div className={clsx("flex flex-col mt-1 space-y-2")}>
                <div className={clsx("flex flex-row space-x-2")}>
                  <Typography variant="body1">
                    <CircleIcon sx={{ fontSize: "10px" }} />
                  </Typography>
                  <Typography variant="body1">
                    Business Models of Career Counseling , Basics of Career
                    Counseling, Traits of Career Counselors
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <Typography variant="h6">MODULE 2</Typography>
              <div className={clsx("flex flex-col mt-1 space-y-2")}>
                <div className={clsx("flex flex-row space-x-2")}>
                  <Typography variant="body1">
                    <CircleIcon sx={{ fontSize: "10px" }} />
                  </Typography>
                  <Typography variant="body1">
                    Psychometric Assessments, Aptitude Test, Personality Test,
                    Interest Test, Career Values and Strengths Test, Emotional
                    Intelligence Test, Motivation Style Test
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <Typography variant="h6">MODULE 3</Typography>
              <div className={clsx("flex flex-col mt-1 space-y-2")}>
                <div className={clsx("flex flex-row space-x-2")}>
                  <Typography variant="body1">
                    <CircleIcon sx={{ fontSize: "10px" }} />
                  </Typography>
                  <Typography variant="body1">
                    India and Abroad University Selection, Writing SOPs, Resume,
                    Interview Preparation, Profile Building
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <Typography variant="h6">MODULE 4</Typography>
              <div className={clsx("flex flex-col mt-1 space-y-2")}>
                <div className={clsx("flex flex-row space-x-2")}>
                  <Typography variant="body1">
                    <CircleIcon sx={{ fontSize: "10px" }} />
                  </Typography>
                  <Typography variant="body1">
                    How to create your brand in career counseling, Getting first
                    100 customers, Sales and Marketing of Career Counseling,
                    Pricing your Services
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={clsx("flex-1")}>
          <img
            className={clsx()}
            src="assets/career-counsellor-certificate.svg"
          />
        </div>
      </div>

      <div className={clsx("flex flex-col md:mt-0 mt-12 space-y-10")}>
        <div className={clsx("flex flex-row items-center space-x-6")}>
          <EventNoteIcon sx={{ fontSize: "60px" }} />
          <Typography variant="h4" className="font-black">
            {sessionStartDate}
          </Typography>
        </div>

        <div className={clsx("flex flex-row space-x-6")}>
          <AccessTimeIcon sx={{ fontSize: "60px" }} />
          <Typography variant="h4" className="font-black">
            12-2 PM Every Sunday
          </Typography>
        </div>

        <div className={clsx("flex flex-row items-start space-x-6")}>
          <img className={clsx()} src="assets/rupee-icon.svg" />

          <div
            className={clsx(
              "flex md:flex-row flex-col space-y-8 md:space-y-0 space-x-6"
            )}
          >
            <div
              className={clsx(
                "flex flex-col justify-start items-center price-container"
              )}
            >
              <div
                className={clsx(
                  "flex flex-col justify-center items-center early-bird-button",
                  earlyBirdFlag ? "early-bird-active" : "early-bird-inactive"
                )}
                onClick={handleEarlyBird}
              >
                <Typography variant="h5">1500</Typography>
                <Typography variant="subtitle2">
                  <strike>5000</strike>
                </Typography>
              </div>

              <Typography variant="h6" className="text-center">
                Early Bird Discount Valid Till {earlyBirdDate}
              </Typography>
            </div>

            <div
              className={clsx(
                "flex flex-col justify-start items-center price-container"
              )}
            >
              <div
                className={clsx(
                  "flex flex-col justify-center items-center regular-price-button cursor-pointer"
                )}
                onClick={handleRegularService}
              >
                <Typography variant="h5">2500</Typography>
                <Typography variant="subtitle2">
                  <strike>5000</strike>
                </Typography>
              </div>

              <Typography variant="h6" className="text-center">
                Price after {earlyBirdDate}
              </Typography>
            </div>
          </div>
        </div>

        <div className={clsx("flex flex-row items-start")}>
          <div
            className={clsx(
              "flex flex-row justify-start items-center space-x-3"
            )}
          >
            <Typography variant="body1">Payment secured by</Typography>
            <img className={clsx("w-32")} src="assets/razorpay.svg" />
          </div>
        </div>

        <div className={clsx("flex flex-row space-x-4 review-container")}>
          {review_data.map((data) => (
            <Review {...data} />
          ))}
        </div>
      </div>

      <div className={clsx("flex flex-row items-start space-x-6 mt-10")}>
        <Typography variant="subtitle1">
          For any other queries Contact/Whatsapp @7042694615
        </Typography>
      </div>
    </CareerCounsellorCertificationWrapper>
  );
}

export default withReducer("Platform", reducer)(CareerCounsellorCertification);
