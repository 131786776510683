import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%'
    }
}));

function Disclaimer() {
    const classes = useStyles();

    // STATES

    // EFFECTS

    // METHODS

    return (
        <div className={clsx("flex flex-col space-y-8", classes.root)}>
            <Typography variant="h5">Disclaimer</Typography>

            <Typography variant="body1">
                This document is an electronic record in terms of Information Technology Act, 2000 and Rules made there under as applicable. This electronic record is generated by a computer system and does not require any physical or digital signatures.
            </Typography>

            <Typography variant="body1">
                This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement of access or usage of a website. Here website means www.CAREERCONNECTSPASSION.com.
            </Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION  endeavors to render best possible advice, psychometric assessments and the bonafide guidance to the user/s, however shall not be responsible in any manner for any direct/indirect loss incurred to the user/s due to such advice/tests.
            </Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION  provides advise and recommendations based on the information received from the user/s and results of the psychometric assessments and is not responsible for the outcome of the assessments in any manner.
            </Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION  is not responsible for any act of the career expert and counselors beyond the scope of the services rendered by it in any manner.
                The information/results provided by the website is only for bonafide guidance and shall not be relied upon as the sole factor for the purposes of your decision making, admission or such ancillary purpose as other relevant factors including but not limited to educational qualification. I.Q., or other relevant factors play an important role for the said purpose.
            </Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION shall not be responsible in any manner for any liability that may arise directly or indirectly due to use of information, content or the report, including (but not limited to) errors or omissions, the accuracy, completeness, reliability, or reasonableness of factual or scientific assumptions, studies, remarks, test results or conclusions contained in such information, content or report.
            </Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION is not responsible for any liability for, claims, losses and damages of any kind, direct or indirect, arising out of use, reference to any information/result provided by the Website. CAREER CONNECTS PASSION does not provide any guarantee or warranty, including (but not limited to) qua non-infringement of third-party rights, title, interest qua information provided by the website.
            </Typography>
        </div>
    );
}

export default Disclaimer;
