import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../../../store/app/snackbar.slice";
import {
  getDashboard,
  getUserSummary,
  getStudentClassrooms,
  startClassroom,
} from "../store/dashboard.slice";
import Button from "@mui/material/Button";
import Loader from "@app/layout/shared/loader.component";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { getCareerRemark } from "../careers/career-summary/utils";
import CircleIcon from "@mui/icons-material/Circle";
import Skeleton from "@mui/material/Skeleton";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 14px",
    },
  },
  emptyListContent: {
    height: "72vh",
  },
  CompletedCareers: {
    marginTop: "50px",
    minHeight: "40vh",
    padding: "10px 20px",
  },
  CareerContainer: {
    minWidth: "250px",
    height: "300px",
    padding: "16px",
    marginStart: "2px",
    marginTop: "4px",
  },
  CareerLogo: {
    height: "210px",
  },
  careersContainer: {
    overflow: "scroll",
    // width: "88vw",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      overflowX: "scroll",
    },
  },
}));

function createSummaryRows(data) {
  return data?.map((row) => ({
    name: row?.career_name,
    score: row?.total,
    remark: getCareerRemark(row?.total),
    subjects_aligned: row?.subjects_aligned,
  }));
}

function Dashboard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth.user);
  const { role: userRole, data: userData, isCampusStudent } = user;

  const first_name = userData?.["custom:first_name"];
  const last_name = userData?.["custom:last_name"];

  // STATES
  const [loading, setLoading] = useState(true);
  const [isDashboardEmpty, setIsDashboardEmpty] = useState(false);
  const [completedCareers, setCompletedCareers] = useState([]);
  const [subscribedCareers, setSubscribedCareers] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [isClassLoading, setIsClassLoading] = useState(true);
  const [classrooms, setClassrooms] = useState(null);
  const [isActiveClassroom, setIsActiveClassroom] = useState(false);
  const [startClassLoading, setStartClassLoading] = useState([]);

  // EFFECTS
  useEffect(() => {
    setLoading(true);
    getUserDashboard();

    if (isCampusStudent) {
      setIsClassLoading(true);
      dispatch(getStudentClassrooms())
        .then(unwrapResult)
        .then((response) => {
          const data = response?.data?.data;
          setStartClassLoading(data.map((e) => false));
          setIsActiveClassroom(data?.some?.status === "IN_PROGRESS");
          setClassrooms(data);
          setIsClassLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsClassLoading(false);
        });
    }
  }, []);

  //
  const getUserDashboard = () => {
    dispatch(getDashboard())
      .then(unwrapResult)
      .then((response) => {
        if (response?.status === 200) {
          if (Array.isArray(response?.data)) {
            if (!response?.data.length) {
              setIsDashboardEmpty(true);
            }
          } else if (Object.keys(response?.data).length) {
            let dashboard_data = response?.data;

            if (
              dashboard_data?.completed?.length === 0 &&
              dashboard_data?.enrolled?.length === 0 &&
              dashboard_data?.published?.length === 0
            ) {
              setIsDashboardEmpty(true);
            } else {
              setIsDashboardEmpty(false);
              setCompletedCareers(dashboard_data?.completed);
              setSubscribedCareers([
                ...dashboard_data?.enrolled,
                ...dashboard_data?.published,
              ]);

              renderSummaryData();
            }
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        const message = error?.message ? error.message : "Something went wrong";

        dispatch(
          showSnackbar({
            message: message,
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
      });
  };

  const renderSummaryData = () => {
    dispatch(getUserSummary())
      .then(unwrapResult)
      .then((response) => {
        if (response?.status === 200) {
          setSummaryData(createSummaryRows(response?.data));
        }

        setLoading(false);
      })
      .catch((error) => {
        const message = error?.message ? error.message : "Something went wrong";

        dispatch(
          showSnackbar({
            message: message,
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
      });
  };

  const startStudentClassroom = (id, index) => {
    setStartClassLoading((prevState) => {
      let state = [...prevState];
      state[index] = true;
      return state;
    });

    let body = {
      classroom: id,
    };

    dispatch(startClassroom({ body }))
      .then(unwrapResult)
      .then((response) => {
        setClassrooms((state) => {
          state[index] = {
            ...state[index],
            status: "IN-PROGRESS",
          };

          return state;
        });
        getUserDashboard();
      })
      .catch((error) => {
        const message = error?.message ? error.message : "Something went wrong";

        dispatch(
          showSnackbar({
            message: message,
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
      })
      .finally(() => {
        setStartClassLoading((prevState) => {
          let state = [...prevState];
          state[index] = false;
          return state;
        });
      });
  };

  // METHODS
  const gotoExplore = () => {
    navigate(`/careers`);
  };

  // UTILITY
  const capitalizeFirstLetter = (string) => {
    if (!string) return null;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.root
      )}
    >
      <div className={clsx("flex flex-row")}>
        {isCampusStudent ? (
          <Typography variant="h4">Welcome to your dashboard</Typography>
        ) : (
          <Typography variant="h4">
            Hi, {capitalizeFirstLetter(first_name)}{" "}
            {capitalizeFirstLetter(last_name)}
          </Typography>
        )}
      </div>

      <div>
        {isCampusStudent && (
          <div className="flex flex-col my-4">
            <Typography variant="h6">Your Classrooms</Typography>
            <Typography variant="body2">
              Classrooms assigned by your school for careers assessments
            </Typography>

            {isClassLoading ? (
              <Skeleton
                variant="wave"
                width={200}
                height={200}
                className="my-4"
              />
            ) : (
              <div className="flex flex-row space-x-4 my-4">
                {classrooms?.map((classroom, index) => (
                  <Paper
                    key={classroom._id}
                    className="flex flex-col justify-between"
                    style={{ width: "200px", height: "200px", padding: "12px" }}
                  >
                    <div>
                      <div className="flex flex-col mb-2">
                        <Typography variant="caption">Class</Typography>
                        <Typography variant="subtitle" className="font-bold">
                          {classroom?.classroom?.classroom}
                        </Typography>
                      </div>

                      <div className="flex flex-col mb-2">
                        <Typography variant="caption">Status</Typography>
                        <Typography variant="subtitle" className="font-bold">
                          {classroom["status"] === "IN_PROGRESS"
                            ? "IN-PROGRESS"
                            : classroom["status"]}
                        </Typography>
                      </div>
                    </div>

                    {classroom["status"] === "ENROLLED" &&
                      !isActiveClassroom && (
                        <LoadingButton
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            startStudentClassroom(classroom.class, index)
                          }
                          loading={startClassLoading[index]}
                        >
                          Start
                        </LoadingButton>
                      )}
                  </Paper>
                ))}
              </div>
            )}
            <Divider />
          </div>
        )}
      </div>

      <div className={clsx("flex flex-col")}>
        {isDashboardEmpty ? (
          <div
            className={clsx(
              "h-full flex flex-col justify-center items-center",
              classes.emptyListContent
            )}
          >
            {isCampusStudent ? (
              <Typography variant="h6">
                You don't have any active classroom.
              </Typography>
            ) : (
              <Typography variant="h6">
                You haven't enrolled for any career
              </Typography>
            )}
            <div className={clsx("mt-4")}>
              {!isCampusStudent && (
                <Button variant="outlined" color="accent" onClick={gotoExplore}>
                  Explore Careers
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div className={clsx("flex flex-col")}>
            {subscribedCareers?.length ? (
              <div className={clsx("flex flex-col")}>
                <Typography variant="h6">Your Careers</Typography>
                {isCampusStudent && (
                  <Typography variant="body2">
                    Careers in current active classroom
                  </Typography>
                )}

                <div
                  className={clsx(
                    "flex flex-row mt-4 pb-2 space-x-8",
                    classes.careersContainer
                  )}
                >
                  {subscribedCareers?.map((career, index) => (
                    <Paper
                      key={index}
                      className={clsx(
                        "flex flex-col justify-start items-stretch space-y-4",
                        classes.CareerContainer
                      )}
                    >
                      <Typography variant="subtitle" className="font-bold">
                        {career?.master?.name}
                      </Typography>

                      <img
                        className={clsx(classes.CareerLogo)}
                        src={career?.master?.logo_bg}
                        alt="parent-illustration"
                      />
                    </Paper>
                  ))}
                </div>
              </div>
            ) : null}

            {completedCareers?.length ? (
              <div className={clsx("flex flex-col", classes.CompletedCareers)}>
                <Typography variant="h6">Your Assessment</Typography>
                <Typography variant="body2">
                  Careers' assessment taken so far
                </Typography>

                <div
                  className={clsx(
                    "flex flex-row mt-4 pb-2 space-x-8",
                    classes.careersContainer
                  )}
                >
                  {completedCareers?.map((career, index) => (
                    <Paper
                      key={index}
                      className={clsx(
                        "flex flex-col justify-start items-stretch space-y-4",
                        classes.CareerContainer
                      )}
                    >
                      <Typography variant="subtitle" className="font-bold">
                        {career?.master?.name}
                      </Typography>

                      <img
                        className={clsx(classes.CareerLogo)}
                        src={career?.master?.logo_bg}
                        alt="parent-illustration"
                      />
                    </Paper>
                  ))}
                </div>

                <div className="my-10">
                  <Divider />
                </div>

                <Typography variant="h6">Summary</Typography>
                <div
                  className={clsx(
                    "flex flex-col mt-4",
                    classes.careerSummaryContainer
                  )}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Career</TableCell>
                          <TableCell align="left">Score</TableCell>
                          <TableCell align="left">Remark</TableCell>
                          <TableCell align="left">Subjects Aligned</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summaryData?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              sx={{ maxWidth: 100 }}
                              component="th"
                              scope="row"
                            >
                              {row.name}
                            </TableCell>

                            <TableCell sx={{ maxWidth: 20 }} align="left">
                              {row.score}
                            </TableCell>

                            <TableCell sx={{ maxWidth: 120 }} align="left">
                              <CircleIcon
                                sx={{
                                  color: row.remark?.color,
                                  marginRight: "8px",
                                }}
                              />
                              {row.remark?.remark}
                            </TableCell>

                            <TableCell sx={{ maxWidth: 200 }} align="left">
                              {row?.subjects_aligned?.map((subject) => (
                                <Chip className="my-1 mx-1" label={subject} />
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
