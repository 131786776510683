import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

//
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import CircleIcon from "@mui/icons-material/Circle";
import {
  getCareerSummary,
  getEnrolledCareers,
} from "../../store/careers.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import { showSnackbar } from "../../../../../../store/app/snackbar.slice";
import Chip from "@mui/material/Chip";
import Loader from "@app/layout/shared/loader.component";
import { formatSectionsReport, getCareerRemark } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0px",
    },
  },
  ReportContainer: {
    margin: "30px 0px",
  },
  ReportCardScore: {
    padding: "20px 30px",
    width: "300px",
    height: "250px",
  },
  ReportCardSubjects: {
    padding: "20px 30px",
    width: "450px",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  DetailedReportContainer: {
    marginTop: "30px",
  },
  SectionScoreCard: {
    padding: "20px 30px",
    width: "300px",
    height: "200px",
  },
  SectionRemarkCard: {
    padding: "20px 30px",
    width: "500px",
    minHeight: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function CareerSummary() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // REDUX
  const userRole = useSelector(({ auth }) => auth.user.role);
  const userData = useSelector(({ auth }) => auth.user.data);

  // STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const [careerName, setCareerName] = useState(searchParams.get("name"));
  const [loading, setLoading] = useState(false);
  const [careerSummary, setCareerSummary] = useState();
  const [careerScore, setCareerScore] = useState();
  const [maxScore, setMaxScore] = useState();
  const [sections, setSections] = useState();

  // EFFECTS
  useEffect(() => {
    setLoading(true);

    dispatch(getCareerSummary({ careerId: params.careerId }))
      .then(unwrapResult)
      .then((response) => {
        console.log(response);
        if (response?.status === 200) {
          setCareerSummary(response?.data);
          setCareerScore(
            getCareerRemark(response?.data?.user_map_career?.total)
          );
          const sectionsReport = formatSectionsReport(
            response?.data?.career_contents?.sections
          );
          setSections(sectionsReport);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const message = error?.message ? error.message : "Something went wrong";

        dispatch(
          showSnackbar({
            message: message,
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
      });
  }, [params]);

  useEffect(() => {
    if (sections?.length) {
      let _maxScore = 0;
      sections.forEach((section) => {
        _maxScore += section?.maxSectionScore;
      });

      setMaxScore(_maxScore);
    }
  }, [sections]);

  // FUNCTIONS

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.root
      )}
    >
      <Typography variant="h3">{careerName}</Typography>

      <div
        className={clsx(
          "flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8",
          classes.ReportContainer
        )}
      >
        <Paper
          elevation={3}
          className={clsx("flex flex-col", classes.ReportCardScore)}
        >
          <Typography variant="body1">Your Score</Typography>
          <div className={clsx("flex flex-row justify-start items-end")}>
            <Typography variant="h1">
              {careerSummary?.user_map_career?.total}
            </Typography>
            <div className={"mb-4"}>
              <Typography variant="h5">/{maxScore}</Typography>
            </div>
          </div>

          <div className={clsx("flex flex-row space-x-3")}>
            <CircleIcon sx={{ color: careerScore?.color }} />
            <Typography variant="subtitle1">{careerScore?.remark}</Typography>
          </div>
        </Paper>

        <Paper
          elevation={3}
          className={clsx(
            "flex flex-col justify-start items-stretch",
            classes.ReportCardSubjects
          )}
        >
          <Typography variant="body1">Aligned Subjects</Typography>

          <div className={clsx("w-full flex flex-row flex-wrap")}>
            {careerSummary?.career_contents?.subjects_aligned?.map(
              (subject) => (
                <div className={"m-1"}>
                  <Chip label={subject} />
                </div>
              )
            )}
          </div>
        </Paper>
      </div>

      <div
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.DetailedReportContainer
        )}
      >
        <Typography variant="h5">Your detailed report</Typography>

        <div
          className={clsx(
            "flex flex-col justify-start items-stretch mt-8 space-y-6"
          )}
        >
          {sections?.map((section) => (
            <div
              className={clsx(
                "flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8"
              )}
            >
              <Paper
                elevation={3}
                className={clsx(
                  "flex flex-col justify-start items-stretch",
                  classes.SectionScoreCard
                )}
              >
                <Typography variant="h5">{section?.name}</Typography>
                <div className={clsx("flex flex-row justify-start items-end")}>
                  <Typography variant="h1">{section.sectionScore}</Typography>
                  <div className={"mb-4"}>
                    <Typography variant="h5">
                      /{section?.maxSectionScore}
                    </Typography>
                  </div>
                </div>
              </Paper>

              <Paper
                elevation={3}
                className={clsx(
                  "flex flex-col justify-start items-stretch",
                  classes.SectionRemarkCard
                )}
              >
                <Typography variant="subtitle1">Remark</Typography>

                <div
                  className={clsx(
                    "flex flex-row justify-start items-center space-x-3"
                  )}
                >
                  <CircleIcon sx={{ color: section?.color }} />
                  <Typography variant="h6">{section.remark}</Typography>
                </div>
              </Paper>

              <Divider />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CareerSummary;
