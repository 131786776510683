import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

//
// import withReducer from '../../../store/withReducer';
// import reducer from './store';
// import { getSearch } from "./store/header.slice";
// import { logoutUser } from '@app/auth/store/user.slice';

//
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import OverlayTimer from './overlay.timer';
import {submitResponse} from "../../store/course.slice";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '10px'
        }
    },
    questionContainer: {
        width: "75%",
        height: '70vh',
        [theme.breakpoints.down('sm')]: {
            height: 'inherit',
        }
    },
    rateOptionsContainer: {

    },
    rateOption: {
        height: '150px',
        width: '158px',
        padding: '10px',
        border: '1px solid #cccccca6',
        cursor: 'pointer'
    },
    selectedOption: {
        border: `3px solid ${theme.palette.accent.main}`,
    },
    notSelectedOption: {
        transition: 'all 0.05s ease-out',
        '&:hover': {
            border: `3px solid ${theme.palette.secondary.main}`,
            transform: 'translateY(-5px) scale(1.005) translateZ(0)',
            boxShadow: '0 24px 36px rgba(0,0,0,0.11), 0 24px 46px var(--box-shadow-color)'
        }
    },
    rateEmoticon: {
        width: '80%',
        height: '80%'
    },
    nextButton: {
        width: '200px',
        height: '50px',
    },
    timerContainer: {
        width: '15%',
        marginTop: '10px'
    },
    timerClock: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '10px'
        }
    }
}));

function QuestionRate() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const isMobileMatch = useMediaQuery('(max-width:599px)');

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const userGender = "male";
    const currentSubsection = useSelector(({ student }) => student.course.current_subsection);
    const nextSectionId = useSelector(({ student }) => student.course.next_sectionId);
    const nextSubsectionId = useSelector(({ student }) => student.course.next_subsectionId);
    const nextContentType = useSelector(({ student }) => student.course.next_contentType);
    const questionNumber = useSelector(({student}) => student.course.question_number);

    // STATES
    const [selectedOption, updateSelectedOption] = useState(0);
    const [duration, setDuration] = useState(null);
    const [isTimeOver, setIsTimeOver] = useState(false);
    const [isTimerStart, setIsTimerStart] = useState(false);
    const [pauseTime, setPauseTime] = useState(null);
    const [pauseMessage, setPauseMessage] = useState(null);
    const [content, setContent] = useState(null);
    const [meta, setMeta] = useState(null);
    const [questionContent, setQuestionContent] = useState(null);
    const [isResponseSubmitting, setIsResponseSubmitting] = useState(false);

    // EFFECTS
    useEffect(() => {
        window.scrollTo(0, 0);

        setIsTimeOver(false);
        setIsTimerStart(false);
        updateSelectedOption(0);
        setIsResponseSubmitting(false);
    }, [params]);

    useEffect(() => {
        if(currentSubsection) {
            setContent(currentSubsection.content);
            setMeta(currentSubsection.meta);
        }
    }, [currentSubsection]);

    useEffect(() => {
        if(content && meta) {
            setSubsectionView();
        }
    }, [content, meta]);

    // METHODS
    const setSubsectionView = () => {
        setQuestionContent(content);
        setDuration(content.timer);
        setIsTimerStart(true);
    }

    const handleSelectOption = (option) => {
        updateSelectedOption(option);
    }

    const timerComplete = () => {
        setPauseTime(5);
        setPauseMessage("Pause a while...");
        setIsTimeOver(true);

        if(!isResponseSubmitting) {
            handleClickNext();
        }
    }

    const handlePauseComplete = () => {
        postResponse(() => {
            gotoNext();
        })
    }

    const handleClickNext = () => {
        postResponse(() => {
            gotoNext();
        })
    }

    const postResponse = (callback) => {
        let postPrams = {
            "careerId": params?.careerId,
            "body": {
                "sectionId": params?.sectionId,
                "subSectionId": params?.subsectionId,
                "type": 2,
                "questionType": 3,
                "rating": selectedOption
            }
        };
        setIsResponseSubmitting(true);
        dispatch(submitResponse(postPrams)).then(unwrapResult).then(response => {
            if(response.status === 200) {
                callback();
            } else {

            }
        }).catch(error => {
            console.log(error);
        });
    }

    // NAVIGATION
    const gotoNext = () => {
        navigate(`../../section/${nextSectionId}/${nextSubsectionId}/cn-${nextContentType}`);
    }

    return (
        <div className={clsx("flex flex-row justify-between items-start", classes.root)}>
            {
                isTimeOver &&
                    <OverlayTimer
                        duration={pauseTime}
                        message={pauseMessage}
                        onComplete={handlePauseComplete}
                    />
            }

            <div className={clsx("flex flex-col justify-start items-stretch", classes.questionContainer)}>
                <Typography variant="body1">Question {questionNumber}</Typography>

                <div className={clsx("mt-5")}>
                    <Typography variant="h6">{questionContent?.question}</Typography>
                </div>

                <div className={clsx("mt-8 flex flex-col sm:flex-row space-y-5 sm:space-y-0 sm:space-x-5", classes.rateOptionsContainer)}>
                    <div className={clsx("flex flex-col justify-center items-center", classes.rateOption, selectedOption === 1 ? classes.selectedOption : classes.notSelectedOption)}
                         onClick={() => handleSelectOption(1)}
                    >
                        <img className={clsx("mb-2", classes.rateEmoticon)} src="assets/course/rate-1.png" />
                        <Typography variant="body2">1: Strongly disagree</Typography>
                    </div>

                    <div className={clsx("flex flex-col justify-center items-center", classes.rateOption, selectedOption === 2 ? classes.selectedOption : classes.notSelectedOption)}
                         onClick={() => handleSelectOption(2)}
                    >
                        <img className={clsx("mb-2", classes.rateEmoticon)} src="assets/course/rate-2.png" />
                        <Typography variant="body2">2: Disagree</Typography>
                    </div>

                    <div className={clsx("flex flex-col justify-center items-center", classes.rateOption, selectedOption === 3 ? classes.selectedOption : classes.notSelectedOption)}
                         onClick={() => handleSelectOption(3)}
                    >
                        <img className={clsx("mb-2", classes.rateEmoticon)} src="assets/course/rate-3.png" />
                        <Typography variant="body2">3: Neutral</Typography>
                    </div>

                    <div className={clsx("flex flex-col justify-center items-center", classes.rateOption, selectedOption === 4 ? classes.selectedOption : classes.notSelectedOption)}
                         onClick={() => handleSelectOption(4)}
                    >
                        <img className={clsx("mb-2", classes.rateEmoticon)} src="assets/course/rate-4.png" />
                        <Typography variant="body2">4: Agree</Typography>
                    </div>

                    <div className={clsx("flex flex-col justify-center items-center", classes.rateOption, selectedOption === 5 ? classes.selectedOption : classes.notSelectedOption)}
                         onClick={() => handleSelectOption(5)}
                    >
                        <img className={clsx("mb-2", classes.rateEmoticon)} src="assets/course/rate-5.png" />
                        <Typography variant="body2">5: Strongly Agree</Typography>
                    </div>
                </div>

                <div className={clsx("flex-grow flex flex-row justify-start items-end mt-5 sm:mt-0")}>
                    <Button className={classes.nextButton} disabled={!selectedOption} variant="contained" color="primary" onClick={handleClickNext}>NEXT</Button>
                </div>
            </div>

            <div className={clsx("flex flex-col justify-center items-center", classes.timerContainer)}>
                {
                    isTimerStart &&
                        <>
                            {
                                !isMobileMatch && (
                                    <Typography variant="h6" className={clsx("text-center")}>Time to answer this question</Typography>
                                )
                            }

                            <div className={classes.timerClock}>
                                <CountdownCircleTimer
                                    size={isMobileMatch ? 80 : 150}
                                    strokeWidth={isMobileMatch ? 8 : 12}
                                    isPlaying={isTimerStart}
                                    duration={duration}
                                    colors={['#70CDE0', '#FFC860', '#FE7062']}
                                    colorsTime={[20, 10, 0]}
                                    onComplete={() => {
                                        timerComplete();
                                    }}
                                >
                                    {({remainingTime}) => (
                                        <Typography variant="h4">{remainingTime}</Typography>
                                    )}
                                </CountdownCircleTimer>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default QuestionRate;
