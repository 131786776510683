import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import './style/platform.css';
//
import Header from './components/header';
import Footer from './components/footer';
import SnackbarComponent from "../shared/snackbar.component";

const useStyles = makeStyles(theme => ({
    root: {

    }
}));

function PlatformApp() {
    const classes = useStyles();

    return (
        <div id="platform" className={clsx("flex flex-col justify-between items-stretch", classes.root)}>
            <Header />
            <Outlet />
            <Footer />
            <SnackbarComponent />
        </div>
    );
}

export default PlatformApp;
