import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const postDemoSchedule = createAsyncThunk('school/postDemoSchedule', (data, { dispatch, getState}) => {
    return axios.post(`/platform/demo?usertype=${data.usertype}`, data.body);
});


// ENTITY ADAPTER
const schoolAdapter = createEntityAdapter({});

// SELECTORS
export const { selectAll: selectCareer, selectById: selectCareerById } = schoolAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = schoolAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = schoolSlice.actions;

// REDUCERS
export default schoolSlice.reducer;
