import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
//
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { MobileNavigation } from "./navigation.component";
import { Account } from "../../../platform/components/header";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 30px",
    padding: "20px 0px",
    borderBottom: "1.5px solid #6e6e6e85",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 14px",
    },
  },
  accountButton: {
    width: "200px",
  },
  accountAvatar: {
    height: "40px",
    width: "40px",
    backgroundColor: "#424242",
    // boxShadow: `0 0 0.5rem rgba(0, 0, 0, 0.3)`,
    transition: `background-color 0.3s ease`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#757575",
    },
  },
}));
function StudentHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileMatch = useMediaQuery("(max-width:600px)");

  // REDUX
  const user = useSelector(({ auth }) => auth.user);
  const { role: userRole, data: userData, isCampusStudent } = user;

  // STATES
  const [showAccount, setShowAccount] = useState(false);
  const [accountAnchor, setAccountAnchor] = useState(null);

  const accountAvatarRef = useRef(null);

  // VARS AND CONST
  const first_name = userData?.["custom:first_name"];
  const last_name = userData?.["custom:last_name"];

  const handleAccountMouseOver = (event) => {
    setShowAccount(true);
    setAccountAnchor(event.target);
  };

  const handleAccountMouseOut = (event) => {
    setShowAccount(false);
    setAccountAnchor(event.target);
  };

  const handleAccountAvatarMouseOut = (event) => {
    const boundingRect = accountAvatarRef.current.getBoundingClientRect();
    const exitPosition = boundingRect.bottom - event.clientY;

    if (exitPosition > 10) {
      setShowAccount(false);
      setAccountAnchor(event.target);
    }
  };

  // Mobile Side drawer handler
  const [mobileDrawer, setMobileDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileDrawer(open);
  };

  const closeMobileDrawer = () => setMobileDrawer(false);

  return (
    <div
      className={clsx(
        "flex flex-row justify-between sm:justify-end  items-center",
        classes.root
      )}
    >
      <>
        {isMobileMatch && (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
            >
              <MenuIcon />
            </IconButton>

            <SwipeableDrawer
              anchor={"left"}
              open={mobileDrawer}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <MobileNavigation
                toggleDrawer={toggleDrawer}
                closeMobileDrawer={closeMobileDrawer}
              />
            </SwipeableDrawer>
          </>
        )}
        <button
          ref={accountAvatarRef}
          className={clsx(
            "flex flex-col justify-center items-center rounded-full",
            classes.accountAvatar
          )}
          // onClick={handleCart}
          onMouseEnter={(event) => handleAccountMouseOver(event)}
          onMouseLeave={(event) => handleAccountAvatarMouseOut(event)}
        >
          {isCampusStudent ? (
            <PersonIcon sx={{ color: "white" }} />
          ) : (
            <span className={clsx("text-white")}>
              {first_name && first_name[0]}
              {last_name && last_name[0]}
            </span>
          )}
        </button>
        <ClickAwayListener onClickAway={handleAccountMouseOut}>
          <Popper
            open={showAccount}
            anchorEl={accountAnchor}
            placement="bottom-start"
          >
            <Account mouseLeave={handleAccountMouseOut} />
          </Popper>
        </ClickAwayListener>
      </>
    </div>
  );
}

export default StudentHeader;
