import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { Auth, Hub } from 'aws-amplify';
//
import AuthService from '@app/services/auth/auth.service';
import { postSignupUser } from '@app/auth/store/signup.slice';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
import { setUserData, logoutUser } from "@app/auth/store/user.slice";
//
import Loader from "@app/layout/shared/loader.component";

function GoogleOauth() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // STATES
    const [loading, setLoading] = useState(true);

    // EFFECTS
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {

                let userPayload = currentUser?.signInUserSession?.idToken?.payload;
                let userId = userPayload['custom:user_id'];

                if(userId) {
                    handleUserSignin().then(() => gotoRedirectURL());
                } else {
                    handleMongoSignup(currentUser, userPayload);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }, []);

    // METHODS
    const handleMongoSignup = (user, payload) => {
        const mongoUser = {
            username: payload['cognito:username'],
            first_name: payload['name'].split(' ')[0],
            last_name: payload['name'].split(' ')[1],
            email: payload['email']
        };

        dispatch(postSignupUser(mongoUser)).then(unwrapResult).then(response => {
            Auth.updateUserAttributes(user, {
                'custom:user_type': 'student',
                'custom:user_id': response.data.userId,
                'custom:first_name': mongoUser.first_name,
                'custom:last_name': mongoUser.last_name
            }).then(res => {
                handleUserSignin().then(() => gotoRedirectURL());
            }).catch(error => {
                // Handle error state on custom attributes error set
                console.log(error);
            })
        }).catch(error => {
            // handle signin if user already exist with google signin or with same email in dataset for FORM signup
            if(error.status === 400 && error.data.message === 'User with this Email id already exists') {
                handleUserSignin().then(() => gotoRedirectURL());
            } else {
                // Cognito User Signed Up but MongoSignup causes error
                const message = error?.data?.message ? error?.data?.message : "Something went wrong";

                dispatch(showSnackbar({
                    message: message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));

                // dispatch(logoutUser());
            }
        });
    };

    const handleUserSignin = () => {
        return new Promise((resolve, reject) => {
            AuthService.signInWithToken()
                .then(user => {
                    dispatch(setUserData(user));
                    resolve();
                })
                .catch(error => {

                });
        })
    }

    // NAVIGATION
    const gotoLogin = () => {
        navigate(`/auth/login`);
    }

    const gotoRedirectURL = () => {
        let savedRedirect = sessionStorage.getItem('redirect');
        let redirectUrl = savedRedirect && savedRedirect !== 'null' ? savedRedirect : '/';
        navigate(redirectUrl);
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch")}>

        </div>
    );
}

export default GoogleOauth;
