import Cart from './cart/cart';
import Checkout from './checkout/checkout';
import CheckoutService from './checkout/checkout-service';

const Routes = [
    {
        path: '/cart',
        element: <Cart />
    },
    {
        path: '/checkout',
        element: <Checkout />
    },
    {
        path: '/checkout-services',
        element: <CheckoutService />
    },
];

export default Routes;
