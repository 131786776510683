import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
//
import withReducer from '../../../store/withReducer';
import reducer from './store';
import {showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
import {postDemoSchedule} from "../app/store/parent.slice";
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {ProgressButton} from "@app/layout/shared/buttons.component";
import {postCareerRequest} from "./store/career-request.slice";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '50rem'
    },
    container: {
        width: '100%',
        height: '100%'
    },
    heading: {
        fontWeight: '900 !important'
    },
    form_container: {

    },
    parent_form: {
        marginTop: '60px'
    },
    illustration_container: {},
    parent_illustration: {
        marginTop: '60px',
        width: '80%'
    }
}));

function CareerRequest() {
    const classes = useStyles();
    const dispatch = useDispatch();

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    // STATE
    const [loading, setLoading] = useState(false);

    // HANDLES
    const handleSchedule = () => {
        if(isValid) {
            setLoading(true);

            let body = {
                ...form,
                mobile: `+91${form?.mobile}`
            }

            const data = {
                body: body,
            };

            dispatch(postCareerRequest(data)).unwrap().then(response => {
                if(response.status === 200) {
                    dispatch(showSnackbar({
                        message: response.data.message,
                        autoHideDuration: 6000, //ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'right'  //left center right
                        },
                        variant: 'success'
                    }));
                    reset();
                }
                setLoading(false);
            }).catch(error => {
                console.log(error);
                // Classify Error states for managing
                dispatch(showSnackbar({
                    message: 'Something went wrong, please try again',
                    autoHideDuration: 600000, //ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'right'  //left center right
                    },
                    variant: 'error'
                }));
                setLoading(false);
            });
        } else {
            // dispatch(showSnackbar({
            //     message: 'Please fill all required details.',
            //     autoHideDuration: 6000, //ms
            //     anchorOrigin: {
            //         vertical  : 'bottom',//top bottom
            //         horizontal: 'right'  //left center right
            //     },
            //     variant: 'warning'
            // }));
        }
    }

    const handleMobileChange = (event, callback) => {
        const {value} = event.target;
        if(!isNaN(value)) {
            callback(event);
            return true
        } else {
            return false
        }
    }

    return (
        <div className={clsx("flex flex-col md:flex-row justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col-reverse sm:flex-row", classes.container)}>
                <div className={clsx("w-full sm:w-2/5 flex flex-col justify-start items-center", classes.form_container)}>
                    <div className={clsx("flex flex-col justify-start items-stretch", classes.parent_form)}>
                        <Typography variant="h2" className={clsx(classes.heading)}>Can't find career</Typography>
                        <Typography variant="h6">Let us know, we will work for you...</Typography>

                        <div className={clsx("mt-6 flex flex-col justify-start items-stretch space-y-5")}>
                            <Controller
                                name="careerName"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Career name is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="w-12/25"
                                        error={!!errors.careerName}
                                        required
                                        helperText={errors?.careerName?.message}
                                        label="Career Name"
                                        id="career-name"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Your name is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="w-12/25"
                                        error={!!errors.name}
                                        required
                                        helperText={errors?.name?.message}
                                        label="Name"
                                        id="name"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Enter valid email"
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="w-12/25"
                                        error={!!errors.email}
                                        required
                                        helperText={errors?.email?.message}
                                        label="Email"
                                        id="email"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <div className={clsx("flex flex-row space-x-6")}>
                                <TextField
                                    disabled
                                    value="IND(+91)"
                                    className="w-28 mt-8 mb-16"
                                    id="mobile-code"
                                    variant="outlined"
                                />

                                <Controller
                                    name="mobile"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Mobile is required",
                                        minLength: {
                                            value: 10,
                                            message: "Enter valid mobile number"
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <TextField
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => handleMobileChange(e, onChange)}
                                            onBlur={onBlur}
                                            value={value}
                                            className="flex-grow mt-8 mb-16"
                                            error={!!errors.mobile}
                                            required
                                            helperText={errors?.mobile?.message}
                                            label="Mobile"
                                            id="mobile"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>

                            <Controller
                                name="comments"
                                control={control}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        multiline
                                        rows={4}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="w-12/25"
                                        label="Comments"
                                        id="comments"
                                        variant="filled"
                                    />
                                )}
                            />
                        </div>

                        <div className={clsx("mt-6 flex flex-col justify-center items-stretch")}>
                            <ProgressButton
                                label={"Send"}
                                loading={loading}
                                color={"accent"}
                                classes={"w-full"}
                                handleButtonClick={handleSchedule}
                            />
                        </div>
                    </div>
                </div>

                <div className={clsx("w-full sm:w-3/5 flex flex-col justify-start items-center", classes.illustration_container)}>
                    <img className={classes.parent_illustration} src="assets/homepage/parent-illustration.png" alt="parent-illustration"/>
                </div>
            </div>
        </div>
    );
}

export default withReducer('Platform', reducer)(CareerRequest);
