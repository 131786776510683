import Company from "./company/company";
// COMPONENTS
import AboutUs from './company/components/about-us';
import Terms from './company/components/terms';
import PrivacyPolicy from './company/components/privacy-policy';
import CancellationRefundPolicy from "./company/components/cancellation-refund-policy";
import Disclaimer from './company/components/disclaimer';
import Contact from './contact-us/contact'

const Routes = [
    {
        path: 'company',
        element: <Company />,
        children: [
            {
                path: 'about-us',
                element: <AboutUs/>
            },
            {
                path: 'terms',
                element: <Terms/>
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy/>
            },
            {
                path: 'cancellation-refund-policy',
                element: <CancellationRefundPolicy/>
            },
            {
                path: 'disclaimer',
                element: <Disclaimer/>
            }
        ]
    },
    {
        path: 'contact-us',
        element: <Contact/>
    }
];

export default Routes;
