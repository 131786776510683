import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Outlet, useNavigate } from "react-router-dom";

// COMPONENTS
import Header from "./components/header.component";
import Navigator from "./components/navigation.component";
import {useSelector} from "react-redux";
import UserDetailsModal from './components/user-details.dialog';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    container: {
        width: '100%'
    }
}));

function MainApp() {
    const classes = useStyles();
    const navigate = useNavigate();

    const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);

    useEffect(() => {
        if(userData && !(userData?.gender && userData?.phone_number && userData?.email)) {
            setShowUserDetailsModal(true);
        }
    }, [userData]);

    return (
        <div className={clsx("flex flex-row justify-start items-stretch", classes.root)}>
            <Navigator />

            <div className={clsx("", classes.container)}>
                <Header />

                <Outlet />
            </div>

            {
                showUserDetailsModal &&
                    <UserDetailsModal
                        showModal={showUserDetailsModal}
                        userData={{
                            email: userData?.email,
                            mobile: userData?.phone_number,
                            gender: userData['custom:gender']
                        }}
                        handleClose={() => setShowUserDetailsModal(false)}
                    />
            }
        </div>
    );
}

export default MainApp;
