import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//
import withReducer from "../../../store/withReducer";
import reducer from "./store";
import { getCareer } from "./store/career.slice";
import { addItem, addItemToCart } from "../user/store/cart.slice";
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Loader from "@app/layout/shared/loader.component";
import CircleIcon from "@mui/icons-material/Circle";
import AuthModal from "../../auth/component/auth.modal";
import { unwrapResult } from "@reduxjs/toolkit";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  careerHeader: {
    height: "55vh",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url("assets/homepage/career-bg.png")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      height: "inherit",
      backgroundPosition: "bottom",
    },
  },
  careerMetaContainer: {
    width: "70%",
    padding: "20px 5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  careerLogoContainer: {
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  careerMeta: {
    width: "100%",
    height: "100%",
    paddingLeft: "165px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  careerBreadcrumb: {},
  careerHeading: {
    marginTop: "45px",
  },
  careerStatus: {
    marginTop: "30px",
  },
  careerStart: {
    flexGrow: 1,
  },
  startNowButton: {},
  careerLogoCard: {
    position: "relative",
    top: "35%",
    height: "300px",
    width: "300px",
    boxShadow: "0 3px 20px rgb(0 0 0 / 42%)",
    borderRadius: "10px",
    background: "white",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      height: "150px",
      width: "150px",
    },
  },
  careerLogoImg: {
    width: "85%",
    margin: "14px 10px",
  },
  careerDetailsContainer: {
    marginTop: "10%",
    marginBottom: "6%",
    marginLeft: "170px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
  careerProperties: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  careerVideoContainer: {
    marginBottom: "80px",
  },
  videoContent: {
    width: "60%",
  },
}));

const COLORS = {
  Science: "#84c3c9",
  Commerce: "#ea8170",
  Arts: "#256d70",
  Independent: "#f7935c",
};

function Career() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useParams();

  // REDUX
  const user = useSelector(({ auth }) => auth.user);
  const cartItems = useSelector(({ user }) => user.cart.items);

  const { role: userRole, data: userData, isCampusStudent } = user;

  // STATES
  const [loading, setLoading] = useState(false);
  const [isCareerAdded, setIsCareerAdded] = useState(false);
  const [isCareerAdding, setIsCareerAdding] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [career, setCareer] = useState(null);
  const [showAuth, setShowAuth] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  // EFFECTS
  useEffect(() => {
    setLoading(true);
    dispatch(getCareer({ id }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setCareer(response.data);
        } else {
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (cartItems?.length && career) {
      console.log(cartItems);
      let index = cartItems.findIndex((el, i) => el.id === career._id);

      if (index > -1) {
        setIsCareerAdded(true);
        setLoading(false);
      }
    }
  }, [cartItems, career]);

  // METHODS
  const handleAddToCart = () => {
    setIsCareerAdding(true);

    if (userData) {
      dispatch(
        addItemToCart({
          id: id,
          name: career?.name,
          field: career?.field,
          selling_price: career?.public?.selling_price,
          strike_price: career?.public?.strike_price,
          logo: career?.public?.logo,
          logo_bg: career?.public?.logo_bg,
        })
      );
    } else {
      dispatch(
        addItem({
          id: career?._id,
          name: career?.name,
          field: career?.field,
          selling_price: career?.public?.selling_price,
          strike_price: career?.public?.strike_price,
          logo: career?.public?.logo,
          logo_bg: career?.public?.logo_bg,
        })
      );
    }
  };

  const handleBuyNow = () => {
    setIsBuying(true);

    if (userData) {
      if (isCareerAdded) {
        navigate("/cart");
      } else {
        dispatch(
          addItemToCart({
            id: career._id,
            name: career.name,
            field: career.field,
            selling_price: career.public.selling_price,
            strike_price: career.public.strike_price,
            logo: career.public.logo,
            logo_bg: career.public.logo_bg,
          })
        )
          .then(unwrapResult)
          .then((payload) => {
            navigate("/cart");
          })
          .catch((err) => {
            setIsBuying(false);
            dispatch(
              showSnackbar({
                message: "Something went wrong.",
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                  vertical: "bottom", //top bottom
                  horizontal: "left", //left center right
                },
                variant: "error",
              })
            );
          });
      }
    } else {
      setShowAuth(true);
      setIsBuying(false);
    }
  };

  const handleCloseAuthModal = () => {
    setShowAuth(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.root
      )}
    >
      <div
        className={clsx(
          "flex flex-col sm:flex-row justify-start items-stretch",
          classes.careerHeader
        )}
      >
        <div
          className={clsx(
            "flex flex-col justify-start items-center",
            classes.careerMetaContainer
          )}
        >
          <div className={clsx("flex flex-col", classes.careerMeta)}>
            <div className={clsx(classes.careerBreadcrumb)}>
              <Breadcrumbs separator="›" color="white" aria-label="breadcrumb">
                <Link underline="hover" color="white" href="/careers">
                  Careers
                </Link>
                {/*<Typography color="white">Independent Careers</Typography>*/}
              </Breadcrumbs>
            </div>

            <div className={clsx("space-y-4", classes.careerHeading)}>
              <Typography variant="h4">{career?.name}</Typography>
              <Typography variant="body1">
                {career?.public?.header_description}
              </Typography>
            </div>

            <div
              className={clsx("flex flex-row space-x-20", classes.careerStatus)}
            >
              {/*<Typography variant="body1">Ratings</Typography>*/}
              {/*<Typography variant="body1">Language</Typography>*/}
              {/*<Typography variant="body1">Creator</Typography>*/}
            </div>

            {!isCampusStudent && (
              <div
                className={clsx(
                  "flex flex-col-reverse sm:flex-row justify-start sm:items-center sm:space-x-8",
                  classes.careerStart
                )}
              >
                <div
                  className={clsx(
                    "flex flex-row justify-start items-center space-x-8"
                  )}
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={loading}
                    onClick={handleAddToCart}
                    disabled={isCareerAdded}
                  >
                    {isCareerAdded ? (
                      <span>Added</span>
                    ) : (
                      <span>Add to cart</span>
                    )}
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    color="accent"
                    loading={isBuying}
                    onClick={handleBuyNow}
                    disabled={isBuying}
                  >
                    Buy Now
                  </LoadingButton>
                </div>

                <div
                  className={clsx(
                    "flex flex-row justify-start sm:items-center space-x-3 mb-8 sm:mb-0"
                  )}
                >
                  <Typography variant="h5">
                    Rs. {career?.public?.selling_price}
                  </Typography>
                  <Typography variant="body1">
                    <strike>Rs. {career?.public?.strike_price}</strike>
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className={clsx(
            "flex flex-col justify-center sm:justify-end items-center",
            classes.careerLogoContainer
          )}
        >
          <div
            className={clsx(
              "flex flex-col justify-center items-center",
              classes.careerLogoCard
            )}
          >
            <img
              className={clsx(classes.careerLogoImg)}
              src={career?.public?.logo_bg}
              alt="career-logo"
            />
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.careerDetailsContainer
        )}
      >
        <div
          className={clsx(
            "flex flex-col justify-start items-stretch space-y-6",
            classes.careerProperties
          )}
        >
          {career?.public?.properties &&
            career?.public?.properties.map((property, index) => (
              <>
                {property.type === "list" && (
                  <div className={clsx("flex flex-col space-y-4")}>
                    <Typography className={"font-black"} variant="h4">
                      What will you explore about this career ?
                    </Typography>

                    <div
                      className={clsx(
                        "flex flex-col justify-start items-start space-y-3",
                        classes.orderedList
                      )}
                    >
                      {property.list_type === "numeric" &&
                        property.content.map((item, index) => (
                          <div className={clsx("flex flex-row space-x-2")}>
                            <Typography variant="body1">
                              {index + 1}.
                            </Typography>
                            <Typography variant="body1" key={index}>
                              {item}
                            </Typography>
                          </div>
                        ))}

                      {property.list_type === "bullets" &&
                        property.content.map((item, index) => (
                          <div className={clsx("flex flex-row space-x-2")}>
                            <Typography variant="body1">
                              <CircleIcon sx={{ fontSize: "15px" }} />
                            </Typography>
                            <Typography variant="body1" key={index}>
                              {item}
                            </Typography>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                {property.type === "title_body" && (
                  <div className={clsx("flex flex-col space-y-1")}>
                    <Typography className={"font-black"} variant="h4">
                      {property.header}
                    </Typography>
                    <Typography className={clsx()} variant="h6">
                      {property.body}
                    </Typography>
                  </div>
                )}
              </>
            ))}
        </div>
      </div>

      {/*<div className={clsx("flex flex-col justify-start items-center", classes.careerVideoContainer)}>*/}
      {/*    <video className={clsx(classes.videoContent)} controls controlsList="nodownload nofullscreen noremoteplayback">*/}
      {/*        <source />*/}
      {/*        <track default kind="captions" />*/}
      {/*    </video>*/}
      {/*</div>*/}

      {showAuth && (
        <AuthModal
          redirectUrl={location?.pathname}
          showAuth={showAuth}
          handleClose={handleCloseAuthModal}
        />
      )}
    </div>
  );
}

export default Career;
