import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {createSearchParams, useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import {Auth} from "aws-amplify";
import {setUserData} from '../../../../auth/store/user.slice';

// AUTH MODAL
const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    imageContainer: {
        width: '36%'
    },
    mainImage: {
        height: '100%'
    },
    content: {
        width: '100%'
    },
    contentBody: {
        width: '100%',
        marginTop: '20px'
    },
    actionBody: {
        width: '90%'
    },
    horizontalLine: {
        width: '45%',
        height: '1px'
    },
    horizontalLineText: {
        width: '10%',
        padding: '0 10px'
    },
    oAuthBody: {
        width: '90%'
    }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: "340px"
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '.dialog-auth-header': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& #dialog-logo': {
        width: '100px'
    },
    '.dialog-auth-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    }
}));
const BootstrapDialogHeader = (props) => {
    const { children, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
        </DialogTitle>
    );
};

BootstrapDialogHeader.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function UserDetailsModal({
    showModal,
    handleClose,
    userData
}) {
    const {email, mobile, gender} = userData;

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    useEffect(() => {
        setOpen(showModal)
    }, [showModal]);

    const handleClickConfirm = () => {
        // handleConfirm();
        setOpen(true);
    };

    const handleClickClose = () => {
        handleClose();
        setOpen(false);
    };

    const updateDetails = () => {
        setLoading(true);
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                Auth.updateUserAttributes(currentUser, {
                    'email': form.email,
                    'phone_number': '+91' + form.mobile,
                    'gender': form.gender
                }).then(res => {
                    Auth.currentAuthenticatedUser().then(updatedCurrentUser => {
                        const user = {
                            role: updatedCurrentUser.attributes['custom:user_type'],
                            user_id: updatedCurrentUser.attributes['custom:user_id'],
                            data: updatedCurrentUser.attributes
                        };

                        dispatch(setUserData(user));
                        handleClose();
                    })
                }).catch(error => {
                    // Handle error state on custom attributes error set
                    console.log(error);
                    setLoading(false);
                })
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    return (
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogHeader id="customized-dialog-title" onClose={handleClickClose}>
                Please fill the following details to continue
            </BootstrapDialogHeader>

            <DialogContent>
                <div className={clsx("flex flex-col justify-start items-stretch mt-5")}>
                    <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.actionBody)}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={email || ""}
                            rules={
                                {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Enter valid email'
                                    }
                                }
                            }
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    className={clsx(classes.signupInput)}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={!!errors.email}
                                    required
                                    helperText={errors?.email?.message}
                                    label="Email"
                                    id="email"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Controller
                            name="mobile"
                            control={control}
                            defaultValue={mobile ? (mobile.length > 10 ? mobile.slice(3) : mobile) : "" }
                            rules={
                                {
                                    required: "Mobile is required",
                                    pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Enter valid mobile'
                                    },
                                    minLength: {
                                        value: 10,
                                        message: 'Enter 10 digit number'
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: 'Enter 10 digit number'
                                    },
                                }
                            }
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                                    }}
                                    className={clsx(classes.signupInput)}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={!!errors.mobile}
                                    required
                                    helperText={errors?.mobile?.message}
                                    label="Mobile"
                                    id="mobile"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Controller
                            name="gender"
                            control={control}
                            defaultValue={gender?.toLowerCase() || ""}
                            rules={{ required: "Gender is required" }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    select
                                    className={clsx(classes.signupInput)}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    error={!!errors.gender}
                                    required
                                    helperText={errors?.gender?.message}
                                    label="Gender"
                                    id="gender"
                                    variant="outlined"
                                >
                                    <MenuItem  value="male">Male</MenuItem>
                                    <MenuItem  value="female">Female</MenuItem>
                                    <MenuItem  value="other">Other</MenuItem>
                                </TextField>
                            )}
                        />

                        <LoadingButton variant="contained" loading={loading} onClick={updateDetails}>Update</LoadingButton>
                    </div>
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}

export default UserDetailsModal;
