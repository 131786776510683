import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

function StudentAuth(props) {
  const navigate = useNavigate();
  const location = useLocation();

  // REDUX
  const userRole = useSelector(({ auth }) => auth.user.role);
  const userData = useSelector(({ auth }) => auth.user.data);

  // STATES
  const [access, updateAccess] = useState(null);

  useEffect(() => {
    if (userRole && userData) {
      updateAccess(true);
    } else {
      navigate("/");
    }
  }, [userRole, userData]);

  return <>{access ? props.children : null}</>;
}

export default StudentAuth;
