import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

// ENTITY ADAPTER
const mobileAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = mobileAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = mobileAdapter.getInitialState({
    drawerOpen: {
        event: null,
        state: false
    },
});

// SLICE
const mobileSlice = createSlice({
    name: 'mobileStore',
    initialState,
    reducers: {
        setDrawerOpen: {
            reducer: (state, action) => {
                state.drawerOpen.event = action.payload.event;
                state.drawerOpen.state = action.payload.state;
            }
        }
    }
});

// ACTIONS
export const { setDrawerOpen } = mobileSlice.actions;

// REDUCERS
export default mobileSlice.reducer;
