import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getFields = createAsyncThunk('career/getFields', (id, { dispatch, getState}) => {
    return axios('/platform/careers/fields');
});

export const getCareer = createAsyncThunk('career/getCareer', (params, { dispatch, getState}) => {
    return axios(`/platform/careers/${params?.id}`);
});

// ENTITY ADAPTER
const careersAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = careersAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = careersAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const careersSlice = createSlice({
    name: 'careers',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = careersSlice.actions;

// REDUCERS
export default careersSlice.reducer;
