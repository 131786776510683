import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import appConfig from '@config/config';

// ASYNC THUNK OPERATIONS
export const getCourseData = createAsyncThunk('checkout-service/getCourseData', (payload, { dispatch, getState, rejectWithValue}) => {
    return axios.get(`${appConfig.NODE_URL}/course/certification/${payload?.id}`).then(response => {
        return response
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

export const createOrder = createAsyncThunk('checkout-service/createOrder', (payload, { dispatch, getState, rejectWithValue}) => {
    return axios.post(`${appConfig.NODE_URL}/course/order`, payload).then(response => {
        return response
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

export const updateOrder = createAsyncThunk('checkout-service/updateOrder', (payload, { dispatch, getState, rejectWithValue}) => {
    return axios.post(`${appConfig.NODE_URL}/course/${payload._id}`, payload?.data).then(response => {
        return response
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

export const enrollAll = createAsyncThunk('checkout/enrollAll', (payload, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.post(`/platform/cart/enroll?userId=${userId}`).then(response => {
        return response
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

// ENTITY ADAPTER
const cartAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = cartAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = cartAdapter.getInitialState({
    status: 0,
    loading: false
});

// SLICE
const checkoutServiceSlice = createSlice({
    name: 'checkout-service',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = checkoutServiceSlice.actions;

// REDUCERS
export default checkoutServiceSlice.reducer;
