import React, {useEffect, useState} from 'react';
import { makeStyles } from "@mui/styles";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import { useNavigate, useLocation } from "react-router-dom";

//

// MUI
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SchoolIcon from '@mui/icons-material/School';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import {useDispatch} from "react-redux";

const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    backgroundColor: `${theme.palette.primary.main} !important`
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundColor: `${theme.palette.primary.main} !important`
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0px 30px",
        padding: "20px 0px",
        borderBottom: "1.5px solid #6e6e6e85"
    },
    accountMetaContainer: {
        transition: "all 0.4s"
    },
    accountMetaContainerOpen: {
        height: "190px"
    },
    accountMetaContainerClosed: {
        height: "60px"
    },
    accountMetaOpen: {
        height: "130px",
        width: "130px",
    },
    accountMetaClosed: {
        height: "45px",
        width: "45px",
    },
    navigationLogoContainer: {
        marginBottom: "18px",
        borderRadius: "100%",
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: "all 0.4s"
    },
    navigationLogo: {
        width: "75%"
    }

}));

function StudentNavigations() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobileMatch = useMediaQuery('(max-width:600px)');

    const classes = useStyles();
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    // NAVIGATION
    const gotoHome = () => {
        navigate('/');
    }

    const gotoDashboard = () => {
        navigate('./dashboard');
    }

    const gotoCareers = () => {
        navigate('./careers');
    }

    const gotoSubjects = () => {
        navigate('./subjects');
    }

    const gotoSessions = () => {
        navigate('./sessions')
    }

    if(isMobileMatch) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer variant={"permanent"} open={open}>
                <DrawerHeader>
                    {
                        open ?
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon sx={{color: 'white'}} /> : <ChevronLeftIcon sx={{color: 'white'}} />}
                            </IconButton>
                            :
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    ...(open && { display: 'none' })
                                }}
                            >
                                <MenuIcon sx={{color: 'white'}} />
                            </IconButton>

                    }
                </DrawerHeader>

                <div className={clsx("flex flex-col justify-start items-center", classes.accountMetaContainer, open ? classes.accountMetaContainerOpen : classes.accountMetaContainerClosed)}>
                    <div className={clsx("flex flex-col justify-center items-center", classes.navigationLogoContainer, open ? classes.accountMetaOpen : classes.accountMetaClosed)} onClick={gotoHome}>
                        <img className={clsx(classes.navigationLogo)} src="assets/logo/logo_icon.svg" />
                    </div>
                </div>

                <List>
                    <ListItemButton
                        onClick={gotoDashboard}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <DashboardIcon sx={{color: 'white'}} />
                        </ListItemIcon>

                        <Typography variant="subtitle1" sx={{ display: open ? 'inherit' : 'none', color: 'white'}}>Dashboard</Typography>
                    </ListItemButton>

                    <ListItemButton
                        onClick={gotoCareers}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <SchoolIcon sx={{color: 'white'}} />
                        </ListItemIcon>

                        <Typography variant="subtitle1" sx={{ display: open ? 'inherit' : 'none', color: 'white'}}>Careers</Typography>
                    </ListItemButton>

                    <ListItemButton
                        onClick={gotoSubjects}
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <ArchitectureIcon sx={{color: 'white'}} />
                        </ListItemIcon>
                        <Typography variant="subtitle1" sx={{ display: open ? 'inherit' : 'none', color: 'white'}}>Subjects</Typography>
                    </ListItemButton>

                    {/*<ListItemButton*/}
                    {/*    onClick={gotoSessions}*/}
                    {/*    sx={{*/}
                    {/*        minHeight: 48,*/}
                    {/*        justifyContent: open ? 'initial' : 'center',*/}
                    {/*        px: 2.5,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <ListItemIcon*/}
                    {/*        sx={{*/}
                    {/*            minWidth: 0,*/}
                    {/*            mr: open ? 3 : 'auto',*/}
                    {/*            justifyContent: 'center',*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <TimelapseIcon sx={{color: 'white'}} />*/}
                    {/*    </ListItemIcon>*/}

                    {/*    <Typography variant="subtitle1" sx={{ display: open ? 'inherit' : 'none', color: 'white'}}>Sessions</Typography>*/}
                    {/*</ListItemButton>*/}
                </List>
            </Drawer>
        </Box>
    );
}

export const MobileNavigation = (props) => {
    const {toggleDrawer, closeMobileDrawer} = props;

    const classes = useStyles();
    const navigate = useNavigate();

    const gotoHome = () => {
        closeMobileDrawer();
        navigate('/');
    }

    const gotoDashboard = () => {
        closeMobileDrawer();
        navigate('./dashboard');
    }

    const gotoCareers = () => {
        closeMobileDrawer();
        navigate('./careers');
    }

    const gotoSubjects = () => {
        closeMobileDrawer();
        navigate('./subjects');
    }

    const gotoSessions = () => {
        closeMobileDrawer();
        navigate('./sessions')
    }

    return (
        <Box
            sx={{ height: '100%', width: "250px", backgroundColor: '#70CDE0' }}
            role="presentation"
            onClick={(event) => toggleDrawer("left", false)}
            onKeyDown={(event) => toggleDrawer("left", false)}
        >
            <div className={clsx("flex flex-col justify-start items-center", classes.accountMetaContainer, classes.accountMetaContainerOpen)}>
                <div className={clsx("flex flex-col justify-center items-center", classes.navigationLogoContainer, classes.accountMetaOpen)} onClick={gotoHome}>
                    <img className={clsx(classes.navigationLogo)} src="assets/logo/logo_icon.svg" />
                </div>
            </div>

            <List>
                <ListItemButton
                    onClick={gotoDashboard}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                        }}
                    >
                        <DashboardIcon sx={{color: 'white'}} />
                    </ListItemIcon>

                    <Typography variant="subtitle1" sx={{ display: 'inherit', color: 'white'}}>Dashboard</Typography>
                </ListItemButton>

                <ListItemButton
                    onClick={gotoCareers}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                        }}
                    >
                        <SchoolIcon sx={{color: 'white'}} />
                    </ListItemIcon>

                    <Typography variant="subtitle1" sx={{ display: 'inherit', color: 'white'}}>Careers</Typography>
                </ListItemButton>

                <ListItemButton
                    onClick={gotoSubjects}
                    sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: 'center',
                        }}
                    >
                        <ArchitectureIcon sx={{color: 'white'}} />
                    </ListItemIcon>
                    <Typography variant="subtitle1" sx={{ display: 'inherit', color: 'white'}}>Subjects</Typography>
                </ListItemButton>

                {/*<ListItemButton*/}
                {/*    onClick={gotoSessions}*/}
                {/*    sx={{*/}
                {/*        minHeight: 48,*/}
                {/*        justifyContent: 'initial',*/}
                {/*        px: 2.5,*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <ListItemIcon*/}
                {/*        sx={{*/}
                {/*            minWidth: 0,*/}
                {/*            mr: 3,*/}
                {/*            justifyContent: 'center',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <TimelapseIcon sx={{color: 'white'}} />*/}
                {/*    </ListItemIcon>*/}

                {/*    <Typography variant="subtitle1" sx={{ display: 'inherit', color: 'white'}}>Sessions</Typography>*/}
                {/*</ListItemButton>*/}
            </List>
        </Box>
    )
}

export default StudentNavigations;
