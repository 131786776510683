import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        height: '95vh'
    },
    container: {
        height: '100%'
    },
    heading: {
        fontWeight: '900 !important'
    },
    form_container: {

    },
    contact_form: {
        marginTop: '60px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            marginBottom: '20px'
        },
    },
    illustration_container: {},
    contact_illustration: {
        marginTop: '60px',
        width: '80%'
    }
}));

function Contact() {
    const classes = useStyles();

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col-reverse sm:flex-row", classes.container)}>
                <div className={clsx("w-full sm:w-2/5 flex flex-col justify-start items-center", classes.form_container)}>
                    <div className={clsx("flex flex-col justify-start items-stretch", classes.contact_form)}>
                        <Typography variant="h2" className={clsx(classes.heading)}>Contact Us</Typography>
                        <div className={clsx("flex flex-col space-y-2 mt-10")}>
                            <Typography variant="h6">Book Your Career Counseling Session At</Typography>
                            <Typography variant="body1">Email: careerconnectspassion@gmail.com</Typography>
                            <Typography variant="body1">Phone: +91 7042694615</Typography>
                        </div>

                        <div className={clsx("mt-16")}>
                            <Typography variant="body1">Address: 53 3rd Floor, Street 7, South Ganesh Nagar, Delhi 110092</Typography>
                        </div>
                    </div>
                </div>

                <div className={clsx("w-full sm:w-3/5 flex flex-col justify-start items-center", classes.illustration_container)}>
                    <img className={classes.contact_illustration} src="assets/homepage/contact-illustration.png" alt="contact-illustration"/>
                </div>
            </div>
        </div>
    );
}

export default Contact;
