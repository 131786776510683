import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {useNavigate, useLocation, useParams} from "react-router-dom";
//

//
import Button from '@mui/material/Button';
import OverlayTimer from './overlay.timer';
import { submitResponse } from "../../store/course.slice";
import { unwrapResult } from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    videoContainer: {
        minHeight: '80vh',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'inherit',
            marginTop: '35%'
        }
    },
    videoContent: {
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    nextButton: {
        width: '200px',
        height: '50px',
    }
}));

function VideoContent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const userGender = useSelector(({auth}) => auth.user.data.gender);
    const currentSubsection = useSelector(({ student }) => student.course.current_subsection);
    const nextSectionId = useSelector(({ student }) => student.course.next_sectionId);
    const nextSubsectionId = useSelector(({ student }) => student.course.next_subsectionId);
    const nextContentType = useSelector(({ student }) => student.course.next_contentType);

    // STATES
    const [overlayStartTimer, showOverlayStartTimer] = useState(false);
    const [overlayEndTimer, showOverlayEndTimer] = useState(false);
    const [content, setContent] = useState(null);
    const [meta, setMeta] = useState(null);
    const [videoContent, setVideoContent] = useState(null);
    const [duration, setDuration] = useState(null);
    const [timerMessage, setTimerMessage] = useState();
    const [isVideoWatched, setIsVideoWatched] = useState(false);
    const [isVideoStart, setIsVideoStart] = useState(false);

    // REF
    const videoRef = useRef(null);

    // EFFECTS
    useEffect(() => {
        showOverlayStartTimer(false);
        showOverlayEndTimer(false)
        setVideoContent(null);
        setDuration(null);
        setIsVideoWatched(false);
        setIsVideoStart(false);
        setTimerMessage(null);
        videoRef.current = null;
    }, [params]);

    useEffect(() => {
        const url = window.location.href;

        window.onpopstate = function(event) {
            if(window.confirm("You will lose all the data and will need to re buy to take this career.")) {
                navigate("/user-student/main/dashboard");
            } else {
                navigate(location.pathname)
            }
        };

        setDuration(5);
        // setTimerMessage("Video is about to start, sit tight...");
        showOverlayStartTimer(true);
    }, [params]);

    useEffect(() => {
        if(videoRef.current) {
            videoRef.current.onended = () => {
                let postPrams = {
                    "careerId": params?.careerId,
                    "body": {
                        "sectionId" : params?.sectionId,
                        "subSectionId" : params?.subsectionId,
                        "type" : 1
                    }
                };

                dispatch(submitResponse(postPrams)).then(unwrapResult).then(response => {
                    if(response.status === 200) {
                        setIsVideoWatched(true);
                        setIsVideoStart(false);

                        console.log(nextContentType);
                        if(nextContentType == 1) {
                            handleEndVideo();
                        } else {
                            showOverlayEndTimer(true);
                            setDuration(content.pause_time);
                            // setTimerMessage("");
                        }
                    } else {

                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [videoContent, videoRef])

    useEffect(() => {
        if(currentSubsection && currentSubsection._id.$oid === params.subsectionId) {
            setContent(currentSubsection.content);
            setMeta(currentSubsection.meta);
        }
    }, [currentSubsection, params]);

    useEffect(() => {
        if(content && meta) {
            setSubsectionView();
        }
    }, [content, meta])

    // METHODS
    const setSubsectionView = () => {
        // LOGIC TO UPDATE FOR AFTER SETTING LOGGED IN USER AND GET VIDEO URL ACCORDINGLY
        let isVideoAvailable = meta?.gender[userGender] || meta?.gender?.all;

        if(isVideoAvailable) {
            if(meta?.gender[userGender]) {
                setVideoContent(content.video[userGender]);
            } else if(meta?.gender?.all) {
                setVideoContent(content.video['all']);
            }
        }
    };

    const handleStartTimerComplete = () => {
        handleStartVideo();
    };

    const handleEndTimerComplete = () => {
        handleEndVideo();
    };

    const handleStartVideo = () => {
        showOverlayStartTimer(false);
        setTimeout(() => {
            videoRef?.current?.play();
            videoRef.current.muted = false;
        }, 1000);
    };

    const handleEndVideo = () => {
        gotoNext();
    }

    // NAVIGATION
    const gotoNext = () => {
        navigate(`../../section/${nextSectionId}/${nextSubsectionId}/cn-${nextContentType}`);
    }

    return (
        <>
            {
                overlayStartTimer &&
                    <OverlayTimer
                        duration={duration}
                        message={timerMessage}
                        onComplete={handleStartTimerComplete}
                    />
            }

            {
                overlayEndTimer &&
                    <OverlayTimer
                        duration={duration}
                        message={timerMessage}
                        onComplete={handleEndTimerComplete}
                    />
            }

            <div className={clsx("flex flex-col justify-start items-center", classes.videoContainer)}>
                {
                    videoContent &&
                        <video ref={videoRef} className={clsx(classes.videoContent)} controls={process.env.REACT_APP_ENVIRONMENT !== 'prod'} controlsList="nodownload nofullscreen noremoteplayback noplay" muted={true}>
                            <source src={videoContent?.url} />
                            <track default kind="captions" />
                        </video>
                }
            </div>
        </>
    );
};

export default VideoContent;
