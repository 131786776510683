import Careers from "./careers";
import Career from "./career";
import CareerRequest from "./career-request";

const Routes = [
    {
        path: '/careers',
        element: <Careers />
    },
    {
        path: '/careers/:id',
        element: <Career />
    },
    {
        path: '/career-request',
        element: <CareerRequest />
    }
];

export default Routes;
