import MainApp from "./main.app";
import DashboardRoutes from "./modules/dashboard/dashboard.routes";
import CareersRoutes from "./modules/careers/careers.routes";
import SubjectsRoutes from "./modules/subjects/subjects.routes"
import SessionsRoutes from "./modules/sessions/sessions.routes"

const Routes = [
    {
        path: 'main',
        element: <MainApp />,
        children: [
            ...DashboardRoutes,
            ...CareersRoutes,
            ...SubjectsRoutes,
            ...SessionsRoutes
        ]
    }
];

export default Routes;
