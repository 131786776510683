import { amber, blue, green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import {memo, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSnackbar } from '../../store/app/snackbar.slice';

const useStyles = makeStyles(theme => ({
    root: {},
    success: {
        backgroundColor: green[600],
        color: '#FFFFFF'
    },
    error: {
        backgroundColor: '#d32f2f',
        color: theme.palette.getContrastText(theme.palette.error.dark)
    },
    info: {
        backgroundColor: blue[600],
        color: '#FFFFFF'
    },
    warning: {
        backgroundColor: amber[600],
        color: '#FFFFFF'
    }
}));

const variantIcon = {
    success: 'check_circle',
    warning: 'warning',
    error: 'error_outline',
    info: 'info'
};

function SnackbarComponent(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const state = useSelector(({ app }) => app.snackbar.state);
    const options = useSelector(({ app }) => app.snackbar.options);

    return (
        <Snackbar
            {...options}
            open={state}
            onClose={() => dispatch(hideSnackbar())}
            classes={{
                root: classes.root
            }}
            ContentProps={{
                variant: 'body2',
                headlineMapping: {
                    body1: 'div',
                    body2: 'div'
                }
            }}
        >
            <SnackbarContent
                className={clsx(classes[options.variant])}
                message={
                    <div className="max-w-sm flex flex-row justify-between items-center">
                        {variantIcon[options.variant] && <Icon className={clsx("mr-4")} color="inherit">{variantIcon[options.variant]}</Icon>}
                        <Typography variant="body1">{options.message}</Typography>
                    </div>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={() => dispatch(hideSnackbar())}>
                        <Icon>close</Icon>
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
}

export default memo(SnackbarComponent);
