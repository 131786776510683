import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const postCareerRequest = createAsyncThunk('careerRequest/postCareerRequest', (data, { dispatch, getState}) => {
    return axios.post(`/platform/careers/request`, data.body);
});


// ENTITY ADAPTER
const careerRequestAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = careerRequestAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = careerRequestAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const careerRequestSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = careerRequestSlice.actions;

// REDUCERS
export default careerRequestSlice.reducer;
