import { createSlice } from '@reduxjs/toolkit';
import AuthService from '@app/services/auth/auth.service';
import { setUserData } from './user.slice'

export const submitLogin =
    ({ username, password }) =>
        async dispatch => {
            return AuthService
                .signInWithUsernameAndPassword(username, password)
                .then(response => {
                    const user  = {
                        role: response.attributes['custom:user_type'],
                        user_id: response.attributes['custom:user_id'],
                        data: response.attributes
                    };

                    dispatch(setUserData(user));
                    return dispatch(loginSuccess());
                })
                .catch(errors => {
                    const error = errors && errors.message ? errors : null ;
                    return dispatch(loginError([error]));
                });
        };

const initialState = {
    success: false,
    errors: []
};

const loginSlice = createSlice({
    name: 'auth/login',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.success = true;
            state.errors = [];
        },
        loginError: (state, action) => {
            state.success = false;
            state.errors = action.payload;
        },
        resetLoginStatus: (state, action) => {
            state.success = false;
            state.errors = [];
        }
    },
    extraReducers: {}
});

export const { loginSuccess, loginError, resetLoginStatus } = loginSlice.actions;

export default loginSlice.reducer;
