import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate, useLocation } from "react-router-dom";
//
// import withReducer from '../../../store/withReducer';
// import reducer from './store';
// import { getSearch } from "./store/header.slice";
// import { logoutUser } from '@app/auth/store/user.slice';

//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from "@mui/material/Popper";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import CircleIcon from '@mui/icons-material/Circle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles(theme => ({
    questionContainer: {
        height: '70vh'
    },
    answerContainer: {

    },
    answerInput: {
        width: '60%',
        height: '200px'
    },
    nextButton: {
        width: '200px',
        height: '50px',
    }
}));

function QuestionRadio() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);

    // STATES
    const [selectedAnswer, updateSelectedAnswer] = useState(null);

    // EFFECTS
    useEffect(() => {

    }, []);

    // METHODS

    // NAVIGATION
    const gotoNext = () => {
        navigate('../../section/123/2/cn-3');
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.questionContainer)}>
            <Typography variant="body1">Question</Typography>

            <div className={clsx("mt-5")}>
                <Typography variant="h6">I like the task and activities presented in this career option</Typography>
            </div>

            <div className={clsx("mt-5 flex flex-col justify-start items-stretch space-x-5", classes.answerContainer)}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-question"
                        value={selectedAnswer}
                        onChange={(event) => updateSelectedAnswer(event.target.value)}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Option 1" />
                        <FormControlLabel value="2" control={<Radio />} label="Option 2" />
                        <FormControlLabel value="3" control={<Radio />} label="Option 3" />
                    </RadioGroup>
                </FormControl>
            </div>

            <div className={clsx("flex-grow flex flex-row justify-start items-end")}>
                <Button className={classes.nextButton} disabled={!selectedAnswer} variant="contained" color="primary" onClick={gotoNext}>NEXT</Button>
            </div>
        </div>
    );
};

export default QuestionRadio;
