import { createSlice, createAsyncThunk, createEntityAdapter, current  } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getCourseContent = createAsyncThunk('studentCourse/getCourseContent', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.get(`/career/content?userMapCareerId=${params.userMapCareerId}`).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});

export const submitResponse = createAsyncThunk('studentCourse/submitResponse', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.post(`/career/${params?.careerId}/user/${userId}/submit`, params?.body).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});

export const submitCareer = createAsyncThunk('studentCourse/submitCareer', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.put(`/career/${params?.careerId}/user/${userId}/submit`, {}).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});

// ENTITY ADAPTER
const courseAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = courseAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = courseAdapter.getInitialState({
    careerId: null,
    instructions: null,
    subjects_aligned: [],
    sections: [],
    status: 0,
    loaded: 0,
    question_number: null,
    current_section: null,
    current_subsection: null,
    next_sectionId: null,
    next_subsectionId: null,
    next_contentType: null
});

// SLICE
const courseSlice = createSlice({
    name: 'studentCourse',
    initialState,
    reducers: {
        setCurrentSectionAndSubsection: {
            reducer: (state, {payload}) => {
                state.current_section = state.sections.filter(section => section._id.$oid === payload.sectionId)[0];
                state.current_subsection = state.current_section.subsections.filter(subsection => subsection._id.$oid === payload.subsectionId)[0];

                let questionIndex = state.current_section.subsections.findIndex(subsection => subsection._id.$oid === payload.subsectionId);
                state.question_number =  questionIndex === 0 ? questionIndex + 1 : questionIndex;
            }
        },
        setNextSectionAndSubsectionId: {
            reducer: (state, action) => {
                const currentSectionId = state.current_section._id.$oid;
                const currentSubsectionId = state.current_subsection._id.$oid;

                const currentSubsectionIndex = state.current_section?.subsections?.findIndex(subsection => subsection._id.$oid === currentSubsectionId);

                if(currentSubsectionIndex > -1) {
                    if((currentSubsectionIndex+1) < state.current_section?.subsections?.length) {
                        state.next_sectionId = currentSectionId;
                        state.next_subsectionId = state.current_section?.subsections[currentSubsectionIndex + 1]._id.$oid;
                        state.next_contentType = state.current_section?.subsections[currentSubsectionIndex + 1].type;
                    } else {
                        const currentSectionIndex = state.sections.findIndex(section => section._id.$oid === currentSectionId);

                        if(currentSectionIndex > -1) {
                            if((currentSectionIndex+1) < state.sections.length) {
                                state.next_sectionId = state.sections[currentSectionIndex + 1]._id.$oid;
                                state.next_subsectionId = state.sections[currentSectionIndex + 1].subsections[0]._id.$oid;
                                state.next_contentType = state.sections[currentSectionIndex + 1].subsections[0].type;
                            } else {
                                state.next_sectionId = 'end';
                                state.next_subsectionId = 'end';
                            }
                        }
                    }
                }
            }
        },
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        },
        resetCourse: {
            reducer: (state, action) => {
                state.careerId = null;
                state.instructions = null;
                state.subjects_aligned = [];
                state.sections = [];
                state.status = 0;
                state.loaded = 0;
                state.question_number = null;
                state.current_section = null;
                state.current_subsection = null;
                state.next_sectionId = null;
                state.next_subsectionId = null;
                state.next_contentType = null;
            }
        }
    },
    extraReducers: {
        [getCourseContent.fulfilled]: (state, action) => {
            const {status, data} = action.payload;
            if(status === 200) {
                const {careerId, content, status: courseStatus} = data[0];

                state.careerId = careerId?.$oid;
                state.instructions = content.instructions;
                state.subjects_aligned = content.subjects_aligned;
                state.sections = content.sections;
                state.status = courseStatus;

                state.loaded = 1
            }
        }
    }
});

// ACTIONS
export const { setLoading, setCurrentSectionAndSubsection, setNextSectionAndSubsectionId, resetCourse } = courseSlice.actions;

// REDUCERS
export default courseSlice.reducer;
