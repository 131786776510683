import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
//
import {getCheckoutCareers, removeItem, removeItemsFromCart} from '../store/cart.slice';
//
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Loader from "@app/layout/shared/loader.component";
import CareerCheckoutModal from '../components/career-checkout.modal';
import AuthModal from '../../../auth/component/auth.modal';
import {unwrapResult} from "@reduxjs/toolkit";
import {showSnackbar} from "../../../../store/app/snackbar.slice";

const cartStyles = makeStyles(theme => ({
    cartRoot: {
        height: '140px',
        border: '1px solid #cccccca6',
        '&:hover': {
            // backgroundColor: '#cccccca6'
        },
        [theme.breakpoints.down('sm')]: {
            height: '150px'
        },
    },
    container: {
        width: '100%',
        height: '100%',
    },
    careerLogoContainer: {
        width: '130px',
        padding: '0px 10px',
        margin: '20px 0',
        borderRight: '1px solid #cccccca6'
    },
    careerLogo: {
        height: '90%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    careerMeta: {
        padding: '15px 15px'
    },
    grow: {
        flexGrow: '1'
    }
}));
function CartItem(props) {
    const dispatch = useDispatch();
    const classes = cartStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { data } = props;

    // STATES
    const [item, updateItem] = useState(null);

    // EFFECTS
    useEffect(() => {
        updateItem(data);
    }, [data])

    // METHODS
    const handleRemove = () => {
        dispatch(removeItemsFromCart({careers: [item.id]})).then(unwrapResult).then(response => {
            dispatch(removeItem({id: item.id}));
        }).catch(error => {
            console.log(error);
        });
    }

    // NAVIGATION
    const gotoCareer = () => {
        navigate(`/careers/${item.id}`)
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.cartRoot)}>
            <div className={clsx("flex flex-row", classes.container)}>
                <div className={clsx("flex flex-col justify-center items-center", classes.careerLogoContainer)}>
                    <img className={classes.careerLogo} onClick={gotoCareer} src={item?.logo} alt="career-logo"/>
                </div>

                <div className={clsx("flex-grow flex flex-row justify-between items-stretch", classes.careerMeta)}>
                    <div className={clsx("flex flex-col justify-between items-stretch", classes.careerDetails)}>
                        <div className={clsx(classes.careerItemHeader)}>
                            <Typography className={clsx("font-bold cursor-pointer")} variant="h6" onClick={gotoCareer}>{item?.name}</Typography>
                            <Typography variant="body2">{item?.field?.name}</Typography>
                        </div>

                        <div className={clsx("flex flex-row justify-start items-center space-x-2", classes.careerItemPrice)}>
                            <Typography variant="body1" className={clsx("font-bold")}>₹{item?.selling_price}</Typography>
                            <Typography variant="body2" className={clsx("text-grey")}><strike>₹{item?.strike_price}</strike></Typography>
                        </div>
                    </div>

                    <div className={clsx("flex flex-col justify-between items-stretch", classes.careerItemAction)}>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button>-</Button>
                            <Button disabled sx={{color: 'black'}}>1</Button>
                            <Button>+</Button>
                        </ButtonGroup>

                        <Button variant="outlined" color="error" size="small" onClick={handleRemove}>
                            <DeleteForeverIcon className={clsx("mr-2")} size="small" />
                            Remove
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '70vh',
        margin: '20px 0px'
    },
    container: {
        width: '80%',
        margin: '30px 0px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px 20px',
        },
    },
    content: {
        margin: '35px 0px'
    },
    cartEmpty: {
        height: '40vh',
        border: '1px solid #cccccca6'
    },
    cartContent: {

    },
    cartItems: {
        width: '65%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    cartCheckout: {
        width: '32%',
        height: '28rem',
        border: '1px solid #cccccca6',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'inherit'
        },
    },
    promotionsContainer: {
        margin: '20px 0'
    }
}));
function Cart() {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const cartLoading = useSelector(({user}) => user.cart.loading);
    const cartItems = useSelector(({user}) => user.cart.items);
    const cartSellingTotal = useSelector(({user}) => user.cart.selling_total);
    const cartStrikeTotal = useSelector(({user}) => user.cart.strike_total);

    // STATES
    const [loading, setLoading] = useState(false);
    const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [enrolledCareers, setEnrolledCareers] = useState([]);
    const [showAuth, setShowAuth] = useState(false);

    // EFFECTS
    useEffect(() => {
        setLoading(cartLoading);
    }, [cartLoading]);

    // METHODS
    const handleCheckout = () => {
        setIsCheckoutLoading(true);

        if(userData) {
            dispatch(getCheckoutCareers()).then(unwrapResult).then(response => {
                if(response.status === 200) {
                    const {data} = response;

                    if(data?.length) {
                        let enrolledCareersData = cartItems.filter(el => data.some(v => v?.careerId?.$oid === el.id));
                        setEnrolledCareers(enrolledCareersData);
                        setShowCheckout(true);
                    } else {
                        navigate('/checkout');
                    }
                }
            }).catch(error => {
                console.log(error);
                const message = error?.data?.message ? error?.data?.message : "Something went wrong";

                dispatch(showSnackbar({
                    message: message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));

                setLoading(false);
            })
            // setShowCheckout(true);
        } else {
            setShowAuth(true);
        }

        // setShowCheckout(true);
        // navigate('/checkout');
    }

    const handleCloseCheckoutModal = () => {
        setShowCheckout(false);
        setIsCheckoutLoading(false);
    }

    const handleCloseAuthModal = () => {
        setShowAuth(false);
        setIsCheckoutLoading(false);
    }

    // NAVIGATIONS
    const gotoExplore = () => {
        navigate(`/careers`);
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col md:flex-row justify-center items-start", classes.root)}>
            <div className={clsx("flex flex-col justify-start items-stretch", classes.container)}>
                <Typography className={clsx("font-bold")} variant="h3">Careers Cart</Typography>

                <div className={clsx("flex flex-col justify-start items-stretch", classes.content)}>
                    {
                        cartItems && cartItems.length > 0 ?
                            <div className={clsx("flex flex-col sm:flex-row justify-start sm:justify-between items-center sm:items-start", classes.cartContent)}>
                                <div className={clsx("flex flex-col", classes.cartItems)}>
                                    {
                                        cartItems && cartItems.map((item, index) => (
                                            <CartItem key={index} data={item}/>
                                        ))
                                    }
                                </div>

                                <div className={clsx("flex flex-col justify-start items-stretch", classes.cartCheckout)}>
                                    <div className={clsx("sm:h-full flex flex-col", classes.checkoutContainer)}>
                                        <Typography variant="h5" className={clsx("text-grey-800")}>Total:</Typography>

                                        <div className={clsx("flex flex-col justify-start", classes.checkoutPrice)}>
                                            <Typography variant="h3" className={clsx("font-bold")}>₹{cartSellingTotal}</Typography>
                                            <Typography variant="h5" className={clsx("text-grey")}><strike>₹{cartStrikeTotal}</strike></Typography>
                                        </div>

                                        {/*<div className={clsx("flex flex-col", classes.promotionsContainer)}>*/}
                                        {/*    <div className={clsx("flex flex-row space-x-1")}>*/}
                                        {/*        <TextField className={clsx("flex-grow")} id="coupon" placeholder="Enter Coupon" variant="outlined" size="small" />*/}
                                        {/*        <Button variant="contained" color="primary">Apply</Button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className={clsx("mt-10 sm:mt-0 sm:flex-grow flex flex-col justify-end")}>
                                            <LoadingButton variant="contained" color="primary" sx={{height: '55px'}} onClick={handleCheckout} loading={isCheckoutLoading} disabled={isCheckoutLoading}>
                                                CHECKOUT
                                            </LoadingButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className={clsx("flex flex-col justify-center items-center", classes.cartEmpty)}>
                                <Typography variant="h6">You career cart is empty</Typography>
                                <div className={clsx("mt-6")}>
                                    <Button  variant="outlined" color="accent" onClick={gotoExplore}>Explore Careers</Button>
                                </div>
                            </div>
                    }
                </div>
            </div>

            {
                showCheckout &&
                    <CareerCheckoutModal
                        enrolledCareers={enrolledCareers}
                        showCheckout={showCheckout}
                        handleClose={handleCloseCheckoutModal}
                    />
            }

            {
                showAuth &&
                    <AuthModal
                        redirectUrl={location?.pathname}
                        showAuth={showAuth}
                        handleClose={handleCloseAuthModal}
                    />
            }
        </div>
    );
}

export default Cart;
