import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Auth } from 'aws-amplify';
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {showSnackbar} from "../../../store/app/snackbar.slice";

const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    content: {
        width: '45%',
        height: '94%',
        border: '1px solid #cccccca6',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80%',
            height: '98%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: '98%',
        },
    },
    logoContainer: {
        margin: '30px 0px'
    },
    logoImage: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '250px'
        },
    },
    codeConfirmBody: {
        width: '80%',
        marginTop: '20px'
    },
    verifyButton: {
        marginTop: '30px'
    },
    resendButton: {
        marginTop: '4px'
    },
    resendTimer: {
        marginTop: '40px',
        color: theme.palette.accent.main
    },
    resendLoader: {
        marginTop: '40px'
    },
    homeButton: {
        marginTop: '20px'
    }
}));

function ChangePassword({username}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //
    const [loading, setLoading] = useState(false);

    // FORM CONTROL
    const methods = useForm({ mode: 'onBlur' });
    const { watch,  control, formState } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    const { verification_code, password, confirm_password } = form;

    // EFFECTS

    // METHODS
    const changePassword = () => {
        if(isValid) {
            setLoading(true);
            Auth.forgotPasswordSubmit(username, verification_code, password)
                .then(data => {
                    setLoading(false);

                    dispatch(showSnackbar({
                        message: "Password has been reset successfully",
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'success'
                    }));

                    navigate(`/auth/login`);
                })
                .catch(err => {
                    setLoading(false);

                    dispatch(showSnackbar({
                        message: err.message,
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'error'
                    }));
                });
        }
    }

    const handleResend = () => {
        if(username) {
            setLoading(true)
            Auth.forgotPassword(username)
                .then(data => {
                    setLoading(false);

                    dispatch(showSnackbar({
                        message: "Verification code sent successfully",
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'success'
                    }));
                })
                .catch(err => {
                    setLoading(false);

                    dispatch(showSnackbar({
                        message: err.message,
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'error'
                    }));
                });
        }
    }

    //
    const gotoHome = () => {
        navigate(`/`);
    }

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col justify-center items-center", classes.container)}>
                <div className={clsx("flex flex-col justify-start items-stretch", classes.content)}>
                    <div className={clsx("flex flex-col justify-start items-center", classes.logoContainer)}>
                        <img className={clsx(classes.logoImage)} src="../../../assets/logo/logo_text.svg" />
                    </div>

                    <div className={clsx("flex flex-col justify-start items-center", classes.confirmCodeContainer)}>
                        <Typography variant="h4">Forgot Password</Typography>

                        <div className={clsx("flex flex-col justify-start items-center space-y-6", classes.codeConfirmBody)}>
                            <Controller
                                name="verification_code"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Code is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className={clsx("w-10/12")}
                                        error={!!errors.verification_code}
                                        required
                                        helperText={errors?.verification_code?.message}
                                        label="Verification Code"
                                        id="verification-code"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Password is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        type="password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className={clsx("w-10/12")}
                                        error={!!errors.password}
                                        required
                                        helperText={errors?.password?.message}
                                        label="Password"
                                        id="password"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="confirm_password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Password is required",
                                    validate: (value) => {
                                        if(password !== value) return "Your password do not match"
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        type="password"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className={clsx("w-10/12")}
                                        error={!!errors.confirm_password}
                                        required
                                        helperText={errors?.confirm_password?.message}
                                        label="Confirm Password"
                                        id="confirm-password"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <LoadingButton className={clsx("w-10/12", classes.verifyButton)} variant="contained" loading={loading} onClick={changePassword}>Reset</LoadingButton>
                        </div>
                        <Button className={clsx(classes.resendButton)} variant="text" size="large" color="accent" onClick={handleResend}>Resend</Button>
                        <Button className={clsx(classes.homeButton)} variant="outlined" size="large" color="primary" onClick={gotoHome}>Home</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
