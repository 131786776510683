import Home from './home';
import Student from './student';
import Parent from './parent';
import School from './school';
import CareerCounsellorCertification from './career-counsellor-certification';

const Routes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/student',
        element: <Student />
    },
    {
        path: '/parent',
        element: <Parent />
    },
    {
        path: '/school',
        element: <School />
    },
    {
        path: '/career-counsellor',
        element: <CareerCounsellorCertification />
    }
];

export default Routes;
