import React, { useState, useEffect} from "react";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography';
import {unwrapResult} from "@reduxjs/toolkit";
import {showSnackbar} from "../../../../../store/app/snackbar.slice";
import {getDashboard} from "../store/dashboard.slice";
import Button from "@mui/material/Button";
import Loader from "@app/layout/shared/loader.component";
import {useNavigate} from "react-router-dom";
import PieChart from "./components/PieChart"
import BarChart from "./components/BarChart";
import {getAlignedSubjects} from "../store/subjects.slice";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 30px',
    },
    emptyListContent: {
        height: '72vh'
    },
    chartContainer: {
        margin: '20px 10px'
    },
    PieChartContainer: {
        width: '50%',
        marginTop: '50px'
    },
    BarChartContainer: {
        width: '75%'
    },
    EmptyContainer: {
        height: '85vh'
    }
}));

const dynamicColors = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
};

function Subjects() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector(({auth}) => auth.user.data);

    const first_name = userData['custom:first_name'];
    const last_name = userData['custom:last_name'];

    // STATES
    const [loading, setLoading] = useState(false);
    const [subjectsAlignedData, setSubjectsAlignedData] = useState();
    const [chartData, setChartData] = useState();

    // EFFECTS
    useEffect(() => {
        setLoading(true);
        dispatch(getAlignedSubjects()).then(unwrapResult).then(response => {
            setSubjectsAlignedData(response?.data?.subjectsAligned);
            setLoading(false);
        }).catch(error => {
            const message = error?.message ? error.message : "Something went wrong";

            dispatch(showSnackbar({
                message: message,
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'right'//left center right
                },
                variant: 'error'
            }));

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if(subjectsAlignedData) {
            makeChartData();
        }
    }, [subjectsAlignedData])

    // METHODS
    const gotoExplore = () => {
        navigate(`/careers`);
    }

    // UTILITY
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const makeChartData = () => {
        const labels = Object.keys(subjectsAlignedData);
        const dataValues = Object.values(subjectsAlignedData);

        setChartData(state => ({
            labels,
            datasets: [
                {
                    label: 'Aligned #',
                    data: dataValues,
                    backgroundColor: dataValues.map((el, index) => dynamicColors()),
                },
            ],
        }));
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            {
                chartData ?
                    <>
                        <div className={clsx("flex flex-row")}>
                            <Typography variant="h4">Subjects Aligned</Typography>
                        </div>

                        <div className={clsx("flex flex-col justify-center items-center", classes.chartContainer)}>
                            <div className={clsx("flex flex-col", classes.BarChartContainer)}>
                                <BarChart
                                    chartData={chartData}
                                />
                            </div>

                            <div className={clsx("flex flex-col", classes.PieChartContainer)}>
                                <PieChart
                                    chartData={chartData}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <div className={clsx("flex flex-col justify-center items-center", classes.EmptyContainer)}>
                        <Typography variant="h6">
                            You have not completed any course.
                        </Typography>
                    </div>
            }
        </div>
    );
};

export default Subjects;
