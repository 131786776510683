/* eslint import/no-extraneous-dependencies: off*/
import { createSlice } from "@reduxjs/toolkit";
import AuthService from "@app/services/auth/auth.service";

// export const setUserDataAuth0 = tokenData => async dispatch => {
//     const user = {
//         role: ['admin'],
//         from: 'auth0',
//         data: {
//             displayName: tokenData.username || tokenData.name,
//             photoURL: tokenData.picture,
//             email: tokenData.email,
//             settings:
//                 tokenData.user_metadata && tokenData.user_metadata.settings ? tokenData.user_metadata.settings : {},
//             shortcuts:
//                 tokenData.user_metadata && tokenData.user_metadata.shortcuts ? tokenData.user_metadata.shortcuts : []
//         }
//     };
//
//     return dispatch(setUserData(user));
// };
//
// export const setUserDataFirebase = (user, authUser) => async dispatch => {
//     if (
//         user &&
//         user.data &&
//         user.data.settings &&
//         user.data.settings.theme &&
//         user.data.settings.layout &&
//         user.data.settings.layout.style
//     ) {
//         // Set user data but do not update
//         return dispatch(setUserData(user));
//     }
//
//     // Create missing user settings
//     return dispatch(createUserSettingsFirebase(authUser));
// };
//
// export const createUserSettingsFirebase = authUser => async (dispatch, getState) => {
//     const guestUser = getState().auth.user;
//     const fuseDefaultSettings = getState().fuse.settings.defaults;
//     const { currentUser } = firebase.auth();
//
//     /**
//      * Merge with current Settings
//      */
//     const user = _.merge({}, guestUser, {
//         uid: authUser.uid,
//         from: 'firebase',
//         role: ['admin'],
//         data: {
//             displayName: authUser.displayName,
//             email: authUser.email,
//             settings: { ...fuseDefaultSettings }
//         }
//     });
//     currentUser.updateProfile(user.data);
//
//     dispatch(updateUserData(user));
//
//     return dispatch(setUserData(user));
// };

export const setUserData = (user) => async (dispatch, getState) => {
  dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  AuthService.logout();
  return dispatch(userLoggedOut());
};

// export const updateUserData = user => async (dispatch, getState) => {
//     if (!user.role || user.role.length === 0) {
//         // is guest
//         return;
//     }
//     switch (user.from) {
//         case 'firebase': {
//             firebaseService
//                 .updateUserData(user)
//                 .then(() => {
//                     dispatch(showMessage({ message: 'User data saved to firebase' }));
//                 })
//                 .catch(error => {
//                     dispatch(showMessage({ message: error.message }));
//                 });
//             break;
//         }
//         case 'auth0': {
//             auth0Service
//                 .updateUserData({
//                     settings: user.data.settings,
//                     shortcuts: user.data.shortcuts
//                 })
//                 .then(() => {
//                     dispatch(showMessage({ message: 'User data saved to auth0' }));
//                 })
//                 .catch(error => {
//                     dispatch(showMessage({ message: error.message }));
//                 });
//             break;
//         }
//         default: {
//             jwtService
//                 .updateUserData(user)
//                 .then(() => {
//                     dispatch(showMessage({ message: 'User data saved with api' }));
//                 })
//                 .catch(error => {
//                     dispatch(showMessage({ message: error.message }));
//                 });
//             break;
//         }
//     }
// };

const initialState = {
  role: null,
  data: null,
  user_id: null,
  isCampusStudent: false,
  campusUuid: null,
  campus: null,
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.role = action.payload.role;
      state.user_id = action.payload.user_id;
      state.data = action.payload.data;
      state.campusUuid = action.payload.data?.["custom:campusId"];
      state.campus = action.payload.data?.["custom:campusUuid"];
      state.isCampusStudent = Boolean(state.campusUuid && state.campus);
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
