import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%'
    }
}));

function AboutUs() {
    const classes = useStyles();

    // STATES

    // EFFECTS

    // METHODS

    return (
        <div className={clsx("flex flex-col space-y-8", classes.root)}>
            <Typography variant="h5">About Us</Typography>

            <Typography variant="body1">
                Career Connects Passion is India’s First Video-Based Career Counseling Platform.
                Our unique platform allows you to explore and understand your suitability for a wide variety of career choices.
                We truly believe in our brand name i.e. Connecting passion with career  for every student on this planet.
            </Typography>
        </div>
    );
}

export default AboutUs;
