import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, createSearchParams } from "react-router-dom";
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
//
import { submitLogin, resetLoginStatus } from '@app/auth/store/login.slice';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    imageContainer: {
        width: '36%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    mainImage: {
        height: '100%'
    },
    content: {
        width: '64%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    contentBody: {
        width: '50%',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    actionBody: {
        width: '80%'
    },
    horizontalLine: {
        width: '45%',
        height: '1px'
    },
    horizontalLineText: {
        width: '10%',
        padding: '0 10px'
    },
    oAuthBody: {
        width: '80%'
    }
}));

function Login() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // REDUX
    const loginStatus = useSelector(({auth}) => auth.login);

    // STATES
    const [loading, setLoading] = useState(false);

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    // EFFECTS
    useEffect(() => {
        // console.log(form);
    }, [form]);

    useEffect(() => {
        if(loginStatus.success){
                dispatch(showSnackbar({
                    message: "You are successfully logged in",
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'success'
                }));

                navigate('/');

                setLoading(false);
        } else if(loginStatus.errors.length) {
            const loginError = loginStatus.errors[0];

            if(loginError.code === "UserNotConfirmedException") {
                navigate({
                    pathname: `../confirm/${form.username}`,
                    search: `?${createSearchParams({
                        state: "old"
                    })}`
                });

                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'info'
                }));
            } else {
                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));
            }
            setLoading(false);
        }
    }, [loginStatus]);

    // Unmount Component
    useEffect(() => () => {
        dispatch(resetLoginStatus());
    },[])

    // METHODS
    const handleLogin = () => {
        if(isValid && isDirty) {
            setLoading(true);
            dispatch(submitLogin({username: form.username, password: form.password}));
        }
    };

    const handleGoogleLogin = () => {
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
    }

    //
    const gotoSignup = () => {
        navigate(`/auth/signup`);
    }

    const gotoHome = () => {
        navigate(`/`);
    }

    const gotoForgotPassword = () => {
        navigate(`/auth/forgot-password`);
    }

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-row", classes.container)}>
                <div className={clsx(classes.imageContainer)}>
                    <img className={clsx(classes.mainImage)} src="../../../assets/auth/auth-bg-7.2.png" />
                </div>

                <div className={clsx("flex flex-col justify-center items-center", classes.content)}>
                    <div className={clsx("flex flex-col justify-center items-center space-y-8", classes.contentBody)}>
                        <div className={clsx("flex flex-")}>

                        </div>

                        <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.actionBody)}>
                            <Controller
                                name="username"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Username is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="mt-8 mb-16 w-12/25"
                                        error={!!errors.username}
                                        required
                                        helperText={errors?.username?.message}
                                        label="Username"
                                        id="username"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <div className={clsx("flex flex-col justify-start items-stretch")}>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "Password is required" }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <TextField
                                            type="password"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            className="mt-8 mb-16 w-12/25"
                                            error={!!errors.password}
                                            required
                                            helperText={errors?.password?.message}
                                            label="Password"
                                            id="password"
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <div className={clsx("flex flex-row justify-end items-center")}>
                                    <Button variant="text" onClick={gotoForgotPassword}>Forgot password?</Button>
                                </div>
                            </div>

                            <LoadingButton variant="contained" loading={loading} onClick={handleLogin}>Let's Go</LoadingButton>
                        </div>

                        <div className={clsx("w-4/5 flex flex-row justify-center items-center")}>
                            <hr className={clsx(classes.horizontalLine)}/>
                            <Typography className={clsx(classes.horizontalLineText)} variant="body1">or</Typography>
                            <hr className={clsx(classes.horizontalLine)}/>
                        </div>

                        <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.oAuthBody)}>
                            <Button variant="google" onClick={handleGoogleLogin}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-google mr-4" viewBox="0 0 16 16">
                                    <path
                                        d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                </svg>
                                Login with Google
                            </Button>

                            {/*<Button variant="facebook">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                            {/*         className="bi bi-facebook mr-4" viewBox="0 0 16 16">*/}
                            {/*        <path*/}
                            {/*            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>*/}
                            {/*    </svg>*/}
                            {/*    Login with Facebook*/}
                            {/*</Button>*/}
                        </div>

                        <div className={clsx("flex flex-row justify-center items-center", classes.SignupBody)}>
                            <Typography variant="subtitle1">Don't have account?</Typography>
                            <Button variant="text" onClick={gotoSignup}>Signup</Button>
                        </div>

                        <Button variant="text-black" onClick={gotoHome}>
                            <ArrowBackIcon className={clsx("mr-3")} />
                            Home
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
