import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
//

//
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Loader from "@app/layout/shared/loader.component";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    sectionCard: {
        margin: "10px 0px",
        padding: "20px",
        width: '100%'
    }
}));

function CourseSection() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const courseLoaded = useSelector(({student}) => student.course.loaded);
    const courseSections = useSelector(({student}) => student.course.sections);
    const currentSection = useSelector(({student}) => student.course.current_section);
    const currentSubsection = useSelector(({student}) => student.course.current_subsection);

    // STATES
    const [loading, setLoading] = useState(false);
    const [sectionId, setSectionId] = useState(params.sectionId);
    const [subsectionId, setSubsectionId] = useState(params.subsectionId);

    // EFFECTS


    // METHODS
    const gotoDashboard = () => {
        navigate('/user-student/main/dashboard');
    }

    //
    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-center items-center", classes.root)}>
            <Typography>You have successfully completed this career</Typography>
            <div className="my-8">
                <Button variant="contained" color="primary" onClick={gotoDashboard}>Go to Dashboard</Button>
            </div>
        </div>
    );
};

export default CourseSection;
