import { memo } from 'react';
import './style/splash.component.css';
import Typography from '@mui/material/Typography';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

function SplashScreen() {
    return (
        <div id="splash-screen">
            <div id="splash-container">
                <div id="splash-logo">
                    <img src="assets/logo/logo_icon.svg" alt="splash-logo" />
                </div>

                <div id="splash-loader">
                    <CircularProgress
                        size={70}
                        thickness={5}
                        disableShrink
                        sx={{
                            animationDuration: '1000ms',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(SplashScreen);
