import PlatformApp from "./platform.app";

// ROUTES
import AppRoutes from './app/app.routes';
import CareersRoutes from './careers/careers.routes';
import CompanyRoutes from './company/company.routes';
import UserRoutes from './user/user.routes';

const routes = [
    {
        path: '/',
        element: <PlatformApp />,
        children: [
            ...AppRoutes,
            ...CareersRoutes,
            ...CompanyRoutes,
            ...UserRoutes
        ]
    }
];

export default routes;
