import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%'
    }
}));

function CancellationRefundPolicy() {
    const classes = useStyles();

    // STATES

    // EFFECTS

    // METHODS

    return (
        <div className={clsx("flex flex-col space-y-8", classes.root)}>
            <Typography variant="h5">Cancellation/Refund Policy</Typography>

            <Typography variant="body1">
                CAREER CONNECTS PASSION  is a career advisory service and it gets delivered as soon as a person buys any career mentioned on our Platform. Once the services are bought, we do not have any provision for cancellation of the service that the person has purchased or subscribed to. In case you feel that you did not get the service to your satisfaction level, you are requested to write into the email.
            </Typography>

            <Typography variant="body1">
                The CAREER CONNECTS PASSION renders services including but not limited to counseling, assessment tests, etc, which act as a bonafide guide to the user and the services are rendered as soon as availed, therefore there is no provision for refund of the fee paid by the User/s. The user/s is therefore deemed to have understood and accepted that once the fee is paid to the CAREER CONNECTS PASSION as per the services availed, there shall be no refund of the said Fee.
            </Typography>
        </div>
    );
}

export default CancellationRefundPolicy;
