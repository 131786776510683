import React, { useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
//
import withReducer from "../../../store/withReducer";
import reducer from "./store";
import { getFields, getCareers } from "./store/careers.slice";
import { addItem, addItemToCart } from "../user/store/cart.slice";
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import LinearProgress from "@mui/material/LinearProgress";
import Loader from "@app/layout/shared/loader.component";
import { unwrapResult } from "@reduxjs/toolkit";
import AuthModal from "../../auth/component/auth.modal";

const COLORS = {
  Science: "#84c3c9",
  Commerce: "#ea8170",
  Arts: "#256d70",
  Independent: "#f7935c",
};

const careerItemStyles = makeStyles((theme) => ({
  card: {
    height: "340px",
    width: "340px",
    boxShadow: "0 3px 20px rgb(0 0 0 / 42%)",
    borderRadius: "25px",
    background: "white",
    margin: "20px",
    transition: "all 0.05s ease-out",
    [theme.breakpoints.down("md")]: {
      margin: "20px 5px",
    },
    "&:hover": {
      // cursor: 'pointer',
      border: "0.3px solid #c3c3c3",
      transform: "translateY(-5px) scale(1.005) translateZ(0)",
      boxShadow:
        "0 24px 36px rgba(0,0,0,0.11), 0 24px 46px var(--box-shadow-color)",
    },
  },
  head: {
    cursor: "pointer",
    height: "30%",
    borderTopLeftRadius: "25px",
    borderTopRightRadius: "25px",
    backgroundColor: theme.palette.primary.main,
  },
  body: {
    height: "70%",
    // margin: '0 32px'
  },
  content: {
    height: "30%",
  },
  careerLogo: {
    cursor: "pointer",
    width: "60%",
  },
  careerLogoCard: {
    position: "relative",
    bottom: "30px",
    height: "150px",
    width: "150px",
    boxShadow: "0 3px 20px rgb(0 0 0 / 42%)",
    borderRadius: "10px",
    background: "white",
  },
  careerLogoImg: {
    height: "85%",
    margin: "14px 10px",
  },
  careerPrice: {
    width: "40%",
  },
  careerHeading: {
    cursor: "pointer",
    margin: "25px 32px 4px",
  },
  careerField: {
    margin: "0px 32px",
  },
  cardAction: {
    margin: "0px 32px 20px",
  },
  TrialContainer: {
    width: "120px",
    height: "100%",
  },
  TrialTextContainer: {
    backgroundColor: "#41c300",
    width: "100%",
    transform: "rotate(38deg)",
  },
}));

function CareerItem(props) {
  const classes = careerItemStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // REDUX
  const user = useSelector(({ auth }) => auth.user);
  console.log(user);
  // const userRole = useSelector(({ auth }) => auth.user.role);
  // const userData = useSelector(({auth}) => auth.user.data);
  const cartItems = useSelector(({ user }) => user?.cart?.items);

  const { role: userRole, data: userData, isCampusStudent } = user;

  const { data } = props;

  // STATES
  const [loading, setLoading] = useState(false);
  const [isCareerAdded, setIsCareerAdded] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const [showAuth, setShowAuth] = useState(false);

  // EFFECTS
  useEffect(() => {
    if (cartItems && cartItems.length) {
      let index = cartItems.findIndex((el, i) => el.id === data._id);

      if (index > -1) {
        setIsCareerAdded(true);
        setLoading(false);
      }
    }
  }, [cartItems]);

  // METHODS
  const gotoCareer = () => {
    navigate(`/careers/${data._id}`);
  };

  const handleAddToCart = () => {
    setLoading(true);

    if (userData) {
      dispatch(
        addItemToCart({
          id: data._id,
          name: data.name,
          field: data.field,
          selling_price: data.public.selling_price,
          strike_price: data.public.strike_price,
          logo: data.public.logo,
          logo_bg: data.public.logo_bg,
        })
      )
        .then(unwrapResult)
        .then((payload) => {
          console.log(payload);
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            showSnackbar({
              message: "Something went wrong.",
              autoHideDuration: 6000, //ms
              anchorOrigin: {
                vertical: "bottom", //top bottom
                horizontal: "left", //left center right
              },
              variant: "error",
            })
          );
        });
    } else {
      setShowAuth(true);
      setLoading(true);
    }
  };

  const handleBuyNow = () => {
    setIsBuying(true);

    if (userData) {
      if (isCareerAdded) {
        navigate("/cart");
      } else {
        dispatch(
          addItemToCart({
            id: data._id,
            name: data.name,
            field: data.field,
            selling_price: data.public.selling_price,
            strike_price: data.public.strike_price,
            logo: data.public.logo,
            logo_bg: data.public.logo_bg,
          })
        )
          .then(unwrapResult)
          .then((payload) => {
            navigate("/cart");
          })
          .catch((err) => {
            setIsBuying(false);
            dispatch(
              showSnackbar({
                message: "Something went wrong.",
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                  vertical: "bottom", //top bottom
                  horizontal: "left", //left center right
                },
                variant: "error",
              })
            );
          });
      }
    } else {
      setShowAuth(true);
      setIsBuying(true);
    }
  };

  const handleCloseAuthModal = () => {
    setShowAuth(false);
    setIsBuying(false);
    setLoading(false);
  };

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.card
      )}
    >
      <div
        className={clsx("flex flex-col justify-start items-end", classes.head)}
        style={{ backgroundColor: COLORS[data.field.name] }}
        onClick={gotoCareer}
      >
        {data?.public?.trial == "true" && (
          <div
            className={clsx(
              "flex flex-row justify-center items-center",
              classes.TrialContainer
            )}
          >
            <div
              className={clsx(
                "flex flex-row justify-center items-center",
                classes.TrialTextContainer
              )}
            >
              <Typography
                variant="h6"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Try it
              </Typography>
            </div>
          </div>
        )}
      </div>

      <div className={clsx("flex flex-col", classes.body)}>
        <div className={clsx("flex flex-row", classes.content)}>
          <div
            className={clsx(
              "flex flex-col justify-center items-center",
              classes.careerLogo
            )}
          >
            <div
              className={clsx(
                "flex flex-col justify-center items-center",
                classes.careerLogoCard
              )}
              onClick={gotoCareer}
            >
              <img
                className={clsx(classes.careerLogoImg)}
                src={data.public.logo}
                alt="career-logo"
              />
            </div>
          </div>
          <div
            className={clsx(
              classes.careerPrice,
              "flex flex-col justify-start items-start mt-4"
            )}
          >
            <Typography variant="body2" className={clsx("text-grey")}>
              <strike>Rs. {data.public.strike_price}</strike>
            </Typography>
            <Typography variant="subtitle1" className={clsx("font-bold")}>
              Rs. {data.public.selling_price}
            </Typography>
          </div>
        </div>

        <div
          className={clsx("flex flex-col", classes.careerHeading)}
          onClick={gotoCareer}
        >
          <Typography className={clsx("font-bold")} variant="h5">
            {data.name}
          </Typography>
        </div>

        <div className={clsx("flex flex-col", classes.careerField)}>
          <Typography variant="caption">{data.field.name}</Typography>
        </div>

        {!isCampusStudent && (
          <>
            <div
              className={clsx(
                "flex-grow flex flex-row justify-start items-end space-x-4",
                classes.cardAction
              )}
            >
              <LoadingButton
                variant="contained"
                color="accent"
                loading={isBuying}
                onClick={handleBuyNow}
                disabled={isBuying}
              >
                Buy Now
              </LoadingButton>

              <LoadingButton
                variant="contained"
                color="secondary"
                loading={loading}
                onClick={handleAddToCart}
                disabled={isCareerAdded}
              >
                {isCareerAdded ? <span>Added</span> : <span>Add to cart</span>}
              </LoadingButton>
            </div>
          </>
        )}
      </div>

      {showAuth && (
        <AuthModal
          redirectUrl={location?.pathname}
          showAuth={showAuth}
          handleClose={handleCloseAuthModal}
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh",
    margin: "2px 0px 20px",
  },
  sidebar: {
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  container: {
    width: "80%",
    // backgroundColor: 'yellow',
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  filterContainer: {
    padding: "18px 30px",
  },
  filterFieldInput: {
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "150px",
    },
  },
  careersContainer: {
    margin: "18px 30px",
    border: "1px solid #cccccca6",
  },
  career: {
    margin: "10px 0px",
  },
  careersLoading: {
    width: "100%",
    minHeight: "75vh",
  },
  noCareersFound: {
    width: "100%",
    minHeight: "75vh",
  },
  pagination: {},
}));

function Careers() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // STATES
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [careersLoading, setCareersLoading] = useState(false);
  const [filterFields, setFilterFields] = useState([]);
  const [selectField, setSelectField] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [careers, updateCareers] = useState(null);

  // EFFECTS
  useEffect(() => {
    setLoading(true);
    dispatch(getFields())
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setFilterFields(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // FILTER IS APPLIED
    const field = searchParams.get("field");

    if (field) {
      setSelectField(field);
      setCareersLoading(true);
      getCareersData({ index: page - 1, size: pageSize, field: field })
        .then((response) => {
          if (response.status === 200) {
            updateCareers(response.data[0].careers);
            setTotalCount(response.data[0].totalCount);
          } else {
            // HANDLE DIFFERENT STATUS CODES
            dispatch(
              showSnackbar({
                message: "Something went wrong",
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                  vertical: "bottom", //top bottom
                  horizontal: "right", //left center right
                },
                variant: "error",
              })
            );
          }
          setCareersLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setCareersLoading(false);
        });
    }
    // NO FILTER - GET ALL CAREERS
    else {
      setCareersLoading(true);
      getCareersData({ index: page - 1, size: pageSize, field: 0 })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            updateCareers(response.data[0].careers);
            setTotalCount(response.data[0].totalCount);
          } else {
            // HANDLE DIFFERENT STATUS CODES
            dispatch(
              showSnackbar({
                message: "Something went wrong",
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                  vertical: "bottom", //top bottom
                  horizontal: "right", //left center right
                },
                variant: "error",
              })
            );
          }
          setCareersLoading(false);
        })
        .catch((error) => {
          console.log(error);
          dispatch(
            showSnackbar({
              message: "Something went wrong",
              autoHideDuration: 6000, //ms
              anchorOrigin: {
                vertical: "bottom", //top bottom
                horizontal: "right", //left center right
              },
              variant: "error",
            })
          );
          setCareersLoading(false);
        });
    }
  }, [searchParams]);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount]);

  // METHODS
  const handleSelectField = (value) => {
    setSelectField(value);
    if (value === 0) {
      setSearchParams({});
    } else {
      setSearchParams({ field: value });
    }
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    setCareersLoading(true);

    const field = searchParams.get("field");
    getCareersData({ index: value - 1, size: pageSize, field: field })
      .then((response) => {
        if (response.status === 200) {
          updateCareers(response.data[0].careers);
          setTotalCount(response.data[0].totalCount);
        } else {
          // HANDLE DIFFERENT STATUS CODES
          dispatch(
            showSnackbar({
              message: "Something went wrong",
              autoHideDuration: 6000, //ms
              anchorOrigin: {
                vertical: "bottom", //top bottom
                horizontal: "right", //left center right
              },
              variant: "error",
            })
          );
        }
        window.scrollTo(0, 0);
        setCareersLoading(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          showSnackbar({
            message: "Something went wrong",
            autoHideDuration: 6000, //ms
            anchorOrigin: {
              vertical: "bottom", //top bottom
              horizontal: "right", //left center right
            },
            variant: "error",
          })
        );
        setCareersLoading(false);
      });
  };

  const getCareersData = (params) => {
    return dispatch(getCareers(params)).unwrap();
  };

  // RENDERS
  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className={clsx(
        "flex flex-col md:flex-row justify-start items-stretch",
        classes.root
      )}
    >
      <div className={clsx(classes.sidebar)}>
        <div
          className={clsx(
            "flex flex-col justify-start items-stretch space-y-2",
            classes.filterContainer
          )}
        >
          <Typography variant="subtitle1">Filter by</Typography>
          <div
            className={clsx(
              "flex flex-row md:flex-col space-x-8 md:space-x-0 space-y-0 md:space-y-6"
            )}
          >
            <TextField
              className={clsx(classes.filterFieldInput)}
              size="small"
              select
              label="Field"
              defaultValue={0}
              value={selectField}
              onChange={(event) => handleSelectField(event.target.value)}
            >
              <MenuItem value={0}>All</MenuItem>
              {filterFields.map((field, index) => (
                <MenuItem key={index} value={field._id}>
                  {field.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.container
        )}
      >
        <div
          className={clsx(
            "flex flex-row flex-wrap justify-start items-start",
            classes.careersContainer
          )}
        >
          {careersLoading ? (
            <>
              <div
                className={clsx(
                  "flex flex-col justify-center items-center",
                  classes.careersLoading
                )}
              >
                <Box sx={{ width: "500px" }}>
                  <LinearProgress sx={{ height: "6px" }} color="primary" />
                </Box>
              </div>
            </>
          ) : (
            <>
              {careers && careers.length > 0 ? (
                <>
                  {careers.map((career, index) => (
                    <CareerItem key={index} data={career} />
                  ))}
                </>
              ) : (
                <>
                  <div
                    className={clsx(
                      "flex flex-col justify-center items-center",
                      classes.noCareersFound
                    )}
                  >
                    <Typography variant="h6">No careers found</Typography>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        <div
          className={clsx(
            "flex flex-row justify-center items-center",
            classes.pagination
          )}
        >
          <Pagination
            disabled={careersLoading}
            count={pageCount}
            page={page}
            variant="outlined"
            shape="rounded"
            size="large"
            color="accent"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default withReducer("Platform", reducer)(Careers);
// export default Careers;
