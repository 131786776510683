import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@mui/styles";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {removeItem, removeItemsFromCart} from "../store/cart.slice";
import clsx from "clsx";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {unwrapResult} from "@reduxjs/toolkit";
import LoadingButton from "@mui/lab/LoadingButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const cartStyles = makeStyles(theme => ({
    cartRoot: {
        height: '140px',
        border: '1px solid #cccccca6',
        '&:hover': {
            // backgroundColor: '#cccccca6'
        }
    },
    container: {
        width: '100%',
        height: '100%',
    },
    careerLogoContainer: {
        width: '130px',
        padding: '0px 10px',
        margin: '20px 0',
        borderRight: '1px solid #cccccca6'
    },
    careerLogo: {
        width: '90%',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    careerMeta: {
        padding: '15px 15px'
    },
    grow: {
        flexGrow: '1'
    }
}));
function CareerItem(props) {
    const dispatch = useDispatch();
    const classes = cartStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { data } = props;

    // STATES
    const [item, updateItem] = useState(null);

    // EFFECTS
    useEffect(() => {
        updateItem(data);
    }, [data])

    // METHODS
    const handleRemove = () => {
        dispatch(removeItem({id: item.id}));
    }

    // NAVIGATION
    const gotoCareer = () => {
        navigate(`/careers/${item.id}`)
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.cartRoot)}>
            <div className={clsx("flex flex-row", classes.container)}>
                <div className={clsx("flex flex-col justify-center items-center", classes.careerLogoContainer)}>
                    <img className={classes.careerLogo} onClick={gotoCareer} src={data?.logo} alt="career-logo"/>
                </div>

                <div className={clsx("flex-grow flex flex-row justify-between items-stretch", classes.careerMeta)}>
                    <div className={clsx("flex flex-col justify-between items-stretch", classes.careerDetails)}>
                        <div className={clsx(classes.careerItemHeader)}>
                            <Typography className={clsx("font-bold cursor-pointer")} variant="h6" onClick={gotoCareer}>{data?.name}</Typography>
                            <Typography variant="body2">{data?.field?.name}</Typography>
                        </div>

                        <div className={clsx("flex flex-row justify-start items-center space-x-2", classes.careerItemPrice)}>
                            <Typography variant="body1" className={clsx("font-bold")}>₹{data?.selling_price}</Typography>
                            <Typography variant="body2" className={clsx("text-grey")}><strike>₹{data?.strike_price}</strike></Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

function CareerCheckoutModal({
    enrolledCareers,
    showCheckout,
    handleClose
}) {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [careers, setCareers] = useState([]);

    useEffect(() => {
        console.log(enrolledCareers);
    }, [])

    useEffect(() => {
        setOpen(showCheckout)
    }, [showCheckout]);

    useEffect(() => {
        setCareers(enrolledCareers);
    }, [enrolledCareers])

    const handleClickConfirm = () => {
        setLoading(true);
        dispatch(removeItemsFromCart({careers: careers.map(el => el.id)})).then(unwrapResult).then(response => {
            console.log(response);
            setLoading(false);
            handleClose();
        }).catch(error => {
            console.log(error);
            setLoading(false);
        })
    };

    const handleClickClose = () => {
        handleClose();
        setOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                Careers already enrolled
            </BootstrapDialogTitle>

            <DialogContent>
                <div className="mb-4">
                    <Typography gutterBottom>
                        Following careers have already enrolled in your account,
                    </Typography>
                </div>

                <div className={clsx("flex flex-col justify-start items-stretch")}>
                    {
                        careers.map((career, index) => (
                            <CareerItem key={index} data={career} />
                        ))
                    }
                </div>

                <div className="mt-4">
                    <Typography gutterBottom>
                        Click confirm to remove these careers from the cart and continue to checkout.
                    </Typography>
                </div>
            </DialogContent>

            <DialogActions className={clsx("mx-3")}>
                <Button variant="outlined" color="accent" onClick={handleClickClose}>Cancel</Button>

                <LoadingButton variant="contained" color="primary" onClick={handleClickConfirm} loading={loading} disabled={loading}>
                    Confirm
                </LoadingButton>
            </DialogActions>
        </BootstrapDialog>
    );
}

export default CareerCheckoutModal;
