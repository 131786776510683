import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
//
import withReducer from '@app/store/withReducer';
import reducer from './store';
import {resetCourse} from "./store/course.slice";
//

const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        padding: '10px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        }
    }
}));

function CourseApp() {
    const classes = useStyles();
    const params = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);

    // STATES

    // EFFECT
    useEffect(() => {
        dispatch(resetCourse());
    }, [])

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <Outlet />
        </div>
    );
};

export default CourseApp;
