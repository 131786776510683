import localConfig from './local.config';
import devConfig from './dev.config';
import stageConfig from './stage.config';
import prodConfig from './prod.config';

export const getAppConfig = () => {
    switch(process.env.REACT_APP_ENVIRONMENT) {
        case "dev":
            return devConfig
        case "stage":
            return stageConfig
        case "prod":
            return prodConfig
        default:
            return localConfig
    }
};

export default getAppConfig();
