import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
//
import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%'
    },
    SubList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start !important',
        alignItems: 'flex-start !important'
    }
}));

function Terms() {
    const classes = useStyles();

    // STATES

    // EFFECTS

    // METHODS

    return (
        <div className={clsx("flex flex-col space-y-8", classes.root)}>
            <Typography variant="h5">Terms</Typography>

            <Typography variant="body1">
                1. INTRODUCTION
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        This document is an electronic record in terms of Information Technology Act, 2000 and Rules made there under as applicable. This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </Typography>
                </ListItem>

                <ListItem>
                    <Typography variant="body1">
                        This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and user agreement of access or usage of a website. Here website means www. CAREERCONNECTSPASSION.com.
                    </Typography>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            www. CAREERCONNECTSPASSION.com, is a video-based career assessment website that helps the users in career planning in the best of their interests. Before using our services/ products you should go through the present Agreement (herein after referred to as EULA) and agree to the terms and conditions of the EULA. The User of the site shall be deemed to have read the terms of the EULA and duly accepted them before browsing through the website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            These terms may be changed / updated from time to time solely at our discretion. By using our site you will be bound by the terms and conditions as mentioned in the EULA. You are required to and responsible for regularly reviewing the EULA to keep yourself updated with the changes/ amendments to the EULA as made by us from time to time. If u continue to access the website subsequent to such changes to EULA, you will be deemed to have accepted and agreed to the said changes.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                2. REFERENCE OF TERMS USED IN EULA
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        The terms used in the present EULA have been defined hereunder:
                    </Typography>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term CAREER CONNECTS PASSION is used through this entire EULA to refer to the Website, its owner, employees, and individuals / entities associated with the Owner.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term ‘USER or YOU or YOUR’ is used through this entire EULA to refer to the any person who access the website or who is/are registered at the website for various services provided by it (User would include individual/legal entity/society/group of people etc. or as the case may be).
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term ‘OUR WEBSITE‘or ‘WEBSITE’ is used through this entire EULA to refer to the Website www. CAREERCONNECTSPASSION.com.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term ‘OUR PRODUCTS /SERVICES’ is used through this entire EULA to refer to the products/ services offered at the Website or its associated entities, not including the advertisements that may be displayed on the Website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The term ‘APPROPRIATE GOVERNMENT’ is used through this entire EULA as referring to Central/State Government or Local authorities as the case may be.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <Typography variant="body1">
                        The terms and conditions herein shall apply equally to both the singular and plural form of the terms. Whenever the context may require, any pronoun shall include both the corresponding masculine and feminine form. The terms as defined herein shall also read as such in the Privacy Policy as well as the Disclaimer of the Website.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                3. ELIGIBILITY
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Use of the Website is available to all the persons/entities/companies/partnership firms etc., as the case may be. However, any registration on the website shall be by the person who is capable of entering into valid contract as per the Indian Contract Act, 1872.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Website, except under the legal/constructive guardianship of such persons capable of entering into contract on behalf of such minor user/s.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            While registering with the Website, you represent that the information filled by you is correct and that you are duly authorized to accept this agreement and you have the authority to bind yourself / your business entity to this agreement. You further confirm that you shall use/browse the Website in terms of the EULA.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION reserves the right to terminate your registration and refuse to provide you with access to the Website if it is brought to our notice or if it is discovered that you are using the site in violation of the EULA or at its sole discretion without any reason thereof.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                4. REGISTRATION OBLIGATION
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You are responsible for maintaining the confidentiality of your User ID and Password. You are responsible for all the activities that occur under your User ID and Password. You agree, inter-alia, to;
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Provide true, accurate, current and complete information about yourself as prompted by CAREER CONNECTS PASSION registration form (both personal and professional information), maintain and promptly update the Registration Data to keep it true, accurate, current, and complete at all times;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    You must immediately notify us of any unauthorized use of your password or account or any other breach of security, and;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    ensure that you exit from your account at the end of each session.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            If you provide any information that is untrue, inaccurate, not current, or incomplete or CAREER CONNECTS PASSION has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Agreement, CAREER CONNECTS PASSION has the right to indefinitely suspend or terminate or block access of your registration with the website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION shall not be liable for any loss or damage arising from your failure to comply with this Clause.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                5. ELECTRONIC REGISTRATION AND COMMUNICATION
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        When you use the Website or send emails or other data, information or communication to CAREER CONNECTS PASSION, you agree and understand that you are communicating with CAREER CONNECTS PASSION through electronic records. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Website or through any other CAREER CONNECTS PASSION services.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                6. DESCRIPTION OF SERVICES
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION is a technology enabled eco-system for career planning & development for students. We leverage technology, research, machine learning and algorithms to minimize the human bias in career decision making process. CAREER CONNECTS PASSION offers a unique way of career guidance, counseling & mentoring and is not limited to psychometric assessments, career counselling and guidance, interactive career focused activities and career progression or tracking mechanisms etc., as may be provided time to time for help and guidance of user/s.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            By entering into the present agreement, you accept that you are using the services rendered at CAREER CONNECTS PASSION at your sole discretion without any undue influence of the CAREER CONNECTS PASSION and at your sole responsibility (under supervision of guardian in case of minors).
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You further agree to ensure that you will ensure that you avail the services with due care and due diligence and as per the terms & conditions of the EULA and the respective service you are availing.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                7. DESCRIPTION OF SERVICES
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION shall charge the fee for the services provided as per the structure provided on the website. By using the services which are subject to payment, as mentioned therein, you accept to pay for the same before using the services.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You further undertake not to raise any dispute qua the fee charged for the services in any manner whatsoever as you have agreed to the same out of free will and without any undue influence.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You further undertake that you have read the Terms & Conditions of the Payment that are prompted when you move towards payment of the paid services and have accepted the same before making the payment.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION uses third party payments providers to receive payments from user. CAREER CONNECTS PASSION is not responsible for delays or erroneous transactions execution or cancellation of orders due to payment issues. CAREER CONNECTS PASSION takes utmost care to work with 3rd party payment providers, but does not control their systems, processes, technology and work flows, hence cannot be held responsible for any fault at the end of payment providers.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The fee to be charged by CAREER CONNECTS PASSION from users shall be decided solely by CAREER CONNECTS PASSION and the user/s shall have no right/say to interfere in the same.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                8. TAXES
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        You agree to bear all applicable taxes, charges cess etc. levied on the services being rendered to you as notified by the Central/State Govt. time to time, unless already included in the price offered.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                9. REFUND POLICY
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        The CAREER CONNECTS PASSION renders services including but not limited to counseling, assessment tests etc, which act as a bonafide guide to the user and the services are rendered as soon as availed, therefore there is no provision for refund of fee paid by the User/s. The user/s is therefore deemed to have understood and accepted that once the fee is paid to the CAREER CONNECTS PASSION as per the services availed, there shall be no refund of the said Fee.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                10. TERM AND TERMINATION OF THE CONTRACT
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            There shall be no Contract between the CAREER CONNECTS PASSION and the user/s until the services are availed by user/s against consideration. The Access to the free portion of the website is only for welfare of the user and does not give rise to any Contract between the user/s and the CAREER CONNECTS PASSION in any manner.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The Contract against consideration for the services shall be strictly in terms of the services availed and shall end as soon as the services are rendered.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION is entitled to terminate the contract at all times and with immediate effect (by disabling your use of the Website and the Service) if you;
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Violate or breach any term of this EULA, or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    In the opinion of CAREER CONNECTS PASSION, misuse the Website or Service, or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    At the sole discretion of the CAREER CONNECTS PASSION for which CAREER CONNECTS PASSION is under no obligation to assign any reason.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION is not obliged to give prior notice of the termination of contract.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                11. INTELLECTUAL PROPERTY OWNERSHIP
            </Typography>
            <List>
                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION alone (and its licensors, where applicable) shall own all right, title and interest, including all intellectual all property rights, in and to the Website and Service and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by you or any other party relating to the Website or the Service
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You may not use, copy, adapt, modify, create derivative works from, distribute, license, sell, transfer, publicly display, publicly perform, reproduce, transmit, stream, broadcast or otherwise exploit the Website, Service or Collective Content, except as expressly permitted in this CAREER CONNECTS PASSION. You may not use any Collective Content without first obtaining the written consent of CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by CAREER CONNECTS PASSION or its licensors, accept for the licenses and rights expressly granted in this Agreement.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            This EULA does not constitute a sale and does not convey to you any rights of ownership in or related to the Website, or any intellectual property rights owned by CAREER CONNECTS PASSION CAREER CONNECTS PASSION’s name, logo, and the product names associated with the Website and the Service are trademarks of Owner, its affiliated companies or third parties, and no right or license is granted to use them
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                12. WEBSITE LICENSE
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Subject to your compliance with these EULA, CAREER CONNECTS PASSION grants you a limited non-exclusive, non-transferable license for the use of the Website.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You shall not indulged in any of the following activities:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    License, sub license, sell, resell, transfer , assign, distribute or otherwise commercially exploit or make available to any third party the Service or Website in any way;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Modify or make derivative works based upon the Service or Website;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Create internet “links” to the Service or “frame” or “mirror” any Website or any other server or wireless or Internet – based device;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Reverse engineer or access the Website in order to:
                                </Typography>
                            </div>

                            <ListItem>
                                <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                    <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                    <Typography variant="body1">
                                        Design or build a competitive product or service,
                                    </Typography>
                                </div>
                            </ListItem>

                            <ListItem>
                                <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                    <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                    <Typography variant="body1">
                                        Reverse engineer or access the Website in order to:
                                    </Typography>
                                </div>
                            </ListItem>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Design or build a product using similar ideas, features, functions, or graphics of the Service or Website’ or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Copy any ideas, features, functions or graphics of the Service or Website
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, we indexers, bots, viruses or worms or any program which may make multiple server requests per second, or unduly burdens or hinders the operation and/ or performance of the Website.
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Send spam or otherwise duplicative or unsolicited messages in violation of applicable laws;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortuous material, including material harmful to children or violative of third-party privacy rights;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Interfere with or disrupt the integrity or the performance of the Website, the Website or Service or the data contained therein;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Attempt to gain unauthorized access to the website.
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will not authorize others to use your account;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will not assign or otherwise transfer your account to any other person or legal entity;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will not use an account that is subject to any rights of a person other than you without appropriate authorization;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will not use the Service or website for unlawful purposes, including but not limited to sending or storing any unlawful material or for fraudulent purposes;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will provide us with whatever proof of identity we may reasonably request;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    will comply with all the applicable law from your home nation, the country, state and/ or city in which you are present while using the Website or the Service.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION will have the right to investigate and prosecute violations of any of the above to the fullest extent of the law. CAREER CONNECTS PASSION may involve and cooperate with law enforcement authorities in prosecuting users who violate these User Terms.
                        </Typography>
                    </div>
                </ListItem>



                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You acknowledge that CAREER CONNECTS PASSION has no obligation to monitor your access to or use of the Website, Service or Collective Content or to review or edit any Collective Content, but has the right to do so for the purpose of operating the Website, to ensure your compliance with these User Terms or to comply with the applicable law or the order or requirement of a court, administrative agency or other governmental body.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION reserves the right, at any time and without prior notice, to remove or disable access to any Collective Content that CAREER CONNECTS PASSION, at its sole discretion, considers to be in violation of these User Terms or otherwise harmful to the Website, the Service or Website.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                13. CONDITIONS FOR USE OF WEBSITE
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Please note that in accordance with the Information Technology (Intermediaries guidelines) Rules 2011 and further guidelines as may be issued by the appropriate government time to time, in case of non compliance with rules and regulations, user agreement and privacy policy, for access or usage of intermediaries computer resources, the Intermediary has the right to immediately terminate the access or usage rights of the users to the computer resources of Intermediaries and remove non- complaint information.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You warrant that the information you provide to CAREER CONNECTS PASSION is accurate and complete. CAREER CONNECTS PASSION is entitled at all times to verify the information that you have provided and to refuse the Service or use of the website without providing reasons.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            In term of Information Technology (Intermediaries guidelines) Rules 2011, you agree and undertake that you shall not host, display, upload, modify, publish, transmit, update or share any information or share /list (s) any information or item that :
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Belongs to the another person and to which You do not have any right ;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another’s privacy, hateful or racially, ethically, objectionable, disparaging, relating or encouraging money laundering or gambling or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women ( Prohibition) Act,1986;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Harm minors in any way;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Infringes any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or right of publicity or privacy or shall not be fraudulent or involved the sale of counterfeit or stolen items;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Involves the transmissions of “junk mails”, “chain letters”, or unsolicited mask mailing or “spamming”;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Violates any law for the time being in force;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Deceives or misleads addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Impersonate another person;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Contains software viruses or any other computer code, file or programs designed to interrupt, destroy or limit the functionality of any computer resources;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the communication of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Shall not be false, inaccurate or misleading;
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>


                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION further reserves the right at its sole discretion to block or remove (in whole or in part) any content posted or transmitted by you and which CAREER CONNECTS PASSION believes is not in accordance with this EULA (including materials which infringe or may infringe third party intellectual property rights, rights of privacy or personality rights), or is otherwise unacceptable to CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You agree to promptly notify CAREER CONNECTS PASSION in writing of any user content which breaches these User Terms. You agree to provide to CAREER CONNECTS PASSION sufficient information to enable CAREER CONNECTS PASSION to investigate whether such User Content breaches the terms of EULA. CAREER CONNECTS PASSION agrees to make good faith efforts to investigate such complaint and shall take such notice as CAREER CONNECTS PASSION in its sole discretion decides. However, CAREER CONNECTS PASSION does not warrant or represent that it will block or remove (in whole or in part) such user Content.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                14. CONSEQUENCES OF BREACH OF TERMS
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Without limiting other remedies, CAREER CONNECTS PASSION may limit your activity, immediately remove your information or end your registration, warn other Users of your actions, temporarily/ indefinitely suspend or terminate or block your membership, and/ or refuse to provide you with access to the Website in the event, but not limited to following activities:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    If you breach the EULA or Privacy Policy or the documents, agreements rules, policies, terms and conditions as incorporated herein by reference;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    If CAREER CONNECTS PASSION is unable to verify or authenticate any information provided by you; or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    If it is believed that your actions may cause legal liability for other Users or CAREER CONNECTS PASSION.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            User/s who has been suspended or blocked may not register or attempt to register with CAREER CONNECTS PASSION or use the Website in any manner whatsoever until such time that such user/s is permitted to do so by CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Notwithstanding the forgoing, if you breach the EULA or any other documents it incorporates by reference, CAREER CONNECTS PASSION reserves the right to forfeit the money paid by you to CAREER CONNECTS PASSION  and to take strict legal action including but not limited to a referral to the appropriate police or the other authorities for initiating criminal or other proceedings against you.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION reserves its right to initiate civil and/ or criminal proceedings against a user/s who, furnishes invalid/false claim or misleading information to CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Any person who, knowingly and with an intent to injure, defraud or deceive CAREER CONNECTS PASSION, files Fraudulent information containing false, incomplete, or misleading information shall be guilty of a criminal offence and will be prosecuted to the fullest extent to the law.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                15. DEFICIENCY IN SERVICE
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION reserves the right to temporarily/ indefinitely suspend or terminate your registration and refuse to provide you with access to all current and future use of the Website at its sole discretion which shall not be termed as deficiency in service in any manner as per law and the user/s hereby expressly waives its right to call in question in any manner, any such suspension/termination of services by the CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Further, CAREER CONNECTS PASSION reserves its right to suspend the operation of the website for updation/repair/technical fault or any other reason as the case may be for which it shall not be liable in any manner including for deficiency of service under the applicable laws.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                16. INDEMNITY
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You confirm that you shall be responsible for any damage that may be caused to CAREER CONNECTS PASSION for wrongful use of the website or its content by you including but not limited to Intellectual property Violations/Breach of terms & Conditions of EULA or in any manner as the case may be.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            By accepting User Terms and using the Website or Service, you agree that you shall defend, indemnify and hold CAREER CONNECTS PASSION, its affiliates, third- parties and their respective shareholders, officers, directors, agents, and employees, form any losses, liabilities, damages, costs and expenses, claim or demand, or actions including reasonable attorneys’ fees asserted against or incurred by CAREER CONNECTS PASSION, made by any third party arising out of or in connection with:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    our violation or breach of any term of these User Terms or any applicable law or regulation, whether or not referred herein;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Your violation of any rights of any third party, including experts arranged via the Website, or
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Your use or misuse of the Website or Services.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>
            </List>

            <Typography variant="body1">
                17. LIABILITY
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            The information, recommendations and/ or services provided to you on or through the Website, the Service and the Website is for general information purposes only as bonafide guidance and does not constitute any commitment of performance of user in any manner.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION will reasonably keep the site and its contents correct and up to date but does not guarantee that (the contents of) the Website are free of errors, defects, malware and the viruses of that the Website are correct, up to date and accurate.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION endeavors to render the utmost quality of the services rendered, however shall not be liable in any manner for any interruption in service for any reasons whatsoever.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION may permit advertisements of third parties on the website and shall not be responsible for the contents/assurances/services or anything relating to such third party advertisement in any manner whatsoever.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                18. LIMITATION OF LIABILITY
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            In no event shall CAREER CONNECTS PASSION be liable for the amount more than that received by the CAREER CONNECTS PASSION from complainant user/s for any direct, indirect, incidental, special, incidental, consequential or exemplary damages including but not limited to damages for non-admission/performance/loss of goodwill, use, data or other intangible losses arising out of or in connection with the website, its services or this agreement (however arising, including negligence).
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION assumes no liability whatsoever for any monetary or other damage suffered by you on account of:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    The delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Website;
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Any interruption or errors in the operation of the Website.
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>
            </List>

            <Typography variant="body1">
                19. COMMUNICATION POLICY
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You are prohibited to engage in direct or indirect communication with Counselors/consultants of CAREER CONNECTS PASSION except for the purposes of the services availed by you.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION intermediate the communication between user/s and the Counselors which shall be limited for the purposes of service availed and the CAREER CONNECTS PASSION shall not be responsible for any communication between the user and the counselor beyond the scope of the services availed by the user/s in any manner whatsoever.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Violations of policy may result in a range of actions including:
                        </Typography>
                    </div>

                    <List>
                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Cancellation of your registration
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Limits on account privileges
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Account suspension
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Compensation for breach of duty
                                </Typography>
                            </div>
                        </ListItem>

                        <ListItem>
                            <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                                <Typography variant="body1"><CircleOutlinedIcon sx={{fontSize: '10px'}}/></Typography>
                                <Typography variant="body1">
                                    Other proceedings as the law may prescribe
                                </Typography>
                            </div>
                        </ListItem>
                    </List>
                </ListItem>
            </List>

            <Typography variant="body1">
                20. NOTICE
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION may give notice by means of a general notice on the Website, or by electronic mail to your e-mail address on record in CAREER CONNECTS PASSION’s account information, or by written communication sent by regular mail to your address on record in CAREER CONNECTS PASSION’s account information.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            You agree that all the agreements, notices, disclosures and other communications that we provide to you electronically shall be deemed adequate service of notice/ electronic record and satisfy any legal requirement that such communication be in writing in terms of the present EULA.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                21. FEEDBACK
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            CAREER CONNECTS PASSION is always open to the suggestions of the user/s and shall endeavor to doo all such suggestions that may enhance the utility to the user/s for the benefit of user/s as well as CAREER CONNECTS PASSION.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            However, CAREER CONNECTS PASSION is not bound by such suggestions in any manner whatsoever.
                        </Typography>
                    </div>
                </ListItem>
            </List>

            <Typography variant="body1">
                22. DISPUTE RESOLUTION
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        The parties shall attempt that in the event of their being any dispute between them during your use of the Website or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of the User Agreement, or the documents they incorporate by reference or anything relating to the present EULA, the dispute is settled by mutual discussion. In the event of failure of discussion the dispute shall be referred to a sole Arbitrator, who shall be an independent and neutral third party appointed by CAREER CONNECTS PASSION. The place of arbitration shall be New Delhi, India. The Arbitration proceedings shall be in the English Language and shall be governed by Arbitration & Conciliation Act, 1996.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                23. JURISDICTION
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        For any dispute arising out of the present agreement only the Courts at Delhi would Jurisdiction and the you undertake not to raise challenge to the jurisdiction clause in any manner and accept the jurisdiction of the Court at Delhi.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                24. SEVERABILITY
            </Typography>
            <List>
                <ListItem>
                    <Typography variant="body1">
                        If any clause of this Agreement shall be deemed invalid, void or for any reason unenforceable, such clause shall be deemed severable and shall not affect the validity and enforceability of the remaining clauses of the Agreement.
                    </Typography>
                </ListItem>
            </List>

            <Typography variant="body1">
                25. GENERAL
            </Typography>
            <List>
                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Assignment
                            You cannot assign or otherwise transfer this agreement or any rights granted here under to any third party. CAREER CONNECTS PASSION may transfer its rights and obligations( also known as “ assign”) under this Agreement without your prior express consent, provided that CAREER CONNECTS PASSION assigns the Agreement on the same terms or terms that are no less advantageous to you.
                        </Typography>
                    </div>
                </ListItem>

                <ListItem className={classes.SubList}>
                    <div className={clsx("flex flex-row justify-start ml-2 space-x-2")}>
                        <Typography variant="body1"><CircleIcon sx={{fontSize: '10px'}}/></Typography>
                        <Typography variant="body1">
                            Legal Advice
                            You undertake that you had every opportunity to take legal opinion on the present agreement and have understood the legal implications of every clause before agreeing to the same in totality and using the Website.
                        </Typography>
                    </div>
                </ListItem>
            </List>
        </div>
    );
}

export default Terms;
