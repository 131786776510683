import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
//
import withReducer from '../../../store/withReducer';
import reducer from './store';
import { getAggregatedCareers } from "./store/student.slice";
import {showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Loader from "@app/layout/shared/loader.component";

// THIS WILL BE SET ON BACKEND ON FIELD
const logos = {
    arts: 'assets/homepage/student/humanities-logo.png',
    Arts: 'assets/homepage/student/humanities-logo.png',
    commerce: 'assets/homepage/student/commerce-logo.png',
    Commerce: 'assets/homepage/student/commerce-logo.png',
    independent: 'assets/homepage/student/independent-logo.png',
    Independent: 'assets/homepage/student/independent-logo.png',
    science: 'assets/homepage/student/science-logo.png',
    Science: 'assets/homepage/student/science-logo.png'
};

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh'
    },
    container: {
        minheight: '84vh'
    },
    studentBg: {
        position: 'absolute',
        width: '100%',
        height: '400px',
        marginTop: '80px',
        zIndex: '-1000',
        backgroundColor: theme.palette.primary.main
    },
    fieldsContainer: {
        [theme.breakpoints.down('sm')]: {
            rowGap: '20px'
        },
    },
    fieldCard: {
        width: '23%',
        height: '75vh',
        [theme.breakpoints.up('xl')]: {
            height: '72vh'
        },
        [theme.breakpoints.between('lg','xl')]: {
            height: '78vh'
        },
        [theme.breakpoints.between('md','lg')]: {
            height: '75vh'
        },
        [theme.breakpoints.between('sm','md')]: {
            width: '40%',
            height: '75vh'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '90vh',
            rowGap: '20px'
        },
    },
    fieldLogo: {
        height: '35%'
    },
    fieldCardActions: {
        width: '70%'
    },
    cantFindCareer: {

    },
    cantFindCareerButton: {
        width: "30%",
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '20px'
        },
    },
    careersContainer: {
        height: '200px',
        overflowY: 'scroll',

        '&::-webkit-scrollbar': {
            width: '5px'
            // height: '10px',
        },

        '&::-webkit-scrollbar-track': {
            // width: '5px',
        },

        '&::-webkit-scrollbar-thumb': {
            // width: '5px',
            background: '#323232',
            borderRadius: '0px'
        },

        '&::-webkit-scrollbar-thumb:hover': {
            background: '#323232'
        }
    },
    cantFindCareerForm: {

    }
}));
function Student() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // STATES
    const [loading, setLoading] = useState(false);
    const [aggregateCareers, updateAggregateCareers] = useState([])
    const [showNotFound, setShowNotFound] = useState(false);

    // REF


    // EFFECTS
    useEffect(() =>  {
        if(!aggregateCareers.length) {
            setLoading(true);
            dispatch(getAggregatedCareers()).unwrap().then(response => {
                if(response.status === 200) {
                    updateAggregateCareersWithLogo(response.data);
                } else {

                }
                setLoading(false);
            }).catch(error => {
                console.log(error);
                // 404
            })
        }
    }, []);

    // METHODS
    const updateAggregateCareersWithLogo = (data) => {
          let careers_data = data.map((el, index) => {
              console.log(el);
                return {
                    ...el,
                    logo: logos[el._id]
                }
          });
          updateAggregateCareers(careers_data);
    };

    // NAVIGATION
    const handleClickField = (id) => {
        navigate(`/careers?field=${id}`);
    }

    const handleClickCareer = (id) => {
        navigate(`/careers/${id.$oid}`);
    }

    const gotoCareerRequest = () => {
        navigate(`/career-request`);
    }

    // RENDER
    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col justify-start items-center", classes.container)}>
                <div className={clsx(classes.studentBg)} />

                <div className={clsx("w-11/12 mt-6 flex flex-col sm:flex-row flex-nowrap justify-between items-start", classes.fieldsContainer)}>
                    {
                        aggregateCareers.map((field, index) => (
                            <Paper key={index} elevation={8} className={clsx("pt-12 flex flex-col justify-start items-center", classes.fieldCard)}>
                                <img className={clsx(classes.fieldLogo)} src={field.logo} alt="science-card-logo"/>

                                <div className={clsx("mt-8 flex flex-col justify-start items-stretch", classes.fieldCardActions)}>
                                    <Button variant="contained" color="primary" onClick={() => handleClickField(field._id)}>{field._id}</Button>

                                    <div className={clsx("mt-8 flex flex-col justify-start items-stretch space-y-4", classes.careersContainer)}>
                                        {
                                            field.careers.map((career, i) => (
                                                <Button key={i} variant="grey-button" onClick={() => handleClickCareer(career._id)}>{career.name}</Button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Paper>
                        ))
                    }

                </div>

                <div className={clsx("w-11/12 flex flex-col justify-start items-stretch", classes.cantFindCareer)}>
                    <div className={clsx("mt-10 flex flex-col justify-center items-center")}>
                        <div className={clsx("flex flex-col justify-start items-stretch", classes.cantFindCareerButton)}>
                            <Button variant="contained" color="accent" onClick={gotoCareerRequest}>Can't find your future career ?</Button>
                        </div>

                        {
                            showNotFound &&
                                <div className={clsx("flex flex-col mt-8", classes.cantFindCareerForm)}>
                                    <Typography variant="h6">Let us know what you are looking for</Typography>

                                    <div className={clsx("mt-6 mb-6 flex flex-col justify-start space-y-4")}>
                                        <TextField id="outlined-basic" label="Name" variant="outlined" />
                                        <TextField id="outlined-basic" label="Email" variant="outlined" />
                                        <TextField id="outlined-basic" label="Mobile" variant="outlined" />

                                        <TextField id="outlined-basic" label="Career Name" variant="outlined" />
                                        <TextField
                                            id="outlined-multiline-flexible"
                                            label="Multiline"
                                            multiline
                                            rows={4}
                                            // value={value}
                                            // onChange={handleChange}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withReducer('Platform', reducer)(Student);
