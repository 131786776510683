import React, {useState, useEffect, useRef} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
//
import './style/home.css';
import withReducer from '../../../store/withReducer';
import reducer from './store';
import { getHomepage } from "./store/home.slice";
import {showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loader from "@app/layout/shared/loader.component";

const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden',
        backgroundImage: `url("assets/homepage/bg-2.6.png")`,
        backgroundSize: 'contain',
        backgroundPosition: 'right 76vh',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down('md')]: {
            backgroundPosition: 'right 136vh',
        },
        [theme.breakpoints.down('sm')]: {
            background: 'none'
        },
    },
    f1: {
        [theme.breakpoints.down('sm')]: {
            height: 'inherit'
        },
    },
    f1_row1: {

    },
    f1_mobile_img: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            position: 'relative',
            bottom: '40px',
            zIndex: '-10'
        }
    },
    f2: {
        height: '120vh',
        [theme.breakpoints.between('md','lg')]: {
            height: 'inherit'
        },
        [theme.breakpoints.down('md')]: {
            height: 'inherit'
        },
        [theme.breakpoints.down('sm')]: {
            backgroundImage: `url("assets/homepage/bg-mobile-2.1.png")`,
            backgroundRepeat: 'no-repeat',
        },
    },
    f3: {
        height: '120vh',
        [theme.breakpoints.between('md','lg')]: {
            height: '84vh'
        },
        [theme.breakpoints.down('md')]: {
            height: '70vh'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'inherit !important'
        },
    },
    f3_mobile_img: {
        width: '70%',
        marginTop: '20px',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    f4: {
        height: '100vh',
        [theme.breakpoints.between('md','lg')]: {
            height: '95vh'
        },
        [theme.breakpoints.down('md')]: {
            height: '70vh'
        }
    },
    title_body: {
        marginLeft: '30px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
            height: '70vh'
        }
    },
    title_body_container: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '0px 20px'
        },
    },
    exploreCareer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    exploreCareerMobile: {
        marginTop: '50px',
        padding: '0px 40px',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
    },
    illustration_1: {
        width: '75%',
        marginRight: "120px",
        [theme.breakpoints.between('md', 'lg')]: {
            width: '90%',
            marginRight: "30px",
        },
        [theme.breakpoints.down('md')]: {
            width: '68%',
            marginRight: "0px",
        },
    },
    f2_header: {
        marginTop: "90px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "0px"
        },
    },
    f2_content: {
        marginTop: "30px",
        [theme.breakpoints.down('sm')]: {
            rowGap: '20px'
        },
    },
    f2_content_img: {
        width: '85%',
        // marginRight: "50px"
    },
    f2_video: {
        width: '90%'
    },
    f2_video_container: {
        position: 'relative',
        bottom: '22%',
        padding: '0 18%'
    },
    f2_video_card: {
        cursor: 'pointer',
        marginBottom: '10px',
        height: '150px',
        width: '150px',
        backgroundColor: `${theme.palette.accent.main} !important`
    },
    // Card-1
    f3_card1_container: {
        position: 'relative',
        bottom: '90px',
        left: '40px'
    },
    f3_card1: {
        width: '210px',
        height: '210px',
        padding: '24px 10px',
        backgroundColor: '#6666ad !important',
        borderRadius: '30px !important'
    },
    f3_card1_icon: {
        height: '45px'
    },
    f3_card1_button: {
        position: 'relative',
        top: '96px',
        right: '30px'
    },
    // Card 2
    f3_card2_container: {
        position: 'relative',
        top: '175px',
        left: '30px'
    },
    f3_card2: {
        width: '180px',
        height: '180px',
        padding: '24px 10px',
        backgroundColor: '#f27064 !important',
        borderRadius: '30px !important'
    },
    f3_card2_icon: {
        height: '45px'
    },
    f3_card2_button: {
        position: 'relative',
        right: '20px',
        top: '150px'
    },
    // Card 3
    f3_card3_container: {
        position: 'relative',
        bottom: '62px',
        left: '50px'
    },
    f3_card3: {
        width: '180px',
        height: '180px',
        padding: '24px 10px',
        backgroundColor: '#82c341 !important',
        borderRadius: '30px !important'
    },
    f3_card3_icon: {
        height: '45px'
    },
    f3_card3_button: {
        position: 'relative',
        right: '20px',
        top: '150px'
    },
    // Card 4
    f3_card4_container: {
        position: 'relative',
        top: '95px',
        left: '120px'
    },
    f3_card4: {
        width: '200px',
        height: '190px',
        padding: '24px 10px',
        backgroundColor: '#edb55d !important',
        borderRadius: '30px !important'
    },
    f3_card4_icon: {
        height: '45px'
    },
    f3_card4_button: {
        position: 'relative',
        right: '30px',
        top: '125px'
    },
    // Card 5
    f3_card5_container: {
        position: 'relative',
        left: '175px',
        bottom: '72px'
    },
    f3_card5: {
        width: '200px',
        height: '200px',
        padding: '24px 10px',
        backgroundColor: '#6666ad !important',
        borderRadius: '30px !important'
    },
    f3_card5_icon: {
        height: '45px'
    },
    f3_card5_button: {
        position: 'relative',
        right: '20px',
        top: '150px'
    },
    card_button: {
      backgroundColor: '#ffc860d4'
    },
    // F4
    f4_bg_img: {
        visibility: "hidden"
    },
    f4_left_container: {
        width: '5%',
        zIndex: '1000'
    },
    f4_main_container: {
        width: '90%'
    },
    f4_right_container: {
        width: '5%',
        zIndex: '1000'
    },
    text_dark: {
        color: '#585858'
    },
    text_white: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            color: '#585858'
        },
    },
    f2Video: {
        height: '70vh'
    },
    fontBolder: {
        fontWeight: 'bolder !important'
    },
    // Mobile Device
    '@media (max-width: 599px)': {
        f3: {
            height: '120vh',
            backgroundImage: 'none'
        },
    }
}));

function Home() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // STATES
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState(null);
    const [careers, setCareers] = useState(null);
    const [sliderStart, setSliderStart] = useState(0);
    const [sliderEnd, setSliderEnd] = useState(4);

    // REFS


    // EFFECTS
    useEffect(() => {
        setLoading(true);
        dispatch(getHomepage()).unwrap().then(response => {
            const data = response.data;
            setFields(data?.fields);
            setCareers(data?.careers);
            setupSliderCards(data?.careers);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            dispatch(showSnackbar({
                message: 'Something went wrong',
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'right'//left center right
                },
                variant: 'error'
            }));
            setLoading(false);
        });
    }, []);

    useEffect(() => {

    }, [careers]);

    // METHODS
    const setupCareersSlider = (data) => {
        setCareers(data);

        const prev1Img = document.createElement('img');
        prev1Img.setAttribute('src', data[0].logo);
        document.getElementsByClassName("prev-1")[0].appendChild(prev1Img);

        const prev2Img = document.createElement('img');
        prev2Img.setAttribute('src', data[1].logo);
        document.getElementsByClassName("prev-2")[0].appendChild(prev2Img);

        const actImg = document.createElement('img');
        actImg.setAttribute('src', data[2].logo);
        document.getElementsByClassName("act")[0].appendChild(actImg);

        const next2Img = document.createElement('img');
        next2Img.setAttribute('src', data[3].logo);
        document.getElementsByClassName("next-2")[0].appendChild(next2Img);

        const next1Img = document.createElement('img');
        next1Img.setAttribute('src', data[4].logo);
        document.getElementsByClassName("next-1")[0].appendChild(next1Img);
    };

    const setupSliderCards = (data) => {
        setTimeout(() => {
            const positions = ['prev-1', 'prev-2', 'act', 'next-2', 'next-1'];

            for(let i=0; i<positions.length; i++) {
                document.getElementsByClassName(positions[i])[0].appendChild(SliderCard({logo: data[i]?.logo}));
            }
        }, 400)
    }

    const moveForward = () => {
        if (document.getElementsByClassName("hide")[0]) {
            document.getElementsByClassName("hide")[0].remove();
        }

        /* Step */
        if (document.getElementsByClassName("prev-1")[0]) {
            document.getElementsByClassName("prev-1")[0].classList.add("hide");
            document.getElementsByClassName("prev-1")[0].classList.remove("prev-1");
        }

        document.getElementsByClassName("prev-2")[0].classList.add("prev-1");
        document.getElementsByClassName("prev-2")[0].classList.remove("prev-2");

        document.getElementsByClassName("act")[0].classList.add("prev-2");
        document.getElementsByClassName("act")[0].classList.remove("act");

        document.getElementsByClassName("next-2")[0].classList.add("act");
        document.getElementsByClassName("next-2")[0].classList.remove("next-2");

        document.getElementsByClassName("next-1")[0].classList.add("next-2");
        document.getElementsByClassName("next-1")[0].classList.remove("next-1");

        /* New Next */
        document.getElementsByClassName("new-next")[0].classList.remove("new-next");

        if(document.getElementsByClassName("next-1")[0].children.length === 0) {
            let pointer = null;
            if(sliderEnd === careers.length - 1) {
                pointer = careers[0].logo;
            } else {
                pointer = careers[sliderEnd+1].logo;
            }
            document.getElementsByClassName("next-1")[0].appendChild(SliderCard({logo: pointer}));
        }

        setSliderEnd(state => state === careers.length-1 ? 0 : state+1);
        setSliderStart(state => state === careers.length-1 ? 0 : state+1);

        const addedEl = document.createElement('li');

        document.getElementsByClassName("list")[0].appendChild(addedEl);
        addedEl.classList.add("next-1","new-next");
    };

    const moveBackward = () => {
        document.getElementsByClassName("new-next")[0].remove();

        /* Step */

        document.getElementsByClassName("next-1")[0].classList.add("new-next");

        document.getElementsByClassName("next-2")[0].classList.add("next-1");
        document.getElementsByClassName("next-2")[0].classList.remove("next-2");

        document.getElementsByClassName("act")[0].classList.add("next-2");
        document.getElementsByClassName("act")[0].classList.remove("act");

        document.getElementsByClassName("prev-2")[0].classList.add("act");
        document.getElementsByClassName("prev-2")[0].classList.remove("prev-2");

        document.getElementsByClassName("prev-1")[0].classList.add("prev-2");
        document.getElementsByClassName("prev-1")[0].classList.remove("prev-1");

        /* New Prev */
        document.getElementsByClassName("hide")[0].classList.add("prev-1");
        document.getElementsByClassName("hide")[0].classList.remove("hide");

        if(document.getElementsByClassName("prev-1")[0].children.length === 0) {
            let pointer = null;
            if(sliderStart === 0) {
                pointer = careers[careers.length-1].logo;
            } else {
                pointer = careers[sliderStart-1].logo
            }
            document.getElementsByClassName("prev-1")[0].appendChild(SliderCard({logo: pointer}));
        }

        setSliderStart(start => start === 0 ? careers.length-1 : start-1);
        setSliderEnd(end => end === 0 ? careers.length-1 : end-1);

        const addedEl = document.createElement('li');
        document.getElementsByClassName("list")[0].insertBefore(addedEl, document.getElementsByClassName("list")[0].firstChild);
        addedEl.classList.add("hide");
    };

    // NAVIGATION
    const gotoField = (id) => {
        navigate(`/careers?field=${id}`);
    }

    const gotoCareer = (id) => {
        navigate(`/careers/${id}`);
    }

    const handleExplore = () => {
        navigate(`/careers`);
    }

    // RENDER METHODS
    const SliderCard = (props) => {
        const card = document.createElement('div');
        card.className = 'slider-card';

        const img = document.createElement('img');
        img.className = 'slider-card-item';
        img.setAttribute('src', props.logo);

        card.appendChild(img);

        return card;
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-center items-stretch home-root", classes.root)}>
            <div className={clsx("flex flex-col justify-center items-stretch", classes.content)}>
                <div className={clsx("md:h-screen flex flex-col justify-center items-stretch", classes.f1)}>
                    <div className={clsx("h-5/6 w-full flex flex-col-reverse md:flex-row", classes.f1_row1)}>
                        <div className={clsx("w-full md:w-1/2 flex flex-col justify-center items-center", classes.title_body)}>
                            <div className={clsx("flex flex-col", classes.title_body_container)}>
                                <Typography className={clsx("font-bolder", classes.fontBolder)} variant="h2">We mix your passions with careers</Typography>
                                {/*<Typography className={clsx("font-bolder", classes.fontBolder)} variant="h2">Let's journey together</Typography>*/}

                                <div className={clsx("my-4")}>
                                    <Typography variant="h6">Step into your future career now !!!</Typography>
                                </div>

                                <div className={clsx("flex flex-row mt-20 space-x-4", classes.exploreCareer)}>
                                    {
                                        fields && fields.map((field, index) =>
                                            <Button key={index} variant="outline-raised" onClick={() => gotoField(field._id)}>{field.name}</Button>
                                        )
                                    }
                                </div>

                                <div className={clsx("flex flex-col", classes.exploreCareerMobile)}>
                                    <Button variant="contained" color="primary" onClick={handleExplore}>Explore Career</Button>
                                </div>
                            </div>
                            <img className={clsx(classes.f1_mobile_img)} src="assets/homepage/bg-mobile-1.png" />
                        </div>
                        <div className={clsx("w-full md:w-1/2  flex flex-row lg:justify-end lg:items-center md:justify-start md:items-start justify-center")}>
                            <img className={clsx(classes.illustration_1)} src="assets/homepage/illustration-1.svg" alt="home-page-illustration"/>
                        </div>
                    </div>
                </div>

                <div className={clsx("flex flex-col justify-start items-stretch", classes.f2)}>
                    <div className={clsx("flex flex-col-reverse md:flex-row", classes.f2_header)}>
                        <div className={clsx("w-full md:w-1/2 flex-1 flex-col justify-center items-center")}>
                            <Typography className={clsx("text-center", classes.text_dark)} variant="h4">India's first interactive video based career counselling platform</Typography>
                        </div>

                        <div className={clsx("w-full md:w-1/2 flex-1 flex-col justify-center items-center")}>
                            <Typography className={clsx("text-center", classes.text_white)} variant="h2">What is CCP?</Typography>
                        </div>
                    </div>

                    <div className={clsx("flex flex-col-reverse md:flex-row", classes.f2_content)}>
                        <div className={clsx("w-full md:w-1/2 flex flex-col justify-center items-center")}>
                            <video className={classes.f2_video} controls preload="metadata">
                                <source src="https://ccp-prod-master.s3.ap-south-1.amazonaws.com/public/CCP+Web+Video+-720p-211112.mp4" type="video/mp4" />
                            </video>
                        </div>

                        <div className={clsx("w-full md:w-1/2 flex flex-col justify-center items-center")}>
                            <img className={clsx(classes.f2_content_img)} src="assets/homepage/bg-2.8.png" />
                        </div>
                    </div>

                    {/*<div className={clsx("flex flex-row justify-end items-center", classes.f2_content)}>*/}
                    {/*    <img className={clsx(classes.f2_content_img)} src="assets/homepage/bg-2.8.png" />*/}
                    {/*</div>*/}

                    {/*<div className={clsx("flex flex-col justify-start items-start", classes.f2_video_container)}>*/}
                    {/*    <div className={clsx("flex flex-col justify-center items-center")}>*/}
                    {/*        <Paper elevation={8} className={clsx("flex flex-col justify-center items-center", classes.f2_video_card)} onClick={handleClickOpen}>*/}
                    {/*            <PlayArrowIcon style={{ color: 'white', fontSize: 80 }} />*/}
                    {/*        </Paper>*/}
                    {/*        <Typography variant="h5" className={clsx("text-center", classes.text_dark)}>Play Video</Typography>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className={clsx("flex flex-col justify-center items-center md:items-stretch", classes.f3)}>
                    {/*<div className={clsx("hidden sm:flex sm:flex-row sm:justify-center sm:items-center")}>*/}
                    {/*    <div className={clsx("flex flex-col justify-start items-stretch", classes.f3_card1_container)}>*/}
                    {/*        <div className={clsx("rounded-full h-16 w-16 flex items-center justify-center", classes.card_button, classes.f3_card1_button)}>*/}
                    {/*            <PlayArrowIcon sx={{ fontSize: '70px', color: 'white' }} />*/}
                    {/*        </div>*/}
                    {/*        <Paper elevation={8}  className={clsx("flex flex-col justify-between items-center", classes.f3_card1)}>*/}
                    {/*            <img className={classes.f3_card1_icon} src="assets/homepage/f3-card-1-icon.png" alt="f3-card1-icon"/>*/}

                    {/*            <div className="w-3/5">*/}
                    {/*                <Typography variant="body1" className="text-white text-center">Career Activities and Responsibilities Videos</Typography>*/}
                    {/*            </div>*/}
                    {/*        </Paper>*/}
                    {/*    </div>*/}

                    {/*    <div className={clsx("flex flex-col justify-start items-stretch", classes.f3_card2_container)}>*/}
                    {/*        <div className={clsx("rounded-full h-12 w-12 flex items-center justify-center", classes.card_button, classes.f3_card2_button)}>*/}
                    {/*            <PlayArrowIcon sx={{ fontSize: '50px', color: 'white' }} />*/}
                    {/*        </div>*/}
                    {/*        <Paper elevation={8}  className={clsx("flex flex-col justify-between items-center", classes.f3_card2)}>*/}
                    {/*            <div className="w-4/5">*/}
                    {/*                <Typography variant="body1" className="text-white text-center">A day in the life of your favourite career video</Typography>*/}
                    {/*            </div>*/}

                    {/*            <img className={classes.f3_card2_icon} src="assets/homepage/f3-card-2-icon.png" alt="f3-card2-icon"/>*/}
                    {/*        </Paper>*/}
                    {/*    </div>*/}

                    {/*    <div className={clsx("flex flex-col justify-start items-stretch", classes.f3_card3_container)}>*/}
                    {/*        <div className={clsx("rounded-full h-16 w-16 flex items-center justify-center", classes.card_button, classes.f3_card3_button)}>*/}
                    {/*            <PlayArrowIcon sx={{ fontSize: '70px', color: 'white' }} />*/}
                    {/*        </div>*/}
                    {/*        <Paper elevation={8} className={clsx("flex flex-col justify-between items-center", classes.f3_card3)}>*/}
                    {/*            <div className="w-4/5">*/}
                    {/*                <Typography variant="body1" className="text-white text-center">Knowledge required Videos</Typography>*/}
                    {/*            </div>*/}

                    {/*            <img className={classes.f3_card3_icon} src="assets/homepage/f3-card-3-icon.png" alt="f3-card1-icon"/>*/}
                    {/*        </Paper>*/}
                    {/*    </div>*/}

                    {/*    <div className={clsx("flex flex-col justify-start items-stretch", classes.f3_card4_container)}>*/}
                    {/*        <div className={clsx("rounded-full h-16 w-16 flex items-center justify-center", classes.card_button, classes.f3_card4_button)}>*/}
                    {/*            <PlayArrowIcon sx={{ fontSize: '70px', color: 'white' }} />*/}
                    {/*        </div>*/}
                    {/*        <Paper elevation={8}  className={clsx("flex flex-col justify-between items-center", classes.f3_card4)}>*/}
                    {/*            <img className={classes.f3_card4_icon} src="assets/homepage/f3-card-4-icon.png" alt="f3-card4-icon"/>*/}

                    {/*            <div className="w-2/5">*/}
                    {/*                <Typography variant="body1" className="text-white text-center">Career Personality Videos</Typography>*/}
                    {/*            </div>*/}
                    {/*        </Paper>*/}
                    {/*    </div>*/}

                    {/*    <div className={clsx("flex flex-col justify-start items-stretch", classes.f3_card5_container)}>*/}
                    {/*        <div className={clsx("rounded-full h-16 w-16 flex items-center justify-center", classes.card_button, classes.f3_card5_button)}>*/}
                    {/*            <PlayArrowIcon sx={{ fontSize: '70px', color: 'white' }} />*/}
                    {/*        </div>*/}
                    {/*        <Paper elevation={8}  className={clsx("flex flex-col justify-between items-center", classes.f3_card5)}>*/}
                    {/*            <div className="w-2/6">*/}
                    {/*                <Typography variant="body1" className="text-white text-center">Career Values Videos</Typography>*/}
                    {/*            </div>*/}

                    {/*            <img className={classes.f3_card5_icon} src="assets/homepage/f3-card-5-icon.png" alt="f3-card5-icon"/>*/}
                    {/*        </Paper>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <img className={clsx(classes.f3_mobile_img)} src="assets/homepage/bg-3.3-mobile.png" />
                </div>

                <div className={clsx("flex flex-row", classes.f4)}>
                    <div className={clsx("flex flex-col justify-center items-center", classes.f4_left_container)}>
                        <IconButton color="primary" aria-label="move backward" component="span" onClick={moveBackward}>
                            <ArrowBackIosIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </div>

                    <div className={clsx("h-full flex flex-col justify-center items-stretch", classes.f4_main_container)}>
                        <ul className="list">
                            <li className="hide">

                            </li>

                            <li className="prev-1">

                            </li>

                            <li className="prev-2">

                            </li>

                            <li className="act">

                            </li>

                            <li className="next-2">

                            </li>

                            <li className="next-1">

                            </li>

                            <li className="next-1 new-next"></li>
                        </ul>
                    </div>

                    <div className={clsx("flex flex-col justify-center items-center", classes.f4_right_container)}>
                        <IconButton color="primary" aria-label="move forward" component="span" onClick={moveForward}>
                            <ArrowForwardIosIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withReducer('Platform', reducer)(Home);
