import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
//
import AuthService from '@app/services/auth/auth.service';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import color from "color";

const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    content: {
        width: '45%',
        height: '90%',
        border: '1px solid #cccccca6'
    },
    logoContainer: {
        margin: '70px 0px'
    },
    logoImage: {
        width: '400px'
    },
    codeConfirmBody: {
        width: '50%',
        marginTop: '50px'
    },
    verifyButton: {
        marginTop: '30px'
    },
    resendButton: {
        marginTop: '40px'
    },
    resendTimer: {
        marginTop: '40px',
        color: theme.palette.accent.main
    },
    resendLoader: {
        marginTop: '40px'
    },
    homeButton: {
        marginTop: '20px'
    }
}));

function UserConfirmComponent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { username } = useParams();

    //
    const [searchParams, setSearchParams] = useSearchParams();
    const [userState, setUserState] = useState(searchParams.get('state'));
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(null); // Number of seconds
    const [displayTimer, updateDisplayTimer] = useState(null); // Timer display content

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    // EFFECTS
    useEffect(() => {
        if(userState && userState === 'old') {
            handleResendCode();
        } else if(userState && userState === 'sent') {
            // setResendTimer(30);
        }
    }, []);

    useEffect(() => {
        if(resendTimer > 0) {
            startResendTimer(resendTimer);
        }
    }, [resendTimer]);

    useEffect(() => {
        // console.log(form);
    }, [form]);

    // METHODS
    const handleCodeVerify = () => {
        if(form && form.code) {
            setLoading(true);
            AuthService.verifyUser(username, form.code).then(response => {
                console.log(response);
                navigate('/auth/login');
                dispatch(showSnackbar({
                    message: "Your account has been created successfully.",
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'success'
                }));
                setLoading(false);
            }).catch(error => {
                dispatch(showSnackbar({
                    message: error?.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));
                setLoading(false);
            });
        }
    }

    const handleResendCode = () => {
        setResendLoading(true);
        AuthService.resendConfirmationCode(username).then(response => {
            dispatch(showSnackbar({
                message: "Confirmation code has been sent your account.",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'success'
            }));
            setSearchParams({state: 'sent'});
            setResendTimer(30);
        }).catch(error => {
            dispatch(showSnackbar({
                message: error?.message,
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'error'
            }));
            setResendLoading(false);
        })
    }

    const startResendTimer = (interval) => {
        setTimeout(() => {
            if(interval >= 0) {
                updateDisplayTimer(`00 : ${interval < 10 ? 0 : ''}${interval}`);
                startResendTimer(interval - 1);
            } else {
                setResendTimer(null);
                updateDisplayTimer(null);
            }
            setResendLoading(false);
        }, 1000);
    }

    //
    const gotoSignup = () => {
        navigate(`/auth/signup`);
    }

    const gotoHome = () => {
        navigate(`/`);
    }


    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col justify-center items-center", classes.container)}>
                <div className={clsx("flex flex-col justify-start items-stretch", classes.content)}>
                    <div className={clsx("flex flex-col justify-start items-center", classes.logoContainer)}>
                        <img className={clsx(classes.logoImage)} src="../../../assets/logo/logo_text.svg" />
                    </div>

                    <div className={clsx("flex flex-col justify-start items-center", classes.confirmCodeContainer)}>
                        <Typography variant="h4">Verification</Typography>
                        <Typography variant="h6">Enter the code, we sent you via email to continue</Typography>

                        <div className={clsx("flex flex-col justify-start items-center", classes.codeConfirmBody)}>
                            <Controller
                                name="code"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Code is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        className={clsx("w-10/12")}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        error={!!errors.code}
                                        required
                                        helperText={errors?.code?.message}
                                        label="Code"
                                        id="code"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <LoadingButton className={clsx("w-10/12", classes.verifyButton)} variant="contained" loading={loading} onClick={handleCodeVerify}>Verify</LoadingButton>

                            {
                                resendLoading ?
                                    <CircularProgress
                                        className={clsx(classes.resendLoader)}
                                        color="accent"
                                        size={30}
                                        thickness={5}
                                        disableShrink
                                        sx={{
                                            animationDuration: '1000ms',
                                            [`& .${circularProgressClasses.circle}`]: {
                                                strokeLinecap: 'round',
                                            },
                                        }}
                                    />
                                    :
                                    resendTimer && resendTimer > 0 ?
                                        <Typography className={clsx("font-bold", classes.resendTimer)} variant="body1">{displayTimer}</Typography>
                                        :
                                        <Button variant="text" color="accent" className={clsx(classes.resendButton)} onClick={handleResendCode}>Resend Code</Button>
                            }
                        </div>

                        <Button className={clsx(classes.homeButton)} variant="text" size="large" color="primary" onClick={gotoHome}>Home</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserConfirmComponent;
