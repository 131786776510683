import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "axios";
import { nodeAxios, platformAxios } from "../../../../../App";

// ASYNC THUNK OPERATIONS
export const getDashboard = createAsyncThunk(
  "studentCareers/getDashboard",
  (params, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState().auth;
    const userId = user.data["custom:user_id"];
    const userType = user.data["custom:user_type"];

    return axios
      .get(`/student/dashboard?userId=${userId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getStudentClassrooms = createAsyncThunk(
  "campusStudents/getStudentClassrooms",
  (params, { dispatch, getState, rejectWithValue }) => {
    return nodeAxios
      .get(`/platform/student/classrooms`)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const startClassroom = createAsyncThunk(
  "campusStudents/startClassroom",
  (params, { dispatch, getState, rejectWithValue }) => {
    return nodeAxios
      .post(`/platform/student/classrooms`, { ...params?.body })
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const getUserSummary = createAsyncThunk(
  "studentCareers/getUserSummary",
  (params, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState().auth;
    const userId = user.data["custom:user_id"];
    const userType = user.data["custom:user_type"];

    return axios
      .get(`student/${userId}/summary`)
      .then((response) => {
        return response;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

// ENTITY ADAPTER
const dashboardAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } =
  dashboardAdapter.getSelectors((state) => state.Career.careers);

const initialState = dashboardAdapter.getInitialState({
  list: [],
  status: 0,
  loading: false,
});

// SLICE
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setLoading: {
      reducer: (state, action) => {
        state.loading = action.payload;
      },
    },
  },
  extraReducers: {
    [getDashboard.fulfilled]: (state, action) => {
      state.list = action.payload.data;
    },
  },
});

// ACTIONS
export const { setLoading } = dashboardSlice.actions;

// REDUCERS
export default dashboardSlice.reducer;
