import React, {useRef} from "react";
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from "react-router-dom";
import clsx from 'clsx';

//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import color from "color";

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '45vh',
        backgroundColor: '#323232',
        color: '#ffffff',
        padding: '35px 50px 10px'
    },
    container: {
        width: '100%'
    },
    company: {
        width: '25%'
    },
    social: {
        width: '25%'
    },
    copyrightContainer: {
        width: '100%'
    },
    hyperlink: {
        width: 'fit-content',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    }
}));

function Footer() {
    const classes = useStyles();
    const navigate = useNavigate();

    // REF
    const children = useRef(['about-us', 'terms', 'privacy-policy', 'cancellation-refund-policy', 'disclaimer']);

    // METHODS
    const handleCompanyRoute = (index) => {
        navigate(`/company/${children.current[index]}`);
    }

    const handleContactRoute = () => {
        navigate(`/contact-us`);
    }

    const openFacebook = () => {
        window.open('https://www.facebook.com/career.connects.passion/')
    }

    const openInstagram = () => {
        window.open('https://www.instagram.com/career.connects.passion/')
    }

    const openLinkedIn = () => {
        window.open('https://www.linkedin.com')
    }

    return (
        <div className={clsx("flex flex-col justify-between items-center", classes.root)}>
            <div className={clsx("flex flex-row justify-between sm:justify-start items-start", classes.container)}>
                <div className={clsx(classes.company)}>
                    <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={() => handleCompanyRoute(0)}>About Us</Typography>
                    <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={() => handleCompanyRoute(1)}>Terms & Condition</Typography>
                    <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={() => handleCompanyRoute(2)}>Privacy Policy</Typography>
                    <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={() => handleCompanyRoute(3)}>Cancellation/Refund Policy</Typography>
                    <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={() => handleCompanyRoute(4)}>Disclaimer</Typography>
                </div>

                <div className={clsx(classes.social)}>
                    {/*<Typography className={clsx("")} variant="h6">Social</Typography>*/}

                    <div>
                        <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={handleContactRoute}>Contact Us</Typography>
                        <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={openFacebook}>Facebook</Typography>
                        <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={openInstagram}>Instagram</Typography>
                        <Typography className={clsx(classes.hyperlink)} variant="subtitle1" onClick={openLinkedIn}>LinkedIn</Typography>
                    </div>
                </div>
            </div>

            <div className={clsx("flex flex-col sm:flex-row justify-between items-center mt-4 sm:mt-0", classes.copyrightContainer)}>
                <div>
                    <Typography variant="subtitle1">Career Connects Passion</Typography>
                </div>

                <div>
                    <Typography variant="caption">©2021 · Terms of Use · Privacy Policy</Typography>
                </div>
            </div>
        </div>
    );
}

export default Footer;
