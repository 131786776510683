import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Auth } from 'aws-amplify';
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import ChangePassword from "./change-password";
import {showSnackbar} from "../../../store/app/snackbar.slice";

const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    content: {
        width: '45%',
        height: '94%',
        border: '1px solid #cccccca6',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '80%',
            height: '98%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: '98%',
        }
    },
    logoContainer: {
        margin: '30px 0px'
    },
    logoImage: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '250px'
        },
     },
    codeConfirmBody: {
        width: '80%',
        marginTop: '100px'
    },
    verifyButton: {
        marginTop: '30px'
    },
    resendButton: {
        marginTop: '40px'
    },
    resendTimer: {
        marginTop: '40px',
        color: theme.palette.accent.main
    },
    resendLoader: {
        marginTop: '40px'
    },
    homeButton: {
        marginTop: '40px'
    }
}));

function ForgotPassword() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);

    // FORM CONTROL
    const methods = useForm({ mode: 'onBlur' });
    const { watch,  control, formState } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    const { username } = form;

    // EFFECTS

    // METHODS
    const handleSendVerificationCode = () => {
        if(username) {
            setLoading(true)
            Auth.forgotPassword(username)
                .then(data => {
                    console.log("here");
                    setLoading(false);
                    setSearchParams({'username': username});
                    setIsCodeSent(true);
                })
                .catch(err => {
                    setLoading(false);

                    dispatch(showSnackbar({
                        message: err.message,
                        autoHideDuration: 6000,//ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'left'//left center right
                        },
                        variant: 'error'
                    }));
                });
        }
    }

    //
    const gotoHome = () => {
        navigate(`/`);
    }

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-start items-stretch", classes.root)}>
            {
                isCodeSent ?
                    <ChangePassword username={username} />
                    :
                    <div className={clsx("flex flex-col justify-center items-center", classes.container)}>
                        <div className={clsx("flex flex-col justify-start items-stretch", classes.content)}>
                            <div className={clsx("flex flex-col justify-start items-center", classes.logoContainer)}>
                                <img className={clsx(classes.logoImage)} src="../../../assets/logo/logo_text.svg" />
                            </div>

                            <div className={clsx("flex flex-col justify-start items-center", classes.confirmCodeContainer)}>
                                <Typography variant="h4">Forgot Password</Typography>

                                <div className={clsx("flex flex-col justify-start items-center", classes.codeConfirmBody)}>
                                    <Controller
                                        name="username"
                                        control={control}
                                        defaultValue={searchParams.get('username')}
                                        rules={{ required: "Username is required" }}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <TextField
                                                className={clsx("w-10/12")}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                error={!!errors.code}
                                                required
                                                helperText={errors?.code?.message}
                                                label="Username"
                                                id="code"
                                                variant="outlined"
                                            />
                                        )}
                                    />

                                    <LoadingButton className={clsx("w-10/12", classes.verifyButton)} variant="contained" loading={loading} onClick={handleSendVerificationCode}>Send</LoadingButton>
                                </div>

                                <Button className={clsx(classes.homeButton)} variant="outlined" size="large" color="primary" onClick={gotoHome}>Home</Button>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default ForgotPassword;
