import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {createSearchParams, useLocation, useNavigate} from "react-router-dom";
import clsx from "clsx";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { submitLogin, resetLoginStatus } from '@app/auth/store/login.slice';
import { showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";

// LOGIN
const useStyles = makeStyles(theme => ({
    root: {

    },
    container: {
        height: '100%'
    },
    imageContainer: {
        width: '36%'
    },
    mainImage: {
        height: '100%'
    },
    content: {
        width: '100%'
    },
    contentBody: {
        width: '100%',
        marginTop: '20px'
    },
    actionBody: {
        width: '90%'
    },
    horizontalLine: {
        width: '45%',
        height: '1px'
    },
    horizontalLineText: {
        width: '10%',
        padding: '0 10px'
    },
    oAuthBody: {
        width: '90%'
    }
}));
const LoginComponent = ({
    redirectUrl,
    handleClose,
    handleToggleAuthState
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // REDUX
    const loginStatus = useSelector(({auth}) => auth.login);

    // STATES
    const [loading, setLoading] = useState(false);

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    // SIDE EFFECTS
    useEffect(() => {
        if(loginStatus.success){
            setLoading(false);
            handleClose();
            dispatch(showSnackbar({
                message: "You are successfully logged in",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'success'
            }));
        } else if(loginStatus.errors.length) {
            const loginError = loginStatus.errors[0];

            if(loginError.code === "UserNotConfirmedException") {
                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'info'
                }));
            } else {
                dispatch(showSnackbar({
                    message: loginError.message,
                    autoHideDuration: 6000,//ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'left'//left center right
                    },
                    variant: 'error'
                }));
            }
            setLoading(false);
        }
    }, [loginStatus]);

    // Unmount Component
    useEffect(() => () => {
        dispatch(resetLoginStatus());
    },[])

    // METHODS
    const handleLogin = () => {
        if(isValid && isDirty) {
            setLoading(true);
            dispatch(submitLogin({username: form.username, password: form.password}));
        }
    };

    const handleGoogleLogin = () => {
        sessionStorage.setItem('redirect', redirectUrl ? redirectUrl : null);
        Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
    }

    const gotoSignup = () => {
        // handleToggleAuthState();
        navigate(`../auth/signup?redirect=cart`);
    }

    //
    return (
        <div className={clsx("flex flex-col justify-center items-center", classes.content)}>
            <div className={clsx("flex flex-col justify-center items-center space-y-8", classes.contentBody)}>
                <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.actionBody)}>
                    <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Username is required" }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextField
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                className="mt-8 mb-16 w-12/25"
                                error={!!errors.username}
                                required
                                helperText={errors?.username?.message}
                                label="Username"
                                id="username"
                                variant="outlined"
                            />
                        )}
                    />

                    <div className={clsx("flex flex-col justify-start items-stretch")}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{ required: "Password is required" }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TextField
                                    type="password"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className="mt-8 mb-16 w-12/25"
                                    error={!!errors.password}
                                    required
                                    helperText={errors?.password?.message}
                                    label="Password"
                                    id="password"
                                    variant="outlined"
                                />
                            )}
                        />
                        <div className={clsx("flex flex-row justify-end items-center")}>
                            <Button variant="text">Forgot password?</Button>
                        </div>
                    </div>

                    <LoadingButton variant="contained" loading={loading} onClick={handleLogin}>Let's Go</LoadingButton>
                </div>

                <div className={clsx("w-4/5 flex flex-row justify-center items-center")}>
                    <hr className={clsx(classes.horizontalLine)}/>
                    <Typography className={clsx(classes.horizontalLineText)} variant="body1">or</Typography>
                    <hr className={clsx(classes.horizontalLine)}/>
                </div>

                <div className={clsx("flex flex-col justify-start items-stretch space-y-6", classes.oAuthBody)}>
                    <Button variant="google" onClick={handleGoogleLogin}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-google mr-4" viewBox="0 0 16 16">
                            <path
                                d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                        </svg>
                        Login with Google
                    </Button>

                    {/*<Button variant="facebook">*/}
                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*         className="bi bi-facebook mr-4" viewBox="0 0 16 16">*/}
                    {/*        <path*/}
                    {/*            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>*/}
                    {/*    </svg>*/}
                    {/*    Login with Facebook*/}
                    {/*</Button>*/}
                </div>

                <div className={clsx("flex flex-row justify-center items-center", classes.SignupBody)}>
                    <Typography variant="subtitle1">Don't have account?</Typography>
                    <Button variant="text" onClick={gotoSignup}>Signup</Button>
                </div>
            </div>
        </div>
    )
}

// SIGNUP


// AUTH MODAL
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: '500px',
        [theme.breakpoints.down('sm')]: {
            width: "340px"
        },
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '.dialog-auth-header': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& #dialog-logo': {
        width: '100px'
    },
    '.dialog-auth-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    }
}));
const BootstrapDialogHeader = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogHeader.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function AuthModal({
    showAuth,
    handleClose,
    redirectUrl
}) {
    const [open, setOpen] = useState(false);
    const [authState, setAuthState] = useState(1);      // 1: LOGIN, 0: SIGNUP

    useEffect(() => {
        setOpen(showAuth)
    }, [showAuth]);

    const handleClickConfirm = () => {
        // handleConfirm();
        setOpen(true);
    };

    const handleClickClose = () => {
        handleClose();
        setOpen(false);
    };

    const handleToggleAuthState = () => {
        setAuthState(state => state ? 0 : 1);
    };

    return (
        <BootstrapDialog
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogHeader id="customized-dialog-title" onClose={handleClickClose}>
                Log in to your account
            </BootstrapDialogHeader>

            <DialogContent>
                <div className="dialog-auth-content">
                    {
                        authState ?
                            <LoginComponent
                                redirectUrl={redirectUrl}
                                handleToggleAuthState={handleToggleAuthState}
                                handleClose={handleClickClose}
                            />
                            :
                            null
                    }
                </div>
            </DialogContent>
        </BootstrapDialog>
    );
}

export default AuthModal;
