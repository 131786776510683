import { combineReducers } from '@reduxjs/toolkit';

import cart from './cart.slice';
import checkout from './checkout.slice';

const reducer = combineReducers({
    cart,
    checkout
});

export default reducer;
