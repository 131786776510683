import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 14px',
        },
    }
}));

function Careers() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);

    // STATES


    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <Outlet />
        </div>
    );
};

export default Careers;
