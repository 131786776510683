import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import {getUserCart} from "../../../../platform/user/store/cart.slice";

// ASYNC THUNK OPERATIONS
export const getEnrolledCareers = createAsyncThunk('studentCareers/getEnrolledCareers', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.get(`/platform/mycareers?userId=${userId}`).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});

export const getCareerSummary = createAsyncThunk('studentCareers/getCareerSummary', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.get(`/career/${params.careerId}/user/${userId}/summary`).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});

// ENTITY ADAPTER
const careersListAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = careersListAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = careersListAdapter.getInitialState({
    list: [],
    status: 0,
    loading: false,
});

// SLICE
const careersListSlice = createSlice({
    name: 'studentCareers',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {
        [getEnrolledCareers.fulfilled]: (state, action) => {
            state.list = action.payload.data;
        }
    }
});

// ACTIONS
export const { setLoading } = careersListSlice.actions;

// REDUCERS
export default careersListSlice.reducer;
