import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate, useLocation } from "react-router-dom";
//
// import withReducer from '../../../store/withReducer';
// import reducer from './store';
// import { getSearch } from "./store/header.slice";
// import { logoutUser } from '@app/auth/store/user.slice';

//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from "@mui/material/Popper";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CircularProgress, {circularProgressClasses} from '@mui/material/CircularProgress';
import CircleIcon from '@mui/icons-material/Circle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles(theme => ({
    questionContainer: {
        height: '70vh'
    },
    answerContainer: {

    },
    answerInput: {
        width: '60%',
        height: '200px'
    },
    nextButton: {
        width: '200px',
        height: '50px',
    }
}));

function QuestionInput() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);

    // STATES
    const [answer, setAnswer] = useState(null);

    // EFFECTS
    useEffect(() => {

    }, []);

    // METHODS


    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.questionContainer)}>
            <Typography variant="body1">Question</Typography>

            <div className={clsx("mt-5")}>
                <Typography variant="h6">I like the task and activities presented in this career option</Typography>
            </div>

            <div className={clsx("mt-8 flex flex-col justify-start items-stretch space-x-5", classes.answerContainer)}>
                <TextField
                    className={classes.answerInput}
                    id="answer-input"
                    label="Your answer"
                    multiline
                    rows={8}
                    value={answer}
                    onChange={(event) => setAnswer(event.target.value)}
                    variant="outlined"
                />
            </div>

            <div className={clsx("flex-grow flex flex-row justify-start items-end")}>
                <Button className={classes.nextButton} disabled={!answer} variant="contained" color="primary">NEXT</Button>
            </div>
        </div>
    );
};

export default QuestionInput;
