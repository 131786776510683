import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


export function ProgressButton(props) {
    const {label, loading, handleButtonClick, color, classes } = props;

    return (
        <Box sx={{ m: 1, position: 'relative' }}>
            <Button
                className={classes}
                variant="contained"
                color={color}
                disabled={loading}
                onClick={handleButtonClick}
            >
                {label}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    color={color}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    )
};
