import React, {useEffect, useState} from "react";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import useMediaQuery from '@mui/material/useMediaQuery';
//
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 1000,
        backgroundColor: '#a5a5a5f2'
    },
    messageContainer: {
        marginTop: '30px'
    }
}));

const OverlayTimer = (props) => {
    const {duration, message, onComplete} = props;
    const classes = useStyles();
    const isMobileMatch = useMediaQuery('(max-width:599px)');

    return (
        <div className={clsx("flex flex-col justify-center items-center", classes.root)}>
            <CountdownCircleTimer
                isPlaying
                duration={duration}
                colors={['#70CDE0', '#FFC860', '#FE7062']}
                colorsTime={[6, 3, 0]}
                onComplete={() => {
                    onComplete();
                }}
            >
                {({remainingTime}) => (
                    <Typography variant="h3">{remainingTime}</Typography>
                )}
            </CountdownCircleTimer>

            <div className={clsx("", classes.messageContainer)}>
                <Typography variant="h5">{message}</Typography>
            </div>
        </div>
    )
}

export default OverlayTimer;
