export const data = [
  {
    name: "SRUTHAKEERTHI P SHENOY",
    text: "It was a great experience. I was able to understand and implement all the psychometric tools. All my doubts were cleared during the course. The recorded sessions is a boon, it helps me to get deeper understanding whenever needed. Grateful to be a part of CCP.",
    rating: 5,
  },
  {
    name: "Sushma Sadalage",
    text: "Good experience attending the sessions. The concepts were well explained.",
    rating: 4,
  },
  {
    name: "Mitali Srivastava ",
    text: "All the tools were explained efficiently and doubts were addressed and resolved immediately. The sessions were over all good . ",
    rating: 4.75,
  },
  {
    name: "Pratima.Prashant.Thakur",
    text: "New career venture has opened for me with ease. Trainer was very helpful throught the process. I am eager to start on my own as the course had given me the confidence to do so.",
    rating: 4.75,
  },
  {
    name: "Rashi Mathur",
    text: "I could not attend the live sessions however recorded sessions gave me exact knowledge as i would have got in the live session. Anmol is very detailed in his explanation. Thank you!",
    rating: 4.75,
  },
  {
    name: "Arunees Ravat",
    text: "It was a good learning experience. ",
    rating: 5,
  },
  {
    name: "Dr. Shreya Anand",
    text: "It was a great experience learning through this course.",
    rating: 5,
  },
  {
    name: "Neha shah",
    text: "Supper se upper,  worth it, supportive ,knowledgeable",
    rating: 4.5,
  },
  {
    name: "Abhiyaan Kapoor Malhotra",
    text: "Good content, pace of learning, style of teaching and covered relevant topics, would prefer a longer course as well ",
    rating: 5,
  },
  {
    name: "Uzma Charania",
    text: "Everything explained really well and I have started using the tools with my students...it is of great help...thank you ccp",
    rating: 4.75,
  },
  {
    name: "Sneha Hitesh Desai",
    text: "It was really an amazing experience, thank you.",
    rating: 5,
  },
  {
    name: "Rohith S H",
    text: "This was an eye opener course for me. Sir was patient enough to explain the topics in detail and answer all the questions. The support provided was really helpful. Would like to connect back for any guidance on this. Thanks a lot sir",
    rating: 4.75,
  },
  {
    name: "Madhura Gawas",
    text: "It was very good experience and gave a introduction to different kinds of assessments and tools used. ",
    rating: 4,
  },
  {
    name: "Rupali Sharma ",
    text: "The course is perfectly designed.",
    rating: 5,
  },
  {
    name: "Shrinidhi Karole",
    text: "It was a nice experience. Sir was patient with our questions and helped us all the way",
    rating: 4,
  },
  {
    name: "Monica Moorthy",
    text: "This is a great course, covering all concepts, tests and tools, delivered with clarity. Excellent explanation and  doubt clearing by the trainer. ",
    rating: 5,
  },
  {
    name: "Sadrunnisa Khan",
    text: "I'm glad to get this course which made my work so easy in career counseling process. Thank you Anmol for ur great support and also the Career Connects Passion institute.",
    rating: 5,
  },
  {
    name: "Smriti Adhikari ",
    text: "This course has been very helpful in providing all the necessary information required.",
    rating: 5,
  },
  {
    name: "Ritu Munjal",
    text: "Trainer was very good in explaining the concepts. I learnt a lot from the sessions ",
    rating: 5,
  },
  {
    name: "Avya Kant",
    text: "It was a very well explained course ",
    rating: 4.5,
  },
  {
    name: "MS CAROL DIAS",
    text: "Very informative. Thank You to Anmol for giving such a great course. ",
    rating: 4.75,
  },
  {
    name: "Divya Sharma",
    text: "I have surely benefitted from the course and i have made a good choice enrolling for the same. The course was very informative and engrossing. Course content is brilliantly designed and is easily understood. Our trainer Anmol has great teaching skills , and was helpful in and after session doubt solving. I highly recommend the course .Thank you.",
    rating: 5,
  },
  {
    name: "Smt. Akhila",
    text: "Its was really helpful to know the methofologies and the tools.",
    rating: 4.75,
  },
  {
    name: "Janvi Prashant Bhosale",
    text: " Nothing became real till it is was experienced during the tasks assigned and also the tests answered in the classroom. Made me understand from the root level. ",
    rating: 4.75,
  },
  {
    name: "Dr. Padmaja Ajit Ganpatye",
    text: "All sessions were excellent and informative. Thanks Anmol ",
    rating: 4.75,
  },
  {
    name: "Noela Vishal Pereira ",
    text: "Very cost effective and to the point and clear with explaining the concept",
    rating: 4,
  },
  {
    name: "Shweta Swarnkar",
    text: "Excellent",
    rating: 5,
  },
  {
    name: "Mohmed Arif Abubakar Shaikh ",
    text: "Amazing",
    rating: 5,
  },
  {
    name: "Shraddha Rajput",
    rating: 5,
    text: "Thank u teacher and it was really a positive impact on my life",
  },
  {
    name: "SHALINI BATRA",
    rating: 4,
    text: "Overall, my experience with this certification course has been extremely positive.",
  },
  {
    name: "Namrata Lohana",
    rating: 5,
    text: "Very much helpful and the session was perfectly organized. All commitments been fulfilled",
  },
  {
    name: "Smriti Singhal",
    rating: 5,
    text: "Overall experience was very good. I got to learn a lot from this course which will help me in my career.",
  },
  {
    name: "Garima Nayyar",
    rating: 4,
    text: "The training was detailed. Trainer was able to cover all necessary tools and segments efficiently. Thankyou for this wonderful opportunity and experience.",
  },
  {
    name: "Prayag Deshpande",
    rating: 5,
    text: "Overall it was great experience, I learnt a lot from this course and I can easily understand the concepts. We can apply our knowledge on practical also so it was very great experience",
  },
  {
    name: "Kanakalakshmi Jaishankar",
    rating: 4.75,
    text: "A great experience. Every effort taken to make sure students understand every concept. Very hospitable.",
  },
  {
    name: "Pooja Verma",
    rating: 5,
    text: "My experience was really great. In this course I learned many new things and tools which is very helpful for student to get a clear picture of their career. I got to know about some marketing plans which is helpful to build-up our career.",
  },
  {
    name: "Gargi jaiswal",
    rating: 5,
    text: "Honestly, I was thinking that just like other short term program it will be just of watching video and at the end you will get the certificate but It proved me wrong and changed my perspective and I really appreciate this. I learned alot with this program and and with all the task I was able to practice everything. I must say whatever promised before enrollment was fulfilled and 100% Time and cost worthy. Thank you so much CCP and to the trainer. Will definitely recommend other's.",
  },
  {
    name: "Neetshi Srivastava",
    rating: 5,
    text: "I am so delighted, i joined this course, it's very informative and well planned. Mr. Anmol is a great counselor who has vast knowledge in this field",
  },
  {
    name: "KAVYA CS",
    rating: 5,
    text: "Basically I am a teacher but wanted to add more skills . While attending the Sessions I got confidence and now I am able to take career counseling sessions  with students. After seeing the results they also felt happy. Thank you Anmol sir for teaching each and every step with patience.",
  },
  {
    name: "Chougale Pallavi Prakash",
    rating: 5,
    text: "Very good and informative.",
  },
  {
    name: "Dr. Shyamal A. Nanaware",
    rating: 5,
    text: "This certification course has been great. I have enjoyed the whole learning process and hope to use this knowledge to the fullest. Thanks to Sir for the creating this informative course.",
  },
  {
    name: "Poonam Sethi",
    rating: 5,
    text: "Very knowledgeable,came to understand how we can check client's interests, weaknesses and abilities",
  },
  {
    name: "Renuka Joshi",
    rating: 4.75,
    text: "My experience was good. I have learnt alot of new things which i wasn't aware earlier. The course was well structured and practice oriented, in addition, Marketing strategies and how to establish our profiles online were also taught which makes the course overall amazing.",
  },
  {
    name: "Geetha Manjunath",
    rating: 5,
    text: "I became very confident after this course to take career counseling sessions independently",
  },
  {
    name: "Rajani Parvathi Devi Mitta",
    rating: 4.5,
    text: "It's good in low budget",
  },
  {
    name: "Rekha Dua",
    rating: 5,
    text: "Good content and explanation",
  },
  {
    name: "Sarita Jyoti Singh",
    rating: 5,
    text: "The course was very well instructed and practical. I leared alot.",
  },
  {
    name: "Preeti Bapna",
    rating: 4.75,
    text: "Awesome experience. Very clearly explained and understood",
  },
  {
    name: "Chandrakala D",
    rating: 5,
    text: "It was up to my expectations, everything was made clear at the point, it was really good and I look forward to any other such courses offered in future.",
  },
  {
    name: "Saee Churmure",
    rating: 5,
    text: "Very good experience",
  },
  {
    name: "Sejal bhalla",
    rating: 5,
    text: "My experience of the course was quite good.",
  },
  {
    name: "Somya Bajaj",
    rating: 5,
    text: "It was amazing and loved that the fact that it was interactive.Hoping to take utilise all the resources and recordings.",
  },
  {
    name: "Shilpa Krishnappa",
    rating: 5,
    text: "Great experience and well thought.",
  },
  {
    name: "Varini Verma",
    rating: 4.75,
    text: "I got alot of exposure and learning from this training. Everything was very well explained and facilitator was kind enough to address all the queries.",
  },
  {
    name: "Namrata  Sinha",
    rating: 4,
    text: "Overall it was a great learning experience.",
  },
  {
    name: "Chirag Arora",
    rating: 4.5,
    text: "Great experience. Learnt so much about this field.",
  },
  {
    name: "Preeti S Anand",
    rating: 5,
    text: "The course modules were great. They provided  us with great insight as to what qualities and methods are required to become a good career counsellor. The marketing tools were also explained very easily and effectively.",
  },
  {
    name: "Dhananjay Bhat",
    rating: 4.5,
    text: "Good course.This is course is a milestone for most of us. The Trainer was very approachable and handled our questions very efficiently.",
  },
  {
    name: "Sunil Naba Panda",
    rating: 5,
    text: "Great experience. Specially recording is available👍",
  },
  {
    name: "Indrani Mukherjee",
    rating: 4.75,
    text: "Very interesting and informative",
  },
  {
    name: "Poonam Kalyani",
    rating: 5,
    text: "Really helped a lot",
  },
];
