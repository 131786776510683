import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
//
import {createOrder, enrollAll, updateOrder} from "../store/checkout.slice";
import {resetCart} from "../store/cart.slice";
//
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {showSnackbar} from "../../../../store/app/snackbar.slice";
import Loader from "@app/layout/shared/loader.component";
import appConfig from '@/@config/config';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: '60vh',
        margin: '20px 0px'
    },
    checkoutContainer: {
        width: "33%",
        height: '50vh',
        padding: '10px',
        border: '1px solid #cccccca6',
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            height: '50vh',
            padding: '10px',
        },
    }
}));

function Checkout() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cartItems = useSelector(({user}) => user.cart.items);
    const cartSellingTotal = useSelector(({user}) => user.cart.selling_total);
    const cartStrikeTotal = useSelector(({user}) => user.cart.strike_total);

    const [loading, setLoading] = useState(false);
    const [gst, setGst] = useState();
    const [totalAmount, setTotalAmount] = useState();

    useEffect(() => {
        setGst(state => (cartSellingTotal * 0.18).toFixed(2));
        setTotalAmount(state => (cartSellingTotal * 1.18).toFixed(2));
    }, [])

    // METHODS
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const displayRazorpay = async () => {
        if(cartItems.length) {

        }

        setLoading(true);

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Something went wrong, please try later");
            return;
        }

        let careersBody = cartItems.map(career => ({
            careerId: career.id,
            unit_price: career.selling_price,
            instance: 1,
            total_price: career.selling_price
        }));

        let order = {
            amount: window.location.hostname.includes('localhost') ? 100 : totalAmount*100,
            careers: careersBody
        };

        dispatch(createOrder(order)).then(unwrapResult).then(response => {
            const {amount, id: order_id, currency} = response?.data;

            setLoading(false);

            const options = {
                key: appConfig.RAZORPAY,
                amount: amount,
                currency: currency,
                order_id: order_id,
                method: {
                    netbanking: true,
                    card: true,
                    wallet: true,
                    upi: true,
                    paylater: false
                },
                handler: async function (response) {
                    const data = {
                        "razorpay_payment_id": response?.razorpay_payment_id,
                        "razorpay_order_id": response?.razorpay_order_id,
                        "razorpay_signature": response?.razorpay_signature,
                    };

                    if(response?.razorpay_payment_id) {
                        dispatch(updateOrder({orderId: order_id, data})).then(unwrapResult).then(response => {
                            handleCheckoutSuccess()
                        }).catch(error => {
                            console.log(error);
                            dispatch(showSnackbar({
                                message: "Something went wrong, please try later",
                                autoHideDuration: 6000,//ms
                                anchorOrigin: {
                                    vertical  : 'bottom',//top bottom
                                    horizontal: 'right'//left center right
                                },
                                variant: 'error'
                            }));
                        });
                    }
                }
            };

            const paymentObject = new window.Razorpay(options);

            paymentObject.on('payment.failed', function (response){
                alert("Something went wrong, please try later");
            });

            paymentObject.open();

        }).catch(error => {
            dispatch(showSnackbar({
                message: "Something went wrong, please try later",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'right'//left center right
                },
                variant: 'error'
            }));
        })
    }

    const handleCheckoutSuccess = () => {
        setLoading(true);
        dispatch(enrollAll()).then(unwrapResult).then(response => {
            navigate(`/user-student/main/dashboard`);
            dispatch(resetCart());
            setLoading(false);

            dispatch(showSnackbar({
                message: "Careers has been enrolled into your account, Thank You.",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'success'
            }));
        }).catch(error => {
            const message = error?.data?.message ? error?.data?.message : "Something went wrong";

            dispatch(showSnackbar({
                message: message,
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'left'//left center right
                },
                variant: 'error'
            }));

            setLoading(false);
        })
    }

    const handleCheckoutTrial = () => {
        setLoading(true);

        let careersBody = cartItems.map(career => ({
            careerId: career.id,
            unit_price: career.selling_price,
            instance: 1,
            total_price: career.selling_price
        }));

        let order = {
            amount: 100,
            careers: careersBody
        };
        dispatch(createOrder(order)).then(unwrapResult).then(response => {
            handleCheckoutSuccess();
        }).catch(error => {
            dispatch(showSnackbar({
                message: "Something went wrong, please try later",
                autoHideDuration: 6000,//ms
                anchorOrigin: {
                    vertical  : 'bottom',//top bottom
                    horizontal: 'right'//left center right
                },
                variant: 'error'
            }));
        })

    }

    const handleCheckout = () => {
        if(cartSellingTotal != 0) {
            displayRazorpay();
        } else {
            handleCheckoutTrial();
        }
    }

    return (
        <div className={clsx("flex flex-col md:flex-row justify-center items-center", classes.root)}>
            <div className={clsx("flex flex-col space-y-8", classes.checkoutContainer)}>
                <Typography variant="h5">Checkout</Typography>

                <div className={clsx("flex flex-col", classes.checkoutDetails)}>
                    <Typography variant={"body1"}>Careers #: {cartItems?.length}</Typography>
                    <Typography variant={"body1"}>Careers amount: ₹{cartSellingTotal}</Typography>
                    <Typography variant={"body1"}>GST(18%): ₹{gst}</Typography>

                    <div className={clsx("mt-10")}>
                        <Typography variant={"h6"}>Total amount: ₹{totalAmount}</Typography>
                    </div>
                </div>

                <div className={clsx("basis-1 flex flex-col justify-end")}>
                    <LoadingButton variant="contained" color="primary" sx={{height: '55px'}} onClick={handleCheckout} loading={loading} disabled={loading}>
                        CONFIRM
                    </LoadingButton>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
