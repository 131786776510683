import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
//
import withReducer from "../../../store/withReducer";
import reducer from "./store";
import { getSearch } from "./store/header.slice";
import { logoutUser } from "@app/auth/store/user.slice";

//
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles((theme) => ({
  root: {},
  WideScreen: {
    margin: "0px 30px",
    padding: "20px 0px",
    borderBottom: "1.5px solid #6e6e6e85",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  MobileScreen: {
    margin: "0px 15px",
    padding: "15px 0px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  logo_box: {},
  logo: {
    height: "50px",
    cursor: "pointer",
  },
  mobileLogo: {
    height: "90px",
  },
  search_box: {
    // margin: "0px 20px",
    // border: "2px solid #6e6e6e85",
    // borderRadius: "5px",
    width: "240px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  search_button: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {},
  },
  search: {
    padding: "5px",
    "&:focus": {
      outline: "none",
    },
  },
  explore_button: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    padding: "2px 10px;",
    color: theme.palette.primary.main,
  },
  user_button_box: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  user_button_box_md: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {},
  },
  signup_login_button: {
    padding: "5px 10px",
    borderRadius: "5px",
    color: "white",
    backgroundColor: theme.palette.accent.main,
  },
  cartButton: {
    "&:hover": {
      color: theme.palette.accent.main,
    },
  },
  accountAvatar: {
    height: "40px",
    width: "40px",
    backgroundColor: "#424242",
    // boxShadow: `0 0 0.5rem rgba(0, 0, 0, 0.3)`,
    transition: `background-color 0.3s ease`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#757575",
    },
  },
  accountAvatarMobile: {
    height: "30px",
    width: "30px",
    backgroundColor: "#424242",
  },
  accountPopper: {
    margin: "0 0 10px 0",
  },
  option: {
    height: "100px",
    width: "100px",
    backgroundColor: "red",
  },
  searchOptions: {
    width: "200px",
    backgroundColor: "red",
  },
  searchLoading: {
    minHeight: "70px",
  },
  searchNoOptions: {
    padding: "5px 10px 10px",
  },
  searchCantFind: {
    color: theme.palette.accent.main,
    cursor: "pointer",
    textDecoration: "underline",
    fontStyle: "italic",
  },
  searchPopper: {
    zIndex: "1000",
    width: "300px",
    maxHeight: "500px",
    inset: `5px auto auto 5px !important`,
  },
  searchMainPaper: {
    padding: "10px",
  },
  searchCareerOptions: {},
  searchFieldOptions: {},
  searchCategoryHeading: {
    marginBottom: "5px",
  },
  searchOption: {
    padding: "3px 14px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eaeaeac7",
    },
  },
  cart: {
    margin: "15px 0 0 0",
    width: "250px",
    minHeight: "160px",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
  cartLoading: {
    height: "200px",
  },
  cartEmptyContainer: {
    height: "200px",
  },
  cartItemsContainer: {
    height: "100%",
    padding: "10px 10px",
  },
  cartItem: {},
  cartItemLogoContainer: {
    width: "60px",
    height: "60px",
  },
  cartItemLogo: {
    height: "100%",
  },
  cartItemMetaContainer: {},
  MobileDrawerClose: {
    position: "absolute",
    left: "0",
    top: "0",
  },
}));

const accountPopOverStyles = makeStyles((theme) => ({
  account: {
    margin: "20px 0px 0 0",
    width: "200px",
    // height: '350px'
  },
  accountContainer: {},
  accountMeta: {
    height: "80px",
    margin: "0px 10px",
    borderBottom: "1px solid #cccccca6",
  },
  accountAvatarContainer: {
    width: "75px",
  },
  accountAvatar: {
    height: "40px",
    width: "40px",
    backgroundColor: "#424242",
    // boxShadow: `0 0 0.5rem rgba(0, 0, 0, 0.3)`,
    transition: `background-color 0.3s ease`,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#757575",
    },
  },
  accountName: {},
  accountPopListContainer: {},
  accountPopList: {
    margin: "0px 10px",
    padding: "8px 0",
    borderBottom: "1px solid #cccccca6",
  },
  mainList: {},
  accountList: {},
  logoutContainer: {
    margin: "0px 10px",
    padding: "8px 0",
  },
  listItem: {
    cursor: "pointer",
    margin: "2px 0px",
    transition: `background-color 0.3s ease`,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
  logoutItem: {
    cursor: "pointer",
    margin: "2px 0px",
    transition: `background-color 0.3s ease`,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.accent.main,
    },
  },
}));

function SearchPaperComponent(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { options, searchLoading, handleSearchClose } = props;

  // STATES
  const [careers, setCareers] = useState([]);
  const [fields, setFields] = useState([]);

  // EFFECTS
  useEffect(() => {
    // console.log(options);
    setCareers(options.careers);
    setFields(options.fields);
  }, [options]);

  // METHODS
  const handleClickOption = () => {
    console.log("event");
  };

  const gotoCareer = (id) => {
    navigate(`/careers/${id}`);
    handleSearchClose();
  };

  const gotoField = (id) => {
    navigate(`/careers?field=${id}`);
    handleSearchClose();
  };

  const gotoRequestCareer = () => {
    navigate("/career-request");
    handleSearchClose();
  };

  if (searchLoading) {
    return (
      <Paper
        elevation={14}
        className={clsx(
          "flex flex-row justify-center items-center",
          classes.searchLoading
        )}
      >
        <CircularProgress
          color="accent"
          size={30}
          thickness={3}
          disableShrink
          sx={{
            animationDuration: "800ms",
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
        />
      </Paper>
    );
  }

  if (
    options &&
    options.careers &&
    options.fields &&
    options.careers.length === 0 &&
    options.fields.length === 0
  ) {
    return (
      <Paper
        elevation={14}
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.searchNoOptions
        )}
      >
        <div
          className={clsx(
            "flex flex-col justify-start items-stretch",
            classes.searchNoOptions
          )}
        >
          <Typography variant="subtitle1">Nothing found</Typography>
          <div
            className={clsx("", classes.searchCantFind)}
            onClick={gotoRequestCareer}
          >
            Can't find your searched career ?
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <Paper elevation={8} className={clsx(classes.searchMainPaper)}>
      {careers && careers.length > 0 && (
        <div className={clsx(classes.searchCareerOptions)}>
          <Typography
            className={clsx(classes.searchCategoryHeading)}
            variant="caption"
          >
            Careers
          </Typography>
          {careers &&
            careers.map((option, index) => (
              <div
                key={index}
                className={clsx("", classes.searchOption)}
                onClick={() => gotoCareer(option._id)}
              >
                <Typography variant="body1">{option.name}</Typography>
              </div>
            ))}
        </div>
      )}

      {fields && fields.length > 0 && (
        <div className={clsx(classes.searchFieldOptions)}>
          <Typography
            className={clsx(classes.searchCategoryHeading)}
            variant="caption"
          >
            Fields
          </Typography>
          {fields &&
            fields.map((option, index) => (
              <div
                key={index}
                className={clsx("", classes.searchOption)}
                onClick={() => gotoField(option._id)}
              >
                <Typography variant="body1">{option.name}</Typography>
              </div>
            ))}
        </div>
      )}
    </Paper>
  );
}

function Cart(props) {
  const classes = useStyles();

  const { mouseLeave, handleCheckout } = props;

  const cartLoading = useSelector(({ user }) => user?.cart?.loading);
  const cartItems = useSelector(({ user }) => user?.cart?.items);
  const cartSellingTotal = useSelector(({ user }) => user?.cart?.selling_total);

  // STATEs
  // const [cartItems, updateCartItems] = useState()

  // EFFECTS

  // METHODS

  return (
    <Paper
      elevation={4}
      className={clsx(classes.cart)}
      onMouseLeave={mouseLeave}
    >
      {cartLoading ? (
        <div
          className={clsx(
            "flex flex-col justify-center items-center",
            classes.cartLoading
          )}
        >
          <CircularProgress
            color="accent"
            size={40}
            thickness={3}
            disableShrink
            sx={{
              animationDuration: "1000ms",
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
          />
        </div>
      ) : cartItems && cartItems.length ? (
        <div className={clsx("flex flex-col", classes.cartItemsContainer)}>
          <div className={clsx("flex flex-col")}>
            {cartItems.map((item, index) => (
              <div className={clsx("flex flex-col")}>
                <div
                  className={clsx(
                    "flex flex-row space-x-4 my-3",
                    classes.cartItem
                  )}
                >
                  <div className={clsx(classes.cartItemLogoContainer)}>
                    <img
                      className={clsx(classes.cartItemLogo)}
                      src={item?.logo}
                    />
                  </div>

                  <div className={clsx(classes.cartItemMetaContainer)}>
                    <Typography variant="body2" className={clsx("font-bold")}>
                      {item?.name}
                    </Typography>
                    <Typography variant="caption">
                      {item?.field?.name}
                    </Typography>

                    <div className={clsx("flex flex-row space-x-3")}>
                      <Typography variant="body2">
                        Rs. {item?.selling_price}
                      </Typography>
                      <Typography variant="caption">
                        <strike>Rs. {item?.strike_price}</strike>
                      </Typography>
                    </div>
                  </div>
                </div>

                <Divider />
              </div>
            ))}
          </div>

          <div
            className={clsx(
              "flex-grow flex flex-col justify-end items-start mt-2"
            )}
          >
            <div className={clsx("flex-grow flex flex-row")}>
              <Typography variant="h6" className={clsx("font-bold")}>
                Total: ₹{cartSellingTotal}
              </Typography>
            </div>
          </div>

          <div
            className={clsx("mt-5 flex flex-col justify-start items-stretch")}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckout}
            >
              CHECKOUT
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            "flex flex-col justify-center items-center",
            classes.cartEmptyContainer
          )}
        >
          <Typography variant="body1">Cart is empty</Typography>
        </div>
      )}
    </Paper>
  );
}

export function Account(props) {
  const classes = accountPopOverStyles();
  const dispatch = useDispatch();

  const { mouseLeave } = props;

  const user = useSelector(({ auth }) => auth.user);
  const { role: userRole, data: userData, isCampusStudent } = user;

  // STATES
  const first_name = userData?.["custom:first_name"];
  const last_name = userData?.["custom:last_name"];

  // METHODS
  const handleLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Paper
      elevation={8}
      className={clsx(classes.account)}
      onMouseLeave={mouseLeave}
    >
      <div
        className={clsx(
          "flex flex-col justify-start items-stretch",
          classes.accountContainer
        )}
      >
        <div
          className={clsx(
            "flex flex-row justify-start items-center",
            classes.accountMeta
          )}
        >
          <div
            className={clsx(
              "flex flex-col justify-center items-center",
              classes.accountAvatarContainer
            )}
          >
            <button
              className={clsx(
                "flex flex-col justify-center items-center rounded-full",
                classes.accountAvatar
              )}
            >
              {isCampusStudent ? (
                <PersonIcon sx={{ color: "white" }} />
              ) : (
                <span className={clsx("text-white")}>
                  {first_name && first_name[0]}
                  {last_name && last_name[0]}
                </span>
              )}
            </button>
          </div>

          <div
            className={clsx(
              "flex flex-row justify-start items-center",
              classes.accountName
            )}
          >
            <Typography variant="h6">
              {first_name} {last_name}
            </Typography>
          </div>
        </div>

        <div
          className={clsx(
            "flex flex-col justify-start items-stretch",
            classes.accountPopListContainer
          )}
        >
          <div
            className={clsx(
              "flex flex-col justify-center items-stretch",
              classes.mainList,
              classes.accountPopList
            )}
          >
            <div className={clsx(classes.listItem)}>
              <Typography variant="body1">My Dashboard</Typography>
            </div>

            <div className={clsx(classes.listItem)}>
              <Typography variant="body1">My Cart</Typography>
            </div>
          </div>

          <div
            className={clsx(
              clsx("flex flex-col justify-center items-stretch"),
              classes.accountList,
              classes.accountPopList
            )}
          >
            <div className={clsx(classes.listItem)}>
              <Typography variant="body1">Account</Typography>
            </div>

            <div className={clsx(classes.listItem)}>
              <Typography variant="body1">Purchase History</Typography>
            </div>
          </div>

          <div
            className={clsx(
              clsx("flex flex-col justify-center items-stretch"),
              classes.logoutContainer
            )}
          >
            <div className={clsx(classes.logoutItem)} onClick={handleLogout}>
              <Typography variant="body1">Log out</Typography>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // REDUX
  const user = useSelector(({ auth }) => auth.user);
  const { role: userRole, data: userData, isCampusStudent } = user;

  // STATES
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchAnchor, setSearchAnchor] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchId, setSearchId] = useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [options, setOptions] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(0);
  const [showCart, setShowCart] = useState(false);
  const [cartAnchor, setCartAnchor] = useState(null);
  const [showAccount, setShowAccount] = useState(false);
  const [accountAnchor, setAccountAnchor] = useState(null);
  const [mobileSearch, setMobileSearch] = useState(false);
  // REF
  const searchInputRef = useRef(null);
  const searchVirtualRef = useRef(null);
  const cartIconRef = useRef(null);
  const accountAvatarRef = useRef(null);

  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([]);

      dispatch(getSearch(text))
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            callback(response.data);
          }
        });
    }, 200),
    []
  );

  // VARS AND CONST
  const first_name = userData?.["custom:first_name"];
  const last_name = userData?.["custom:last_name"];

  // EFFECTS
  useEffect(() => {
    setSelectedRoute((state) => {
      let { pathname } = location;
      if (pathname === "/student") {
        state = 1;
      } else if (pathname === "/parent") {
        state = 2;
      } else if (pathname === "/school") {
        state = 3;
      } else if (pathname === "/careers") {
        state = 4;
      } else if (pathname === "/career-counsellor") {
        state = 5;
      } else {
        state = 0;
      }

      return state;
    });
  }, [location]);

  useEffect(() => {
    if (searchQuery.length >= 2) {
      setSearchLoading(true);
      getOptionsDelayed(searchQuery, (filteredOptions) => {
        setOptions(filteredOptions);
        setSearchLoading(false);
      });
    }
  }, [searchQuery, getOptionsDelayed]);

  useEffect(() => {
    setSearchId(Boolean(searchAnchor) ? "search-popper" : undefined);
  }, [searchAnchor]);

  // METHODS
  const handleCartIconMouseOut = (event) => {
    const boundingRect = cartIconRef.current.getBoundingClientRect();
    const exitPosition = boundingRect.bottom - event.clientY;

    if (exitPosition > 10) {
      setShowCart(false);
      setCartAnchor(event.target);
    }
  };

  const handleCartMouseOver = (event) => {
    setShowCart(true);
    setCartAnchor(event.target);
  };

  const handleCartMouseOut = (event) => {
    setShowCart(false);
    setCartAnchor(event.target);
  };

  const handleAccountMouseOver = (event) => {
    setShowAccount(true);
    setAccountAnchor(event.target);
  };

  const handleAccountMouseOut = (event) => {
    setShowAccount(false);
    setAccountAnchor(event.target);
  };

  const handleAccountAvatarMouseOut = (event) => {
    const boundingRect = accountAvatarRef.current.getBoundingClientRect();
    const exitPosition = boundingRect.bottom - event.clientY;

    if (exitPosition > 10) {
      setShowAccount(false);
      setAccountAnchor(event.target);
    }
  };

  const handleMobileSearch = () => {
    setMobileSearch(true);
  };

  //
  const handleSearchFocus = (event) => {
    setSearchAnchor(event.currentTarget);
  };

  const handleSearchClickAway = (event) => {
    handleSearchClose();
  };

  const handleSearchClose = () => {
    setSearchQuery("");
    setSearchAnchor(null);
  };

  //
  const handleExplore = () => {
    navigate(`/careers`);
  };

  const gotoHome = () => {
    navigate(`/`);
  };

  const gotoStudent = () => {
    navigate(`/student`);
  };

  const gotoParent = () => {
    navigate(`/parent`);
  };

  const gotoSchool = () => {
    navigate(`/school`);
  };

  const gotoCounsellor = () => {
    navigate(`/career-counsellor`);
  };

  const handleCart = () => {
    navigate(`/cart`);
  };

  const handleLogin = () => {
    navigate(`/auth/login`);
  };

  const handleSignup = () => {
    navigate(`/auth/signup`);
  };

  const gotoDashboard = () => {
    navigate(`/user-student/main/dashboard`);
  };

  const handleAvatarClick = () => {
    navigate(`/user-student/main/dashboard`);
  };

  const handleCheckout = () => {
    setShowCart(false);
    navigate("/cart");
  };

  // MOBILE DRAWER
  const [mobileMenu, setMobileMenu] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [mobileAvatarMenu, setMobileAvatarMenu] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileMenu({ ...mobileMenu, [anchor]: open });
  };

  const toggleAvatarDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMobileAvatarMenu({ ...mobileAvatarMenu, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: "auto", height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <IconButton className={classes.MobileDrawerClose}>
        <CloseIcon />
      </IconButton>

      <div className={clsx("flex flex-col justify-center items-center m-10")}>
        <img
          className={classes.mobileLogo}
          src="assets/logo/logo_icon.svg"
          alt="main-logo"
          onClick={gotoHome}
        />
      </div>

      <Divider />

      <div className={clsx("flex flex-col justify-center items-center my-5")}>
        <div className={clsx("w-3/4 flex flex-col")}>
          {selectedRoute === 4 ? (
            <Button variant="contained" color="primary">
              Explore
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={handleExplore}>
              Explore
            </Button>
          )}
        </div>
      </div>

      <Divider />

      <div
        className={clsx(
          "flex flex-col justify-center items-center my-5 space-y-4"
        )}
      >
        <div className={clsx("w-3/4 flex flex-col space-y-4")}>
          {selectedRoute === 1 ? (
            <Button variant="contained" color="primary">
              For Students
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoStudent}>
              For Students
            </Button>
          )}

          {selectedRoute === 2 ? (
            <Button variant="contained" color="primary">
              For Parents
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoParent}>
              For Parents
            </Button>
          )}

          {selectedRoute === 3 ? (
            <Button variant="contained" color="primary">
              For School
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoSchool}>
              For School
            </Button>
          )}

          {selectedRoute === 5 ? (
            <Button variant="contained" color="primary">
              Certification
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoCounsellor}>
              Certification
            </Button>
          )}
        </div>
      </div>

      {!userData && (
        <>
          <Divider />

          <div
            className={clsx("flex flex-row justify-center items-center my-10")}
          >
            <Button
              className={"w-1/3"}
              variant="text"
              color="accent"
              onClick={handleLogin}
            >
              Login
            </Button>

            <Button
              className={"w-1/3"}
              variant="contained"
              color="accent"
              onClick={handleSignup}
            >
              Signup
            </Button>
          </div>
        </>
      )}
    </Box>
  );

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const AvatarMenuList = (anchor) => (
    <Box
      sx={{ width: "auto", height: "100%" }}
      role="presentation"
      onClick={toggleAvatarDrawer(anchor, false)}
      onKeyDown={toggleAvatarDrawer(anchor, false)}
    >
      <IconButton className={classes.MobileDrawerClose}>
        <CloseIcon />
      </IconButton>

      <div
        className={clsx(
          "flex flex-col justify-start items-start mx-12 mt-12 mb-6"
        )}
      >
        <div
          className={clsx(
            "flex flex-row justify-start items-center space-x-3",
            classes.accountMeta
          )}
        >
          <div
            className={clsx(
              "flex flex-col justify-center items-center",
              classes.accountAvatarContainer
            )}
          >
            <button
              className={clsx(
                "flex flex-col justify-center items-center rounded-full",
                classes.accountAvatar
              )}
            >
              {isCampusStudent ? (
                <PersonIcon sx={{ color: "white" }} />
              ) : (
                <span className={clsx("text-white")}>
                  {first_name && first_name[0]}
                  {last_name && last_name[0]}
                </span>
              )}
            </button>
          </div>

          <div
            className={clsx(
              "flex flex-row justify-start items-center",
              classes.accountName
            )}
          >
            <Typography variant="h6">
              {first_name} {last_name}
            </Typography>
          </div>
        </div>
      </div>

      <Divider />

      <div className={clsx("flex flex-col justify-center items-center my-5")}>
        <div className={clsx("w-3/4 flex flex-col")}>
          <Button variant="contained" color="accent" onClick={gotoDashboard}>
            My Dashboard
          </Button>
        </div>
      </div>

      <Divider />

      <div className={clsx("flex flex-col justify-center items-center my-5")}>
        <div className={clsx("w-3/4 flex flex-col")}>
          <Button variant="outlined" color="accent" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </div>
    </Box>
  );

  return (
    <div
      className={clsx(
        "flex flex-col justify-start items-stretch",
        classes.root
      )}
    >
      <div className={clsx("flex flex-row", classes.WideScreen)}>
        <div
          className={clsx(
            "w-1/3 flex flex-row justify-start items-center space-x-4"
          )}
        >
          <img
            className={classes.logo}
            src="assets/logo/logo_icon.svg"
            alt="main-logo"
            onClick={gotoHome}
          />

          <div className={clsx("", classes.search_box)}>
            <ClickAwayListener onClickAway={handleSearchClickAway}>
              <div className={clsx("position-relative")}>
                <TextField
                  id="search-career"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                  onFocus={handleSearchFocus}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  inputRef={searchInputRef}
                  autoComplete="off"
                />

                <Popper
                  id={searchId}
                  open={Boolean(searchAnchor)}
                  anchorEl={searchAnchor}
                  className={clsx(classes.searchPopper)}
                  placement="bottom"
                >
                  {searchQuery.length >= 2 && (
                    <SearchPaperComponent
                      options={options}
                      searchLoading={searchLoading}
                      handleSearchClose={handleSearchClose}
                    />
                  )}
                </Popper>
              </div>
            </ClickAwayListener>
          </div>

          <div className={clsx("", classes.search_button)}>
            <IconButton>
              <SearchIcon sx={{ color: "#6e6e6e85" }} />
            </IconButton>
          </div>

          {selectedRoute === 4 ? (
            <Button variant="contained" color="primary">
              Explore
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={handleExplore}>
              Explore
            </Button>
          )}
        </div>

        <div
          className={clsx(
            "w-2/3 flex flex-row justify-center items-center space-x-4",
            classes.user_button_box
          )}
        >
          {selectedRoute === 1 ? (
            <Button variant="contained" color="primary">
              For Students
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoStudent}>
              For Students
            </Button>
          )}

          {selectedRoute === 2 ? (
            <Button variant="contained" color="primary">
              For Parents
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoParent}>
              For Parents
            </Button>
          )}

          {selectedRoute === 3 ? (
            <Button variant="contained" color="primary">
              For School
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoSchool}>
              For School
            </Button>
          )}

          {selectedRoute === 5 ? (
            <Button variant="contained" color="primary">
              Certification
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoCounsellor}>
              Certification
            </Button>
          )}
        </div>

        <div
          className={clsx(
            "w-1/3 flex flex-row justify-center items-center space-x-3",
            classes.user_button_box_md
          )}
        >
          {selectedRoute === 1 ? (
            <Button variant="contained" color="primary">
              Students
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoStudent}>
              Students
            </Button>
          )}

          {selectedRoute === 2 ? (
            <Button variant="contained" color="primary">
              Parents
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoParent}>
              Parents
            </Button>
          )}

          {selectedRoute === 3 ? (
            <Button variant="contained" color="primary">
              School
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoSchool}>
              School
            </Button>
          )}

          {selectedRoute === 5 ? (
            <Button variant="contained" color="primary">
              Certification
            </Button>
          ) : (
            <Button variant="outline-flat" onClick={gotoCounsellor}>
              Certification
            </Button>
          )}
        </div>

        <div
          className={clsx(
            "w-1/3 flex flex-row justify-end items-center space-x-4"
          )}
        >
          {!isCampusStudent && (
            <>
              <IconButton
                ref={cartIconRef}
                onClick={handleCart}
                onMouseEnter={(event) => handleCartMouseOver(event)}
                onMouseLeave={(event) => handleCartIconMouseOut(event)}
              >
                <ShoppingCartOutlinedIcon
                  className={clsx(classes.cartButton)}
                />
              </IconButton>
              <ClickAwayListener onClickAway={handleCartMouseOut}>
                <Popper
                  open={showCart}
                  anchorEl={cartAnchor}
                  placement="bottom"
                >
                  <Cart
                    mouseLeave={handleCartMouseOut}
                    handleCheckout={handleCheckout}
                  />
                </Popper>
              </ClickAwayListener>
            </>
          )}

          {userData ? (
            <>
              <Button
                variant="contained"
                color="accent"
                onClick={gotoDashboard}
              >
                My Dashboard
              </Button>

              <>
                <button
                  ref={accountAvatarRef}
                  className={clsx(
                    "flex flex-col justify-center items-center rounded-full",
                    classes.accountAvatar
                  )}
                  onClick={handleAvatarClick}
                  onMouseEnter={(event) => handleAccountMouseOver(event)}
                  onMouseLeave={(event) => handleAccountAvatarMouseOut(event)}
                >
                  {isCampusStudent ? (
                    <PersonIcon sx={{ color: "white" }} />
                  ) : (
                    <span className={clsx("text-white")}>
                      {first_name && first_name[0]}
                      {last_name && last_name[0]}
                    </span>
                  )}
                </button>
                <ClickAwayListener onClickAway={handleAccountMouseOut}>
                  <Popper
                    open={showAccount}
                    anchorEl={accountAnchor}
                    placement="bottom-start"
                  >
                    <Account mouseLeave={handleAccountMouseOut} />
                  </Popper>
                </ClickAwayListener>
              </>
            </>
          ) : (
            <>
              <Button variant="text" color="accent" onClick={handleLogin}>
                Login
              </Button>

              <Button variant="contained" color="accent" onClick={handleSignup}>
                Signup
              </Button>
            </>
          )}
        </div>
      </div>

      <div
        className={clsx(
          "flex flex-row justify-between items-center",
          classes.MobileScreen
        )}
      >
        {!mobileSearch && (
          <>
            <div>
              <IconButton onClick={toggleDrawer("top", true)}>
                <MenuIcon />
              </IconButton>

              <SwipeableDrawer
                anchor={"top"}
                open={mobileMenu["top"]}
                onClose={toggleDrawer("top", false)}
                onOpen={toggleDrawer("top", true)}
              >
                {list("top")}
              </SwipeableDrawer>

              <IconButton
                aria-label="add to shopping cart"
                onClick={handleMobileSearch}
              >
                <SearchIcon />
              </IconButton>
            </div>

            <div className={clsx("flex flex-row justify-end items-center")}>
              <IconButton onClick={handleCart}>
                <ShoppingCartOutlinedIcon />
              </IconButton>

              {userData && (
                <button
                  ref={accountAvatarRef}
                  className={clsx(
                    "flex flex-col justify-center items-center rounded-full",
                    classes.accountAvatarMobile
                  )}
                  onClick={toggleAvatarDrawer("top", true)}
                >
                  {isCampusStudent ? (
                    <PersonIcon sx={{ color: "white" }} />
                  ) : (
                    <span className={clsx("text-white")}>
                      {first_name && first_name[0]}
                      {last_name && last_name[0]}
                    </span>
                  )}
                </button>
              )}

              <SwipeableDrawer
                anchor={"top"}
                open={mobileAvatarMenu["top"]}
                onClose={toggleAvatarDrawer("top", false)}
                onOpen={toggleAvatarDrawer("top", true)}
              >
                {AvatarMenuList("top")}
              </SwipeableDrawer>
            </div>
          </>
        )}

        {mobileSearch && (
          <div className={clsx("w-full")}>
            <ClickAwayListener onClickAway={handleSearchClickAway}>
              <div className={clsx("position-relative")}>
                <TextField
                  className={"w-full"}
                  id="search-career"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setMobileSearch(false)}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search"
                  onFocus={handleSearchFocus}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  inputRef={searchInputRef}
                  autoComplete="off"
                />

                <Popper
                  id={searchId}
                  open={Boolean(searchAnchor)}
                  anchorEl={searchAnchor}
                  className={clsx(classes.searchPopper)}
                  placement="bottom"
                >
                  {searchQuery.length >= 2 && (
                    <SearchPaperComponent
                      options={options}
                      searchLoading={searchLoading}
                      handleSearchClose={handleSearchClose}
                    />
                  )}
                </Popper>
              </div>
            </ClickAwayListener>
          </div>
        )}
      </div>
    </div>
  );
}

export default withReducer("Platform", reducer)(Header);
