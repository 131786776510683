import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getAlignedSubjects = createAsyncThunk('subjects/getAlignedSubjects', (params, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.get(`/student/${userId}/subjects`).then(response => {
        return response
    }).catch(error => rejectWithValue(error.response));
});


// ENTITY ADAPTER
const subjectsAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = subjectsAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = subjectsAdapter.getInitialState({
    list: [],
    status: 0,
    loading: false,
});

// SLICE
const subjectsSlice = createSlice({
    name: 'subjects',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = subjectsSlice.actions;

// REDUCERS
export default subjectsSlice.reducer;
