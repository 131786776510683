import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";
import SnackbarComponent from "../shared/snackbar.component";

import withReducer from "@app/store/withReducer";
import reducer from "./store";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#f1f5f9",
  },
}));

function StudentApp() {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        "flex flex-row justify-start items-stretch",
        classes.root
      )}
    >
      <Outlet />
      <SnackbarComponent />
    </div>
  );
}

export default withReducer("student", reducer)(StudentApp);
// export default StudentApp;
