import AuthApp from "./auth.app";
import Login from "./component/login";
import Signup from "./component/signup";
import ForgotPassword from "./component/forgot-password"
import UserConfirmComponent from "./component/user-confirm"
import GoogleOauth from "./component/google-oauth";

const routes = [
    {
        path: 'auth',
        element: <AuthApp />,
        children: [
            {
                path: 'login',
                element: <Login />
            },
            {
                path: 'signup',
                element: <Signup />
            },
            {
                path: 'forgot-password',
                element: <ForgotPassword />
            },
            {
                path: 'confirm/:username',
                element: <UserConfirmComponent />
            },
            {
                path: 'oauth/google/success',
                element: <GoogleOauth />
            }
        ]
    }
];

export default routes;
