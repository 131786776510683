import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useNavigate, useLocation, useParams } from "react-router-dom";
//
import {getCourseContent, resetCourse} from '../store/course.slice';

//
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import CircleIcon from '@mui/icons-material/Circle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CourseConfirmDialog from './components/course.dialog';
import Loader from "@app/layout/shared/loader.component";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    root: {

    },
    instructionCard: {
        margin: "10px 0px",
        padding: "20px",
        minHeight: '90vh',
        width: '100%'
    },

    startButton: {
        width: '250px',
        height: '50px'
    }
}));

function CourseInstructions() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const careerId = useSelector(({student }) => student.course.careerId);
    const courseLoaded = useSelector(({student }) => student.course.loaded);
    const instructions = useSelector(({student}) => student.course.instructions);
    const courseSections = useSelector(({student}) => student.course.sections);

    // STATES
    const [loading, setLoading] = useState(false);
    const [instructionsContent, setInstructionsContent] = useState(null);
    const [isInstructionAccepted, setIsInstructionAccepted] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if(courseLoaded) {
            setInstructionsContent(instructions);
            setLoading(false);
        } else {
            setLoading(true);
            dispatch(getCourseContent({userMapCareerId: params.id})).then(unwrapResult).then(response => {
                setInstructionsContent(instructions);
            }).catch(error => {
                console.log(error);
            });
        }
    }, [courseLoaded]);

    // METHODS
    const handleConfirmation = () => {
        setIsInstructionAccepted(state => !state);
    }

    const handleStartConfirm = () => {
        setConfirmOpen(true);
    }

    const handleConfirm = () => {
        const startSection = courseSections[0];
        const sectionId = startSection?._id.$oid;
        const subsectionId = startSection?.subsections[0]._id?.$oid;
        const type = startSection?.subsections[0].type;

        gotoCareerCourse(sectionId, subsectionId, type);
    }

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    }

    // NAVIGATION
    const gotoCareers = () => {
        navigate("/user-student/main/careers");
    }

    const gotoCareerCourse = (sectionId, subsectionId, type) => {
        navigate(`../section/${sectionId}/${subsectionId}/cn-${type}`);
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-row justify-between items-center")}>
                <Typography variant="h4">Instructions</Typography>

                <Button variant="contained" color="accent" onClick={gotoCareers}>Cancel</Button>
            </div>


            <Paper className={clsx("flex flex-col", classes.instructionCard)}>
                <Typography variant="h5" className={classes.instructionHeading}>{instructionsContent?.heading}</Typography>

                <div className={clsx("mt-5 flex flex-col justify-start items-stretch")}>
                    {
                        instructionsContent?.instructions_type === "numeric" &&
                            <div className={clsx("flex flex-col justify-start items-start space-y-5", classes.orderedList)}>
                                {
                                    instructionsContent?.content?.map((item, index) => (
                                        <div key={index} className={clsx("flex flex-row space-x-2")}>
                                            <Typography variant="body1">{index+1}.</Typography>
                                            <Typography variant="body1" key={index}>{item}</Typography>
                                        </div>

                                    ))
                                }
                            </div>
                    }

                    {
                        instructionsContent?.instructions_type === "bullets" &&
                            <div className={clsx("flex flex-col justify-start items-start space-y-5", classes.unOrderedList)}>
                                {
                                    instructionsContent?.content?.map((item, index) => (
                                        <div key={index} className={clsx("flex flex-row space-x-2")}>
                                            <Typography variant="body1"><CircleIcon sx={{fontSize: '15px'}}/></Typography>
                                            <Typography variant="body1" key={index}>{item}</Typography>
                                        </div>

                                    ))
                                }
                            </div>
                    }
                </div>

                <div className={clsx("flex-grow flex flex-col justify-end items-center space-y-3", classes.instructionsAction)}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color="accent" onChange={handleConfirmation} checked={isInstructionAccepted} />} label="I have read all the instructions for taking the following career test." />
                    </FormGroup>

                    <Button variant="contained" color="primary" disabled={!isInstructionAccepted} className={clsx(classes.startButton)} onClick={handleStartConfirm}>Start Career</Button>
                </div>
            </Paper>

            <CourseConfirmDialog
                open={confirmOpen}
                handleConfirm={handleConfirm}
                handleClose={handleConfirmClose}
            />
        </div>
    );
};

export default CourseInstructions;
