import { combineReducers } from '@reduxjs/toolkit';
import login from './login.slice';
import signup from './signup.slice';
import user from './user.slice';

const authReducers = combineReducers({
    user,
    login,
    signup
});

export default authReducers;
