import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Outlet } from "react-router-dom";

//
import SnackbarComponent from "../shared/snackbar.component";

const useStyles = makeStyles(theme => ({
    root: {

    }
}));

function AuthApp() {
    const classes = useStyles();

    return (
        <div className={clsx("h-screen w-screen flex flex-col justify-center items-stretch", classes.root)}>
            <Outlet/>
            <SnackbarComponent />
        </div>
    );
}

export default AuthApp;
