import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const postSignupUser = createAsyncThunk('signup/postUser', (data, { dispatch, getState, rejectWithValue}) => {
    return axios.post('/student/signup', data).then(response => response).catch(error => rejectWithValue(error.response));
});

// ENTITY ADAPTER
const signupAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = signupAdapter.getSelectors();

const initialState = signupAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = signupSlice.actions;

// REDUCERS
export default signupSlice.reducer;
