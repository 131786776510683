import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const postDemoSchedule = createAsyncThunk('parent/postDemoSchedule', (data, { dispatch, getState}) => {
    return axios.post(`/platform/demo?usertype=${data.usertype}`, data.body);
});


// ENTITY ADAPTER
const parentAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = parentAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = parentAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = parentSlice.actions;

// REDUCERS
export default parentSlice.reducer;
