import FuseUtils from "@app/utils/FuseUtils";
import axios from "axios";
import { Auth } from "aws-amplify";
import { nodeAxios, platformAxios } from "../../App";

class AuthService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    // axios.interceptors.response.use(
    //     response => {
    //         return response;
    //     },
    //     err => {
    //         return err;
    //
    //         // Handle this for authenticated user to sign out
    //         // return new Promise((resolve, reject) => {
    //         //     if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
    //         //         // if you ever get an unauthorized response, logout the user
    //         //         this.emit('onAutoLogout', 'Invalid access_token');
    //         //         this.setSession(null);
    //         //     }
    //         //     throw err;
    //         // });
    //     }
    // );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      // this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      // this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      Auth.signUp(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  verifyUser = (username, code) => {
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(username, code)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  resendConfirmationCode = (username) => {
    return new Promise((resolve, reject) => {
      Auth.resendSignUp(username)
        .then((response) => {
          resolve(1);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signInWithUsernameAndPassword = (username, password) => {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then((user) => {
          this.setSession(user?.signInUserSession?.idToken.jwtToken);

          if (
            user.attributes?.["custom:campusId"] &&
            user.attributes?.["custom:campusUuid"]
          ) {
            this.setCampusHeaders({
              userId: user.attributes?.["custom:user_id"],
              campus: user.attributes?.["custom:campusId"],
              campusUuid: user.attributes?.["custom:campusUuid"],
            });
          }

          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((response) => {
          const user = {
            role: response.attributes["custom:user_type"],
            user_id: response.attributes["custom:user_id"],
            data: response.attributes,
          };

          this.setSession(response?.signInUserSession?.idToken.jwtToken);

          if (
            user.data?.["custom:campusId"] &&
            user.data?.["custom:campusUuid"]
          ) {
            this.setCampusHeaders({
              userId: user.user_id,
              campus: user.data?.["custom:campusId"],
              campusUuid: user.data?.["custom:campusUuid"],
            });
          }

          resolve(user);
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post("/api/auth/user/update", {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `${access_token}`;
      platformAxios.defaults.headers.common.Authorization = `${access_token}`;
      nodeAxios.defaults.headers.common.Authorization = `${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
      delete platformAxios.defaults.headers.common.Authorization;
      delete nodeAxios.defaults.headers.common.Authorization;
    }
  };

  setCampusHeaders = (headers) => {
    if (headers) {
      const { userId, campus, campusUuid } = headers;
      nodeAxios.defaults.headers["x-user-uuid"] = `${userId}`;
      nodeAxios.defaults.headers["x-campus-id"] = `${campus}`;
      nodeAxios.defaults.headers["x-campus-uuid"] = `${campusUuid}`;
    } else {
      delete nodeAxios.defaults.headers["x-user-uuid"];
      delete nodeAxios.defaults.headers["x-campus-id"];
      delete nodeAxios.defaults.headers["x-campus-uuid"];
    }
  };

  logout = () => {
    this.setSession(null);
    this.setCampusHeaders(null);

    Auth.signOut()
      .then(() => {
        window.localStorage.clear();
      })
      .catch((error) => {});
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    // const decoded = jwtDecode(access_token);
    // const currentTime = Date.now() / 1000;
    // if (decoded.exp < currentTime) {
    //     console.warn('access token expired');
    //     return false;
    // }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };

  getRefreshToken = () => {
    return window.localStorage.getItem("jwt_refresh_token");
  };
}

const instance = new AuthService();

export default instance;
