import Dashboard from '../dashboard/dashboard';

const Routes = [
    {
        index: true,
        element: <Dashboard />
    },
    {
        path: 'dashboard',
        element: <Dashboard />
    }
];

export default Routes;
