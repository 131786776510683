import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getFields = createAsyncThunk('careers/getFields', (id, { dispatch, getState}) => {
    return axios('/platform/careers/fields');
});

export const getCareers = createAsyncThunk('careers/getCareers', (params, { dispatch, getState}) => {
    const { index, size, field } = params;
    const fieldParam = field ? `&field=${field}` : ``
    return axios(`/platform/careers?pageNum=${index}&num=${size}${fieldParam}`);
});

export const addItemToCart = createAsyncThunk('cart/addItemToCart', (payload, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.post(`/platform/cart?userId=${userId}&usertype=${userType}&careerId=${payload.id}`).then(response => {
        return dispatch(addItem(payload));
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

export const removeItemsFromCart = createAsyncThunk('cart/removeItemFromCart', (payload, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.delete(`/platform/cart?userId=${userId}`, payload?.careers).then(response => {
        return dispatch(removeItems(payload?.careers));
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

export const getUserCart = createAsyncThunk('cart/getUserCart', (payload, { dispatch, getState, rejectWithValue}) => {
    dispatch(setLoading(true));

    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    return axios.get(`/platform/cart?userId=${userId}`).then(response => {
        return response;
    }).catch(error => {
        dispatch(setLoading(false));
        return rejectWithValue(error.response);
    });
});

export const getCheckoutCareers = createAsyncThunk('cart/getCheckoutCareers', (payload, { dispatch, getState, rejectWithValue}) => {
    const { user } = getState().auth;
    const userId = user.data['custom:user_id'];
    const userType = user.data['custom:user_type'];

    const { items } = getState()?.user?.cart;
    const careers = items.map(el => el.id);

    return axios.post(`/platform/cart/enrolled?userId=${userId}`, {"careers": careers}).then(response => {
        return response;
    }).catch(error => {
        return rejectWithValue(error.response);
    });
});

// ENTITY ADAPTER
const cartAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = cartAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = cartAdapter.getInitialState({
    status: 0,
    loading: false,
    cartId: null,
    items: [
        // {
        //     id: null,
        //     name: null,
        //     field: null,
        //     selling_price: null,
        //     strike_price: null,
        //     quantity: null,
        //     logo: null,
        //     logo_bg: null
        // }
    ],
    selling_total: 0,
    strike_total: 0
});

// SLICE
const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        },
        addItem: {
            reducer: (state, action) => {
                state.items.push(action.payload);
                state.selling_total += parseFloat(action.payload.selling_price);
                state.strike_total += parseFloat(action.payload.strike_price);
            }
        },
        removeItem: {
            reducer: (state, action) => {
                const { id } = action.payload;
                let itemIndex = state.items.findIndex(item => item.id === id);
                let items = state.items;

                if(itemIndex > -1) {
                    state.selling_total -= parseFloat(items[itemIndex].selling_price);
                    state.strike_total -= parseFloat(items[itemIndex].strike_price);
                    items.splice(itemIndex, 1);
                }
            }
        },
        removeItems: {
            reducer: (state, action) => {
                const careers = action.payload;

                let subtractSellingTotal = 0.0;
                let subtractStrikeTotal = 0.0;
                let items = state.items;
                let remainItems = [];

                items.forEach(item => {
                    if(careers.includes(item.id)) {
                        subtractSellingTotal += parseFloat(item.selling_price);
                        subtractStrikeTotal += parseFloat(item.strike_price);
                    } else {
                        remainItems.push(item);
                    }
                });

                console.log(items);

                state.items = remainItems;
                state.selling_total -= subtractSellingTotal;
                state.strike_total -= subtractStrikeTotal;
            }
        },
        resetCart: () => initialState
    },
    extraReducers: {
        [getUserCart.fulfilled]: (state, action) => {
            const { data:cartItems } = action.payload;

            cartItems.forEach((item, index) => {
                state.items.push({
                    id: item?.careerId,
                    name: item.career.name,
                    field: null,
                    selling_price: item.career.public.selling_price,
                    strike_price: item.career.public.strike_price,
                    quantity: item.instance,
                    logo: item.career.public.logo,
                    logo_bg: item.career.public.logo_bg
                });

                state.selling_total += parseFloat(item.career.public.selling_price);
                state.strike_total += parseFloat(item.career.public.selling_price);
            });

            state.loading = false;
        }
    }
});

// ACTIONS
export const { setLoading, addItem, removeItem, removeItems, resetCart } = cartSlice.actions;

// REDUCERS
export default cartSlice.reducer;
