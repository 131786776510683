import React, { useState, useEffect} from "react";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography';
import {unwrapResult} from "@reduxjs/toolkit";
import {showSnackbar} from "../../../../../store/app/snackbar.slice";
import {getDashboard} from "../store/dashboard.slice";
import Button from "@mui/material/Button";
import Loader from "@app/layout/shared/loader.component";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 30px',
    },
    emptyListContent: {
        height: '72vh'
    },
    SubscribedCareers: {
        marginTop: '40px',
        border: '1px solid #70CDE0',
        minHeight: '40vh',
        padding: '10px 20px'
    },
    CompletedCareers: {
        marginTop: '50px',
        border: '1px solid #FFC860',
        minHeight: '40vh',
        padding: '10px 20px'
    },
    CareerContainer: {
        border: '1px solid #cccccca6',
        padding: '8px'
    },
    CareerLogo: {
        height: '210px'
    }
}));

function Sessions() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData = useSelector(({auth}) => auth.user.data);

    const first_name = userData['custom:first_name'];
    const last_name = userData['custom:last_name'];

    // STATES
    const [loading, setLoading] = useState(false);
    const [isDashboardEmpty, setIsDashboardEmpty] = useState(false);
    const [completedCareers, setCompletedCareers] = useState([]);
    const [subscribedCareers, setSubscribedCareers] = useState([]);

    // EFFECTS
    useEffect(() => {
        // setLoading(true);
        // dispatch(getDashboard()).then(unwrapResult).then(response => {
        //     console.log(response);
        //     if(response?.status === 200) {
        //         if(Array.isArray(response?.data)) {
        //             if(!response?.data.length) {
        //                 setIsDashboardEmpty(true);
        //             }
        //         } else if(Object.keys(response?.data).length) {
        //             let dashboard_data = response?.data;
        //
        //             if(dashboard_data?.completed?.length === 0 || dashboard_data?.published?.length === 0) {
        //                 setIsDashboardEmpty(true);
        //             } else {
        //                 setCompletedCareers(dashboard_data?.completed);
        //                 setSubscribedCareers(dashboard_data?.published);
        //             }
        //         }
        //     }
        //
        //     setLoading(false);
        // }).catch(error => {
        //     const message = error?.message ? error.message : "Something went wrong";
        //
        //     dispatch(showSnackbar({
        //         message: message,
        //         autoHideDuration: 6000,//ms
        //         anchorOrigin: {
        //             vertical  : 'bottom',//top bottom
        //             horizontal: 'right'//left center right
        //         },
        //         variant: 'error'
        //     }));
        // });
    }, []);

    // METHODS
    const gotoExplore = () => {
        navigate(`/careers`);
    }

    // UTILITY
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-row")}>
                <Typography variant="h4">Your Sessions</Typography>
            </div>

            {/*<div className={clsx("flex flex-col")}>*/}
            {/*    {*/}
            {/*        isDashboardEmpty ?*/}
            {/*            <div className={clsx("h-full flex flex-col justify-center items-center", classes.emptyListContent)}>*/}
            {/*                <Typography variant="h6">You haven't enrolled for any career</Typography>*/}
            {/*                <div className={clsx("mt-4")}>*/}
            {/*                    <Button variant="outlined" color="accent" onClick={gotoExplore}>Explore Careers</Button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            :*/}
            {/*            <div className={clsx("flex flex-col")}>*/}
            {/*                <div className={clsx("flex flex-col", classes.SubscribedCareers)}>*/}
            {/*                    <Typography variant="h6">Your recent subscribed careers</Typography>*/}

            {/*                    <div className={clsx("flex flex-row mt-4")}>*/}
            {/*                        {*/}
            {/*                            subscribedCareers?.map(career => (*/}
            {/*                                <div className={clsx("", classes.CareerContainer)}>*/}
            {/*                                    <img className={clsx(classes.CareerLogo)} src={career?.master?.logo_bg} alt="parent-illustration" />*/}
            {/*                                    <Typography variant="subtitle1">{career?.master?.name}</Typography>*/}
            {/*                                </div>*/}
            {/*                            ))*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <div className={clsx("flex flex-col", classes.CompletedCareers)}>*/}
            {/*                    <Typography variant="h6">You have completed</Typography>*/}

            {/*                    <div className={clsx("flex flex-row mt-4")}>*/}
            {/*                        {*/}
            {/*                            completedCareers?.map(career => (*/}
            {/*                                <div className={clsx("", classes.CareerContainer)}>*/}
            {/*                                    <img className={clsx(classes.CareerLogo)} src={career?.master?.logo_bg} alt="parent-illustration" />*/}
            {/*                                    <Typography variant="subtitle1">{career?.master?.name}</Typography>*/}
            {/*                                </div>*/}
            {/*                            ))*/}
            {/*                        }*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    );
};

export default Sessions;
