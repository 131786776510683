import Subjects from './subjects';

const Routes = [
    {
        index: true,
        element: <Subjects />
    },
    {
        path: 'subjects',
        element: <Subjects />
    }
];

export default Routes;
