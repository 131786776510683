import React, {useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation, useParams } from "react-router-dom";
//
import {setCurrentSectionAndSubsection, setNextSectionAndSubsectionId, submitCareer} from '../store/course.slice';

//
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import student from "../../../platform/app/student";
import {getCourseContent} from "../store/course.slice";
import Loader from "@app/layout/shared/loader.component";
import {unwrapResult} from "@reduxjs/toolkit";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%'
    },
    sectionCard: {
        margin: "10px 0px",
        padding: "20px",
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0px'
        }
    }
}));

function CourseSection() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    // REDUX
    const userRole = useSelector(({ auth }) => auth.user.role);
    const userData = useSelector(({auth}) => auth.user.data);
    const courseLoaded = useSelector(({student}) => student.course.loaded);
    const courseSections = useSelector(({student}) => student.course.sections);
    const currentSection = useSelector(({student}) => student.course.current_section);
    const currentSubsection = useSelector(({student}) => student.course.current_subsection);

    // STATES
    const [loading, setLoading] = useState(false);
    const [sectionId, setSectionId] = useState(params.sectionId);
    const [subsectionId, setSubsectionId] = useState(params.subsectionId);

    // EFFECTS
    useEffect(() => {
        if(courseLoaded) {
            setLoading(false);
        } else {
            setLoading(true);
            dispatch(getCourseContent({userMapCareerId: params.id})).then(unwrapResult).then(response => {

            }).catch(error => {
                console.log(error);
            });
        }
    }, [courseLoaded]);

    useEffect(() => {
        if(params.subsectionId === 'end') {
            handleCompleteCareer();
        } else {
            setSectionId(params.sectionId);
            setSubsectionId(params.subsectionId);
        }
    }, [params]);

    useEffect(() => {
        if(courseLoaded && params && params.subsectionId !== 'end') {
            dispatch(setCurrentSectionAndSubsection({sectionId: params.sectionId, subsectionId: params.subsectionId}));
        }
    }, [courseLoaded, params])

    useEffect(() => {
        if(currentSection && currentSubsection) {
            dispatch(setNextSectionAndSubsectionId({sectionId, subsectionId}));
        }
    }, [currentSection, currentSubsection])

    // METHODS
    const handleCompleteCareer = () => {
        let postParams = {
            careerId: params.careerId
        };

        dispatch(submitCareer(postParams)).then(unwrapResult).then(response => {
            if(response.status === 200) {
                navigate(`../completed`);
            } else {

            }
        }).catch(error => {

        })
    }
    //
    if(loading) {
        return (
            <Loader />
        )
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <Typography variant="h4">Section | {currentSection?.name}</Typography>

            <Paper className={clsx("flex flex-col justify-start items-stretch", classes.sectionCard)}>
                <Outlet />
            </Paper>
        </div>
    );
};

export default CourseSection;
