import React, {useState} from "react";
import clsx from 'clsx';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
//
import withReducer from '../../../store/withReducer';
import reducer from './store';
import {showSnackbar, hideSnackbar } from "../../../store/app/snackbar.slice";
//
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {ProgressButton} from "@app/layout/shared/buttons.component";
import {postDemoSchedule} from "./store/school.slice";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '2rem'
    },
    videoContent: {
        width: '55rem'
    },
    container: {
        height: '100%'
    },
    heading: {
        fontWeight: '900 !important'
    },
    form_container: {

    },
    school_form: {
        marginTop: '60px'
    },
    illustration_container: {},
    school_illustration: {
        marginTop: '60px',
        width: '80%'
    }
}));

function School() {
    const classes = useStyles();
    const dispatch = useDispatch();

    // FORM CONTROL
    const methods = useForm({
        mode: 'onBlur'
    });
    const { watch,  control, formState, reset } = methods;
    const form = watch();
    const { errors, isDirty, isValid } = formState;

    // STATE
    const [loading, setLoading] = useState(false);

    const handleSchedule = () => {
        if(isValid) {
            setLoading(true);

            let body = {
                ...form,
                mobile: `+91${form?.mobile}`
            }

            const data = {
                body: body,
                usertype: 'school'
            };

            dispatch(postDemoSchedule(data)).unwrap().then(response => {
                if(response.status === 200) {
                    dispatch(showSnackbar({
                        message: response.data.message,
                        autoHideDuration: 6000, //ms
                        anchorOrigin: {
                            vertical  : 'bottom',//top bottom
                            horizontal: 'right'  //left center right
                        },
                        variant: 'success'
                    }));
                    reset();
                }
                setLoading(false);
            }).catch(error => {
                console.log(error);
                // Classify Error states for managing
                dispatch(showSnackbar({
                    message: 'Something went wrong, please try again',
                    autoHideDuration: 600000, //ms
                    anchorOrigin: {
                        vertical  : 'bottom',//top bottom
                        horizontal: 'right'  //left center right
                    },
                    variant: 'error'
                }));
                setLoading(false);
            });
        } else {
            // dispatch(showSnackbar({
            //     message: 'Please fill all required details.',
            //     autoHideDuration: 6000, //ms
            //     anchorOrigin: {
            //         vertical  : 'bottom',//top bottom
            //         horizontal: 'right'  //left center right
            //     },
            //     variant: 'warning'
            // }));
        }
    }

    const handleMobileChange = (event, callback) => {
        const {value} = event.target;
        if(!isNaN(value)) {
            callback(event);
            return true
        } else {
            return false
        }
    }

    return (
        <div className={clsx("flex flex-col justify-start items-stretch", classes.root)}>
            <div className={clsx("flex flex-col items-center mt-8", classes.videoContainer)}>
                <video className={classes.videoContent} controls preload="metadata">
                    <source src="https://ccp-prod-master.s3.ap-south-1.amazonaws.com/public/School+-1080p-220831.mp4" type="video/mp4" />
                </video>
            </div>

            <div className={clsx("flex flex-col-reverse sm:flex-row", classes.container)}>
                <div className={clsx("w-full sm:w-2/5 flex flex-col justify-start items-center", classes.form_container)}>
                    <div className={clsx("flex flex-col justify-start items-stretch", classes.school_form)}>
                        <Typography variant="h2" className={clsx(classes.heading)}>For Schools</Typography>
                        <Typography variant="h6">Get a CCP Career Lab at your School</Typography>

                        <div className={clsx("mt-6 flex flex-col justify-start items-stretch space-y-6")}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Name is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="mt-8 mb-16 w-12/25"
                                        error={!!errors.name}
                                        required
                                        helperText={errors?.name?.message}
                                        label="Name"
                                        id="name"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="school"
                                control={control}
                                defaultValue=""
                                rules={{ required: "School name is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="mt-8 mb-16 w-12/25"
                                        error={!!errors.school}
                                        required
                                        helperText={errors?.school?.message}
                                        label="School Name"
                                        id="school-name"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="designation"
                                control={control}
                                defaultValue=""
                                rules={{ required: "Role is required" }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="mt-8 mb-16 w-12/25"
                                        error={!!errors.designation}
                                        required
                                        helperText={errors?.designation?.message}
                                        label="Designation"
                                        id="designation"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Enter valid email"
                                    }
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <TextField
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        className="mt-8 mb-16 w-12/25"
                                        error={!!errors.email}
                                        required
                                        helperText={errors?.email?.message}
                                        label="Email"
                                        id="email"
                                        variant="outlined"
                                    />
                                )}
                            />

                            <div className={clsx("flex flex-row space-x-6")}>
                                <TextField
                                    disabled
                                    value="IND(+91)"
                                    className="w-28 mt-8 mb-16"
                                    id="mobile-code"
                                    variant="outlined"
                                />

                                <Controller
                                    name="mobile"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Mobile is required",
                                        minLength: {
                                            value: 10,
                                            message: "Enter valid mobile number"
                                        }
                                    }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <TextField
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => handleMobileChange(e, onChange)}
                                            onBlur={onBlur}
                                            value={value}
                                            className="flex-grow mt-8 mb-16"
                                            error={!!errors.mobile}
                                            required
                                            helperText={errors?.mobile?.message}
                                            label="Mobile"
                                            id="mobile"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className={clsx("mt-6 flex flex-col justify-center items-stretch")}>
                            <ProgressButton
                                label={"Schedule a free demo"}
                                loading={loading}
                                color={"accent"}
                                classes={"w-full"}
                                handleButtonClick={handleSchedule}
                            />
                        </div>
                    </div>
                </div>

                <div className={clsx("w-full sm:w-3/5 flex flex-col justify-start items-center", classes.illustration_container)}>
                    <img className={classes.school_illustration} src="assets/homepage/school-illustration.png" alt="school-illustration"/>
                </div>
            </div>
        </div>
    );
}

export default withReducer('Platform', reducer)(School);
