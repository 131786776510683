import React, { memo } from 'react';
import './style/loader.component.css';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

function Loader() {
    return (
        <div className="loader-screen">
            <CircularProgress
                color="accent"
                size={70}
                thickness={5}
                disableShrink
                sx={{
                    animationDuration: '1000ms',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
            />
        </div>
    )
}

export default memo(Loader);
