import React from "react";
import {
  StylesProvider,
  jssPreset,
  createGenerateClassName,
} from "@mui/styles";
import { create } from "jss";
import jssExtend from "jss-plugin-extend";
import rtl from "jss-rtl";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Amplify from "aws-amplify";

import AppContext from "./AppContext";
import Routes from "./configs/routes";
import store from "./store";
import Theme from "./theme/theme";
import Auth from "./auth/Auth";
import AuthConfig from "../@aws-config/auth.config";
import axios from "axios";
import appConfig from "../@config/config";

//
const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend(), rtl()],
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

//
function AppRoutes() {
  return useRoutes(Routes);
}

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, developmentRedirectSignIn] =
  AuthConfig.Auth.oauth.redirectSignIn.split(",");

const [localRedirectSignOut, developmentRedirectSignOut] =
  AuthConfig.Auth.oauth.redirectSignOut.split(",");

// NEED TO DEFINE ACCORDING TO STAGING ENV STATES
const updatedAuthConfig = {
  ...AuthConfig.Auth,
  oauth: {
    ...AuthConfig.Auth.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : developmentRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : developmentRedirectSignOut,
  },
};

export const platformAxios = axios.create({
  baseURL: appConfig.BASEURL,
});

export const nodeAxios = axios.create({
  baseURL: appConfig.NODE_URL,
});

function App() {
  Amplify.configure(updatedAuthConfig);

  return (
    <AppContext.Provider value={{ Routes }}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <Theme>
                <SnackbarProvider
                  maxSnack={5}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  classes={{
                    containerRoot:
                      "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
                  }}
                >
                  <Auth>
                    <AppRoutes />
                  </Auth>
                </SnackbarProvider>
              </Theme>
            </BrowserRouter>
          </LocalizationProvider>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
}

export default App;
