import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CourseConfirmDialog(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    const handleClickConfirm = () => {
        props.handleConfirm();
        setOpen(true);
    };

    const handleClickClose = () => {
        props.handleClose();
        setOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClickClose}>
                Confirm to Start
            </BootstrapDialogTitle>

            <DialogContent dividers>
                <Typography gutterBottom>
                    You are about to start this career test, which will be one go and you have to take in one round.
                    Please make sure you are not interrupted in between this test.
                </Typography>

                <Typography gutterBottom>
                    Click confirm to start, cancel to go back.
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color="accent" onClick={handleClickClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleClickConfirm}>Confirm</Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
