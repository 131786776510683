import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';

// ASYNC THUNK OPERATIONS
export const getHomepage = createAsyncThunk('home/getHomepage', (id, { dispatch, getState}) => {
    return axios('/platform/careers/home?number=10');
});

// ENTITY ADAPTER
const homeAdapter = createEntityAdapter({});

export const { selectAll: selectCareer, selectById: selectCareerById } = homeAdapter.getSelectors(
    state => state.Career.careers
);

const initialState = homeAdapter.getInitialState({
    status: 0,
    loading: false,
});

// SLICE
const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setLoading: {
            reducer: (state, action) => {
                state.loading = action.payload
            }
        }
    },
    extraReducers: {

    }
});

// ACTIONS
export const { setLoading } = homeSlice.actions;

// REDUCERS
export default homeSlice.reducer;
